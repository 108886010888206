import React, { useCallback, useEffect, useState } from "react"
import ContentLoader from "react-content-loader"
import Input from "../../Input"
import {
  clearStudyConsentForm,
  loadStudyConsentForm,
  updateStudyConsentForm,
} from "../../../store/entity/entity.actions"
import { useDispatch } from "react-redux"
import Button from "../../Button"
import Modal from "../../Modal"
import Dropzone from "../../DropZone"
import { bytesToMB } from "../../../helpers"
import { addAlertWithTimeout, AlertType } from "../../../utils/alertUtils"
const ConsentForm = ({ studyId }) => {
  const dispatch = useDispatch()
  const [editConsentForm, setEditConsentForm] = useState(false)
  const [consentForm, setConsentForm] = useState(null)
  const [newConsentFormPath, setNewConsentFormPath] = useState("")
  const [consentFormLoading, setConsentFormLoading] = useState(false)
  const [selectConsentFormUrl, setSelectConsentFormUrl] = useState(false)
  const [consentFormSaving, setConsentFormSaving] = useState(false)
  const [consentFormFileModal, setConsentFormFileModal] = useState(false)
  const [consentFormFile, setConsentFormFile] = useState(null)
  const [consentFormEdited, setConsentFormEdited] = useState(false)
  const [confirmClearConsentFormModal, setConfirmClearConsentFormModal] =
    useState(false)

  const loadConsentForm = useCallback(() => {
    setConsentFormLoading(true)

    dispatch(loadStudyConsentForm({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          if (!r.data?.is_file) {
            setNewConsentFormPath(r.data?.path ?? "")
          }
          setConsentForm(r.data)
        }

        setConsentFormLoading(false)
      })
  }, [dispatch, studyId])

  const saveConsentForm = async () => {
    let formData = new FormData()

    setConsentFormSaving(true)

    if (consentFormFile) {
      formData.append("file", consentFormFile)
      formData.append("is_file", "true")
    } else {
      formData.append("path", newConsentFormPath)
      formData.append("is_file", "false")
    }

    dispatch(updateStudyConsentForm({ id: studyId, data: formData }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          setConsentForm(r.data)

          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Study ConsentForm has been updated successfully.</p>
            </>,
            AlertType.Success
          )
        }

        setConsentFormSaving(false)
        setEditConsentForm(false)
      })
  }

  const clearConsentForm = () => {
    setConsentFormSaving(true)

    dispatch(clearStudyConsentForm({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Study Consent Form has been removed successfully.</p>
            </>,
            AlertType.Warning
          )

          setConsentForm(null)
          setConfirmClearConsentFormModal(false)
        }

        setConsentFormSaving(false)
        setEditConsentForm(false)
      })
  }

  useEffect(() => {
    loadConsentForm()
  }, [loadConsentForm])

  return (
    <>
      <div className="bg-white rounded-lg px-5 border mt-2.5 p-5">
        <div className="flex flex-row justify-between items-center">
          <h4 className="font-bold text-xl">Study Consent Form</h4>
          <div>
            {editConsentForm && (
              <Button
                className={`px-8 py-1.5 mb-0 mr-2 text-green-700 ${
                  !consentFormEdited || consentFormSaving ? "opacity-40" : ""
                }`}
                onClick={saveConsentForm}
                disabled={!consentFormEdited || consentFormSaving}
              >
                {consentFormSaving ? "Saving..." : "Save"}
              </Button>
            )}
            <Button
              className="px-3 py-1.5 mb-0"
              onClick={() => {
                setSelectConsentFormUrl(false)
                setConsentFormFile(null)
                setEditConsentForm(prev => !prev)
              }}
            >
              {editConsentForm ? (
                "Cancel"
              ) : (
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87M0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z"
                    fill="black"
                  />
                </svg>
              )}
            </Button>
          </div>
        </div>
        <hr />
        <div className="mt-5 py-3 px-5 rounded-lg border flex flex-row items-center my-2">
          <span className="w-1/2 inline-block font-medium">
            Study consent form
          </span>
          <div className="w-1/2">
            {consentFormLoading ? (
              <ContentLoader
                speed={2}
                width="100%"
                height={24}
                backgroundColor="#c2c2c2"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
              </ContentLoader>
            ) : editConsentForm ? (
              selectConsentFormUrl ? (
                <div className="flex items-center">
                  <Input
                    value={newConsentFormPath}
                    onChange={e => {
                      setNewConsentFormPath(e.target.value)
                      setConsentFormEdited(true)
                    }}
                    placeholder="Enter URL..."
                  />
                  <button
                    className="pl-2.5 hover:opacity-70 transition h-full text-red-500"
                    onClick={() => {
                      setConsentFormEdited(false)
                      setSelectConsentFormUrl(false)
                      setNewConsentFormPath("")
                    }}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="w-fit mx-auto border border-dashed rounded-xl flex flex-row text-dark-gray font-light">
                  {consentFormFile ? (
                    <>
                      <button
                        className="pl-4 pr-2.5 py-4 hover:opacity-70 transition h-full flex flex-row justify-around"
                        onClick={() => setConsentFormFileModal(true)}
                      >
                        <span className="max-w-[200px] truncate mr-1.5">
                          {consentFormFile.name}
                        </span>
                        <span className="font-medium">
                          {bytesToMB(consentFormFile.size)} MB
                        </span>
                      </button>
                      <button
                        className="pr-4 pl-2.5 py-4 hover:opacity-70 transition h-full text-red-500"
                        onClick={() => {
                          setConsentFormFile(null)
                          setConsentFormEdited(false)
                        }}
                      >
                        Clear
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="pl-4 pr-2.5 py-4 hover:opacity-70 transition h-full"
                        onClick={() => setConsentFormFileModal(true)}
                      >
                        {consentForm && consentForm.is_file
                          ? "Upload new file"
                          : "Select file"}
                      </button>
                      <button
                        className="px-2.5 py-4 hover:opacity-70 transition h-full"
                        onClick={() => setSelectConsentFormUrl(true)}
                      >
                        {consentForm && !consentForm.is_file
                          ? "Edit URL"
                          : "Select URL"}
                      </button>
                      {consentForm && (
                        <button
                          className="pr-4 pl-2.5 py-4 hover:opacity-70 transition h-full text-red-500"
                          onClick={() => setConfirmClearConsentFormModal(true)}
                        >
                          Clear all
                        </button>
                      )}
                    </>
                  )}
                </div>
              )
            ) : consentForm ? (
              consentForm.is_file ? (
                <a
                  href={consentForm.link}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full block border border-dashed border-light-gray text-light-gray rounded-lg font-light px-3 truncate hover:opacity-80 transition"
                >
                  {consentForm.path}
                </a>
              ) : (
                <a
                  href={consentForm.link}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full block text-blue hover:opacity-70 transition inline-block truncate"
                >
                  {consentForm.path}
                </a>
              )
            ) : (
              <div className="text-light-gray text-center">
                No file uploaded
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        title="Upload file"
        size="lg"
        isShown={consentFormFileModal}
        setIsShown={setConsentFormFileModal}
        submitActionActive={consentFormFile}
        noButtons={true}
      >
        <Dropzone
          setFile={setConsentFormFile}
          onDropAction={() => {
            setConsentFormFileModal(false)
            setConsentFormEdited(true)
          }}
        />
      </Modal>

      <Modal
        title="Clear ConsentForm"
        size="md"
        isShown={confirmClearConsentFormModal}
        setIsShown={setConfirmClearConsentFormModal}
        onSubmit={clearConsentForm}
        submitText="Yes"
      >
        <p>Are you sure you want to clear ConsentForm?</p>
      </Modal>
    </>
  )
}

export default ConsentForm
