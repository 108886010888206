import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { DOTS, usePagination } from "../../hooks/usePagination"
import { loadEntities } from "../../store/entities/entities.actions"
import { useDispatch, useSelector } from "react-redux"
import { selectEntitiesData } from "../../store/entities/entities.selector"
import { setCurrentPage } from "../../store/entities/entities.reducer"
import RowsLoader from "./RowsLoader"

const UsersLogsDataTable = ({ userId }) => {
  const [usersLogs, setUsersLogs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { currentPage, totalPages, perPage, totalItems } =
    useSelector(selectEntitiesData)
  const dispatch = useDispatch()
  let [searchParams, setSearchParams] = useSearchParams()
  const paginationRange = usePagination({
    totalPages,
    currentPage,
  })

  let queryParams = useMemo(
    function () {
      return {
        page: searchParams.get("page"),
      }
    },
    [searchParams]
  )

  const loadUsersLogs = useCallback(
    (pageNumber = 1) => {
      setIsLoading(true)

      queryParams.page = pageNumber

      let queryStr = new URLSearchParams({
        page: queryParams.page,
      }).toString()

      dispatch(
        loadEntities({
          endpoint: `logs/user/${userId}/auths?${queryStr}`,
        })
      )
        .unwrap()
        .then(r => {
          if (r !== undefined) {
            setUsersLogs(r.data)
          }

          setIsLoading(false)
        })
    },
    [dispatch, queryParams, userId]
  )

  const setQueryParam = useCallback(
    (name, value) => {
      const newQueryParameters = new URLSearchParams()

      newQueryParameters.set(name, value)
      setSearchParams(newQueryParameters)
    },
    [setSearchParams]
  )

  const setPage = useCallback(
    pageNumber => {
      setQueryParam("page", pageNumber)

      dispatch(setCurrentPage(pageNumber))

      loadUsersLogs(pageNumber)
    },
    [setQueryParam, dispatch, loadUsersLogs]
  )

  useEffect(() => {
    setPage(1)
  }, [setPage])

  return (
    <>
      <div className="border-2 rounded-md bg-light-blue px-3 mb-5">
        <div className="overflow-hidden overflow-x-auto visible-horizontal-scrollbar mb-2.5 min-h-[250px]">
          <h3 className="font-bold text-xl m-5 text-dark-gray text-center">
            User's app opens logs
          </h3>
          <table className="mt-2 text-center w-full border-t border-spacing-y-1.5 border-separate">
            <thead>
              <tr className="[&>td]:text-light-gray [&>td]:font-light">
                <td className="py-5 pl-6">Type</td>
                <td className="py-5">Date</td>
              </tr>
            </thead>
            <tbody>
              {usersLogs && !isLoading ? (
                usersLogs.length > 0 ? (
                  usersLogs.map(log => (
                    <tr
                      key={log.id}
                      className="[&>td]:border-y [&>td]:px-5 [&>td]:py-2 bg-white"
                    >
                      <td className="rounded-l-lg border-l">{log.type}</td>
                      <td className="rounded-r-lg border-r">
                        {log.created_at_formatted}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={2}
                      className="rounded-md py-7 px-3 text-light-gray text-center"
                    >
                      <span>Nothing not found</span>
                    </td>
                  </tr>
                )
              ) : (
                <RowsLoader columnsCount={2} rowsCount={perPage} />
              )}
            </tbody>
          </table>
        </div>
        <div>
          <p className="text-xs text-light-gray pb-2">
            Total records: {totalItems}
          </p>
        </div>
        <div>
          <ul className="flex justify-center mb-2.5">
            {paginationRange &&
              paginationRange.map((pageNumber, i) => {
                if (pageNumber === DOTS) {
                  return (
                    <li key={i} className="mx-1">
                      &#8230;
                    </li>
                  )
                }

                return (
                  <li
                    className={`mx-1 font-light cursor-pointer ${
                      pageNumber !== currentPage
                        ? "underline underline-offset-4"
                        : null
                    }`}
                    key={i}
                    onClick={() => {
                      setPage(pageNumber)
                    }}
                  >
                    {pageNumber}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </>
  )
}

export default UsersLogsDataTable
