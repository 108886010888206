import EntityIcon from "../EntityIcon"
import React, { useCallback, useEffect, useState } from "react"
import { loadEntities } from "../../store/entities/entities.actions"
import { useDispatch } from "react-redux"
import { getFullSiteName } from "../../helpers"
import { Roles, Statuses } from "../../constants"
import { Link } from "react-router-dom"
import ArrowDown from "@mui/icons-material/KeyboardArrowDown"
import Modal from "../Modal"
import RoleSelect from "../Selects/RoleSelect"
import SiteSelect from "../Selects/SiteSelect"
import { cloneDeep, toInteger } from "lodash"
import { deleteEntity, loadEntity, updateEntity } from "../../store/entity/entity.actions";
import InsertChartIcon from "@mui/icons-material/InsertChart"
import StatusesChart from "../StatusesChart"
import Button from "../Button"
import Input from "../Input"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"
import StudyStatusSelect from "../Selects/StudyStatusSelect"
import InviteModal from "../Modals/InviteModal"

const SitesWithUsers = ({ studyId }) => {
  const [sites, setSites] = useState([])
  const [allSites, setAllSites] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [visibility, setVisibility] = useState([])
  const [editShareModal, setEditShareModal] = useState(false)
  const [shareForm, setShareForm] = useState({})
  const [editedShareId, setEditedShareId] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const [siteStatuses, setSiteStatuses] = useState(null)
  const [editSiteStatuses, setEditSiteStatuses] = useState(false)
  const [updatedSiteStatuses, setUpdatedSiteStatuses] = useState({})
  const [editedSiteId, setEditedSitedId] = useState(null)
  const [isSiteStatusesSaving, setIsSiteStatusesSaving] = useState(false)
  const [addUsersModal, setAddUsersModal] = useState(false)

  const dispatch = useDispatch()

  const loadStudySites = useCallback(() => {
    setIsLoading(true)

    dispatch(
      loadEntities({
        endpoint: `studies/${studyId}/sites`,
      })
    )
      .unwrap()
      .then(result => {
        setIsLoading(false)
        setSites(result?.data ?? [])

        const array = new Array(result?.data.length).fill(false)
        if (array.length > 0) {
          array[0] = true
        }
        setVisibility(array)
      })
  }, [dispatch, studyId])

  const loadAllSites = useCallback(() => {
    dispatch(
      loadEntities({
        endpoint: `sites/`,
      })
    )
      .unwrap()
      .then(result => {
        setAllSites(result?.data ?? [])
      })
  }, [dispatch])

  const toggleVisibility = index => {
    const updatedVisibility = [...visibility]
    updatedVisibility[index] = !updatedVisibility[index]
    setVisibility(updatedVisibility)
  }

  const onShareFormChange = (field, value) => {
    setShareForm(() => {
      let updatedData = cloneDeep(shareForm)

      updatedData[field] = value

      return updatedData
    })
  }

  const handleSaveShare = () => {
    setIsSaving(true)

    dispatch(
      updateEntity({
        endpoint: `shares/${editedShareId}`,
        data: shareForm,
      })
    )
      .unwrap()
      .then(result => {
        if (result?.data) {
          loadStudySites()
          setEditShareModal(false)
        }

        setIsSaving(false)
      })
      .catch(() => {
        setIsSaving(false)
      })
  }

  const handleDeleteShare = () => {
    setIsSaving(true)

    dispatch(deleteEntity({ endpoint: `shares/${editedShareId}` }))
      .unwrap()
      .then(() => {
        loadStudySites()
        setEditShareModal(false)
        setIsSaving(false)
      })
      .catch(() => {
        setIsSaving(false)
      })
  }

  const loadSiteStatuses = siteId => {
    dispatch(
      loadEntity({
        endpoint: `studies/${studyId}/sites/${siteId}/statuses`,
      })
    )
      .unwrap()
      .then(result => {
        if (result?.data) {
          setSiteStatuses(null)
          setSiteStatuses(result.data)
        }
      })
  }

  const handleOpenStatusModal = siteId => {
    setStatusModal(true)
    setEditedSitedId(siteId)
    loadSiteStatuses(siteId)
  }

  const handleEditStatuses = () => {
    setUpdatedSiteStatuses(siteStatuses)
    setEditSiteStatuses(true)
  }

  const onSiteStatusesChange = (field, value) => {
    setUpdatedSiteStatuses(prev => {
      let updatedData = cloneDeep(prev)

      updatedData[field] = toInteger(value)

      return updatedData
    })
  }

  const handleCancelEdit = () => {
    setUpdatedSiteStatuses({})
    setEditSiteStatuses(false)
  }

  const onCloseStatusModal = () => {
    setSiteStatuses(null)
    setEditedSitedId(null)
    setUpdatedSiteStatuses({})
    setEditSiteStatuses(false)
    setStatusModal(false)
    handleCancelEdit()
  }

  const saveStatuses = () => {
    setIsSiteStatusesSaving(true)

    let data = {
      statuses: updatedSiteStatuses,
    }

    if (updatedSiteStatuses.status_id) {
      data.status_id = updatedSiteStatuses.status_id
    }

    dispatch(
      updateEntity({
        endpoint: `studies/${studyId}/sites/${editedSiteId}`,
        data: data,
      })
    )
      .unwrap()
      .then(result => {
        if (result?.data) {
          loadSiteStatuses(editedSiteId)
          handleCancelEdit()
          loadStudySites()

          addAlertWithTimeout(
            "Site statuses updated successfully",
            <p>Site statuses has been updated successfully.</p>,
            AlertType.Success
          )
        }

        setIsSiteStatusesSaving(false)
      })
      .catch(() => setIsSiteStatusesSaving(false))
  }

  useEffect(() => {
    loadStudySites()
    loadAllSites()
  }, [loadStudySites, loadAllSites])

  return (
    <>
      <h3 className="text-2xl mb-2 font-medium">Sites: </h3>
      <Button onClick={() => setAddUsersModal(true)} className="mt-5 mb-5">
        + Add users to the study
      </Button>
      <div className="w-full border-gray-300">
        <div className="grid grid-cols-4 [&>div]:px-3 [&>div]:py-2.5 [&>div]:text-sm [&>div]:text-light-gray ">
          <div>
            <div className="ml-16">Name</div>
          </div>
          <div className="text-center">Users</div>
          <div className="text-center">Status</div>
          <div></div>
        </div>
        {isLoading ? (
          <div className="bg-white w-full px-3 py-7 rounded-md text-center">
            Loading...
          </div>
        ) : (
          sites.length > 0 &&
          sites.map((site, index) => (
            <div key={site.id} className="bg-white rounded-md mb-4">
              <div className="grid grid-cols-4">
                <div className="pl-5 py-7 flex items-center">
                  <button onClick={() => toggleVisibility(index)}>
                    <ArrowDown
                      className={`mt-0.5 mr-4 transition duration-300 ${
                        visibility[index] ? "" : "-rotate-90"
                      } `}
                      style={{ color: "#446B95", fontSize: 36 }}
                    />
                  </button>
                  <p>
                    <Link
                      to={`/sites/${site.id}`}
                      className="text-link-color transition hover:opacity-70"
                    >
                      {getFullSiteName(site)}
                    </Link>
                  </p>
                </div>
                <div className="py-7 flex items-center justify-center">
                  <p>
                    <button
                      className="text-link-color text-sm transition hover:opacity-70"
                      onClick={() => toggleVisibility(index)}
                    >
                      {site.users.length}
                    </button>
                  </p>
                </div>
                <div className="py-7 flex items-center justify-center">
                  <button
                    onClick={() => handleOpenStatusModal(site.id)}
                    className="border px-2 py-1.5 mr-4 rounded-lg shadow hover:opacity-80 transition"
                  >
                    <InsertChartIcon
                      style={{ color: "#8A8888", fontSize: 24 }}
                    />
                  </button>
                  <div
                    className="rounded-full w-3 h-3 mr-2 mt-0.5"
                    style={{
                      backgroundColor: Statuses[site.status_id].color,
                    }}
                  ></div>
                  <p>{Statuses[site.status_id].title}</p>
                </div>
                <div className="px-5 py-7 flex items-center justify-center">
                  <Link
                    to={`/sites/${site.id}`}
                    className="text-link-color text-sm transition hover:opacity-70"
                  >
                    View site
                  </Link>
                </div>
              </div>
              {visibility[index] && <hr />}
              <div
                className={`pl-5 pr-3 toggle-div ${
                  visibility[index] ? "show" : "hide"
                }`}
              >
                <table className="w-full my-4">
                  <thead>
                    <tr className="[&>td]:px-3 [&>td]:pb-3 [&>td]:text-sm [&>td]:text-light-gray">
                      <td></td>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Role</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {site.users.map(user => (
                      <tr key={`user-${user.id}`}>
                        <td className="border-l-4 pl-5 py-5 items-center">
                          <EntityIcon size="md" src={user.image_path} />
                        </td>
                        <td className="py-4 px-3">
                          <p>
                            <Link
                              to={`/users/${user.id}`}
                              className="text-link-color transition hover:opacity-70"
                            >
                              {user.full_name}
                            </Link>
                          </p>
                        </td>
                        <td className="py-4 px-3">
                          <p>{user.email}</p>
                        </td>
                        <td className="py-4 px-3">
                          <p>{Roles[user.role_id]}</p>
                        </td>
                        <td className="px-5 py-4">
                          <button
                            className="text-link-color text-sm transition hover:opacity-70"
                            onClick={() => {
                              setEditShareModal(true)
                              setEditedShareId(user.share_id)
                              setShareForm({
                                site_id: site.id,
                                role_id: user.role_id,
                              })
                            }}
                          >
                            Edit share
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))
        )}
      </div>

      <Modal
        title="Edit share"
        size="lg"
        isShown={editShareModal}
        setIsShown={setEditShareModal}
        onSubmit={handleSaveShare}
        submitActionLoading={isSaving}
        leftButtonText="Delete share"
        leftButtonClassNames="text-red-500"
        leftButtonAction={handleDeleteShare}
      >
        <div>
          <div className="mb-5">
            <RoleSelect
              id="role"
              value={shareForm.role_id ?? ""}
              onChange={event =>
                onShareFormChange("role_id", event.target.value)
              }
            />
          </div>
          <div>
            <SiteSelect
              id="site"
              value={shareForm.site_id ?? ""}
              onChange={event => {
                onShareFormChange("site_id", event.target.value)
              }}
              sites={allSites}
            />
          </div>
        </div>
      </Modal>

      <Modal
        title="Site status"
        size="lg"
        isShown={statusModal}
        setIsShown={setStatusModal}
        onSubmit={onCloseStatusModal}
        submitActionLoading={isSaving}
        onClose={handleCancelEdit}
        submitText="Ok"
        showSecondary={false}
      >
        <div className="mt-5 mb-4 w-72 mx-auto">
          {siteStatuses ? (
            <>
              <div className="h-1 bg-[#CBCBCB] shadow rounded-lg flex flex-row justify-between">
                {Object.keys(Statuses).map(key => (
                  <div
                    onClick={() => {
                      if (editSiteStatuses) {
                        setUpdatedSiteStatuses(prev => {
                          let updatedData = cloneDeep(prev)

                          updatedData.status_id = key

                          return updatedData
                        })
                      }
                    }}
                    key={key}
                    className={`w-8 h-8 -mt-3.5 rounded-full flex items-center justify-center ${
                      toInteger(
                        updatedSiteStatuses?.status_id ?? siteStatuses.status_id
                      ) === toInteger(key)
                        ? "border-4"
                        : ""
                    }`}
                    style={{
                      borderColor: Statuses[key].borderColor,
                      backgroundColor:
                        toInteger(
                          updatedSiteStatuses?.status_id ??
                            siteStatuses.status_id
                        ) === toInteger(key)
                          ? Statuses[key].color
                          : Statuses[key].disabled,
                    }}
                  >
                    {toInteger(
                      updatedSiteStatuses?.status_id ?? siteStatuses.status_id
                    ) === toInteger(key) && (
                      <div className="bg-white w-4 h-4 rounded-full"></div>
                    )}
                  </div>
                ))}
              </div>
              {editSiteStatuses ? (
                <div className="mt-8">
                  <StudyStatusSelect
                    id="status_id"
                    value={updatedSiteStatuses.status_id}
                    onChange={event =>
                      onSiteStatusesChange("status_id", event.target.value)
                    }
                  />
                </div>
              ) : (
                <p className="mt-7 font-bold text-dark-gray text-center">
                  {Statuses[siteStatuses.status_id].title}
                </p>
              )}
            </>
          ) : (
            <p className="text-center text-light-gray">Loading...</p>
          )}
        </div>
        <div className="bg-light-blue">
          <StatusesChart statuses={siteStatuses} />
        </div>
        <div className="flex flex-row mt-4">
          {editSiteStatuses && (
            <>
              <div className="flex flex-col mr-2">
                <span>Committed:</span>
                <Input
                  value={updatedSiteStatuses.committed}
                  onChange={event =>
                    onSiteStatusesChange("committed", event.target.value)
                  }
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </div>
              <div className="flex flex-col mr-2">
                <span>Consented:</span>
                <Input
                  value={updatedSiteStatuses.consented}
                  onChange={event =>
                    onSiteStatusesChange("consented", event.target.value)
                  }
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </div>
              <div className="flex flex-col mr-2">
                <span>Screened:</span>
                <Input
                  value={updatedSiteStatuses.screened}
                  onChange={event =>
                    onSiteStatusesChange("screened", event.target.value)
                  }
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </div>
              <div className="flex flex-col">
                <span>Enrolled:</span>
                <Input
                  value={updatedSiteStatuses.enrolled}
                  onChange={event =>
                    onSiteStatusesChange("enrolled", event.target.value)
                  }
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="flex justify-center mt-4">
          {siteStatuses && (
            <>
              {editSiteStatuses && (
                <Button className="mr-2" onClick={handleCancelEdit}>
                  Cancel
                </Button>
              )}
              <Button
                className={`${editSiteStatuses ? "text-green-700" : ""} ${
                  isSiteStatusesSaving ? "opacity-80" : ""
                }`}
                onClick={editSiteStatuses ? saveStatuses : handleEditStatuses}
                disabled={isSiteStatusesSaving}
              >
                {editSiteStatuses
                  ? isSiteStatusesSaving
                    ? "Saving..."
                    : "Save"
                  : "Edit"}
              </Button>
            </>
          )}
        </div>
      </Modal>

      <InviteModal
        studyId={studyId}
        isShown={addUsersModal}
        setIsShown={setAddUsersModal}
        sites={sites}
        onInvite={loadStudySites}
      />
    </>
  )
}

export default SitesWithUsers
