import React from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { Statuses } from "../../constants";

const StudyStatusSelect = ({ id, value, onChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={id}>Select status</InputLabel>
      <Select label="Select status" id={id} value={value} onChange={onChange}>
        {Object.keys(Statuses).map(id => (
          <MenuItem key={id} value={id}>
            {Statuses[id].title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default StudyStatusSelect
