import React, { useCallback, useEffect, useState } from "react"
import ContentLoader from "react-content-loader"
import Input from "../../Input"
import {
  clearStudyInstructionalVideo,
  loadStudyInstructionalVideo,
  updateStudyInstructionalVideo,
} from "../../../store/entity/entity.actions"
import { useDispatch } from "react-redux"
import Button from "../../Button"
import Modal from "../../Modal"
import Dropzone from "../../DropZone"
import { bytesToMB } from "../../../helpers"
import { addAlertWithTimeout, AlertType } from "../../../utils/alertUtils"
const InstructionalVideo = ({ studyId }) => {
  const dispatch = useDispatch()
  const [editInstructionalVideo, setEditInstructionalVideo] = useState(false)
  const [instructionalVideo, setInstructionalVideo] = useState(null)
  const [newInstructionalVideoPath, setNewInstructionalVideoPath] = useState("")
  const [instructionalVideoLoading, setInstructionalVideoLoading] =
    useState(false)
  const [selectInstructionalVideoUrl, setSelectInstructionalVideoUrl] =
    useState(false)
  const [instructionalVideoSaving, setInstructionalVideoSaving] =
    useState(false)
  const [instructionalVideoFileModal, setInstructionalVideoFileModal] =
    useState(false)
  const [instructionalVideoFile, setInstructionalVideoFile] = useState(null)
  const [instructionalVideoEdited, setInstructionalVideoEdited] =
    useState(false)
  const [
    confirmClearInstructionalVideoModal,
    setConfirmClearInstructionalVideoModal,
  ] = useState(false)

  const loadInstructionalVideo = useCallback(() => {
    setInstructionalVideoLoading(true)

    dispatch(loadStudyInstructionalVideo({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          if (!r.data?.is_file) {
            setNewInstructionalVideoPath(r.data?.path ?? "")
          }
          setInstructionalVideo(r.data)
        }

        setInstructionalVideoLoading(false)
      })
  }, [dispatch, studyId])

  const saveInstructionalVideo = async () => {
    let formData = new FormData()

    setInstructionalVideoSaving(true)

    if (instructionalVideoFile) {
      formData.append("file", instructionalVideoFile)
      formData.append("is_file", "true")
    } else {
      formData.append("path", newInstructionalVideoPath)
      formData.append("is_file", "false")
    }

    dispatch(updateStudyInstructionalVideo({ id: studyId, data: formData }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          setInstructionalVideo(r.data)

          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Study Instructional Video has been updated successfully.</p>
            </>,
            AlertType.Success
          )
        }

        setInstructionalVideoSaving(false)
        setEditInstructionalVideo(false)
      })
  }

  const clearInstructionalVideo = () => {
    setInstructionalVideoSaving(true)

    dispatch(clearStudyInstructionalVideo({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Study Instructional Video has been removed successfully.</p>
            </>,
            AlertType.Warning
          )

          setInstructionalVideo(null)
          setConfirmClearInstructionalVideoModal(false)
        }

        setInstructionalVideoSaving(false)
        setEditInstructionalVideo(false)
      })
  }

  useEffect(() => {
    loadInstructionalVideo()
  }, [loadInstructionalVideo])

  return (
    <>
      <div className="bg-white rounded-lg px-5 border mt-2.5 p-5">
        <div className="flex flex-row justify-between items-center">
          <h4 className="font-bold text-xl">Study Instructional Video</h4>
          <div>
            {editInstructionalVideo && (
              <Button
                className={`px-8 py-1.5 mb-0 mr-2 text-green-700 ${
                  !instructionalVideoEdited || instructionalVideoSaving
                    ? "opacity-40"
                    : ""
                }`}
                onClick={saveInstructionalVideo}
                disabled={!instructionalVideoEdited || instructionalVideoSaving}
              >
                {instructionalVideoSaving ? "Saving..." : "Save"}
              </Button>
            )}
            <Button
              className="px-3 py-1.5 mb-0"
              onClick={() => {
                setSelectInstructionalVideoUrl(false)
                setInstructionalVideoFile(null)
                setEditInstructionalVideo(prev => !prev)
              }}
            >
              {editInstructionalVideo ? (
                "Cancel"
              ) : (
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87M0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z"
                    fill="black"
                  />
                </svg>
              )}
            </Button>
          </div>
        </div>
        <hr />
        <div className="mt-5 py-3 px-5 rounded-lg border flex flex-row items-center my-2">
          <span className="w-1/2 inline-block font-medium">
            Study instructional video
          </span>
          <div className="w-1/2">
            {instructionalVideoLoading ? (
              <ContentLoader
                speed={2}
                width="100%"
                height={24}
                backgroundColor="#c2c2c2"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
              </ContentLoader>
            ) : editInstructionalVideo ? (
              selectInstructionalVideoUrl ? (
                <div className="flex items-center">
                  <Input
                    value={newInstructionalVideoPath}
                    onChange={e => {
                      setNewInstructionalVideoPath(e.target.value)
                      setInstructionalVideoEdited(true)
                    }}
                    placeholder="Enter URL..."
                  />
                  <button
                    className="pl-2.5 hover:opacity-70 transition h-full text-red-500"
                    onClick={() => {
                      setInstructionalVideoEdited(false)
                      setSelectInstructionalVideoUrl(false)
                      setNewInstructionalVideoPath("")
                    }}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="w-fit mx-auto border border-dashed rounded-xl flex flex-row text-dark-gray font-light">
                  {instructionalVideoFile ? (
                    <>
                      <button
                        className="pl-4 pr-2.5 py-4 hover:opacity-70 transition h-full flex flex-row justify-around"
                        onClick={() => setInstructionalVideoFileModal(true)}
                      >
                        <span className="max-w-[200px] truncate mr-1.5">
                          {instructionalVideoFile.name}
                        </span>
                        <span className="font-medium">
                          {bytesToMB(instructionalVideoFile.size)} MB
                        </span>
                      </button>
                      <button
                        className="pr-4 pl-2.5 py-4 hover:opacity-70 transition h-full text-red-500"
                        onClick={() => {
                          setInstructionalVideoFile(null)
                          setInstructionalVideoEdited(false)
                        }}
                      >
                        Clear
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="pl-4 pr-2.5 py-4 hover:opacity-70 transition h-full"
                        onClick={() => setInstructionalVideoFileModal(true)}
                      >
                        {instructionalVideo && instructionalVideo.is_file
                          ? "Upload new file"
                          : "Select file"}
                      </button>
                      <button
                        className="px-2.5 py-4 hover:opacity-70 transition h-full"
                        onClick={() => setSelectInstructionalVideoUrl(true)}
                      >
                        {instructionalVideo && !instructionalVideo.is_file
                          ? "Edit URL"
                          : "Select URL"}
                      </button>
                      {instructionalVideo && (
                        <button
                          className="pr-4 pl-2.5 py-4 hover:opacity-70 transition h-full text-red-500"
                          onClick={() =>
                            setConfirmClearInstructionalVideoModal(true)
                          }
                        >
                          Clear all
                        </button>
                      )}
                    </>
                  )}
                </div>
              )
            ) : instructionalVideo ? (
              instructionalVideo.is_file ? (
                <a
                  href={instructionalVideo.link}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full block border border-dashed border-light-gray text-light-gray rounded-lg font-light px-3 truncate hover:opacity-80 transition"
                >
                  {instructionalVideo.path}
                </a>
              ) : (
                <a
                  href={instructionalVideo.link}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full block text-blue hover:opacity-70 transition inline-block truncate"
                >
                  {instructionalVideo.path}
                </a>
              )
            ) : (
              <div className="text-light-gray text-center">
                No file uploaded
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        title="Upload file"
        size="lg"
        isShown={instructionalVideoFileModal}
        setIsShown={setInstructionalVideoFileModal}
        submitActionActive={instructionalVideoFile}
        noButtons={true}
      >
        <Dropzone
          setFile={setInstructionalVideoFile}
          onDropAction={() => {
            setInstructionalVideoFileModal(false)
            setInstructionalVideoEdited(true)
          }}
        />
      </Modal>

      <Modal
        title="Clear InstructionalVideo"
        size="md"
        isShown={confirmClearInstructionalVideoModal}
        setIsShown={setConfirmClearInstructionalVideoModal}
        onSubmit={clearInstructionalVideo}
        submitText="Yes"
      >
        <p>Are you sure you want to clear InstructionalVideo?</p>
      </Modal>
    </>
  )
}

export default InstructionalVideo
