import React from "react"
import { Link } from "react-router-dom"

const Pagination = ({ data = null }) => (
  <div className="flex items-center mb-5">
    {data &&
      data.map((item, index) => (
        <React.Fragment key={index}>
          <Link
            to={`${item.link}`}
            className={`block px-8 py-2 border rounded-full hover:bg-gray-50 transition max-w-sm truncate ${
              index === data.length - 1 && "font-medium bg-light-blue"
            } ${
              index === data.length - 1
                ? "pointer-events-none cursor-default"
                : null
            }`}
          >
            {item.title}
          </Link>
          {index !== data.length - 1 && <div className="pagination-line"></div>}
        </React.Fragment>
      ))}
  </div>
)

export default Pagination
