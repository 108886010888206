import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  archiveStudy,
  deleteEntity,
  loadEntity,
  unarchiveStudy,
  updateEntity,
} from "../../store/entity/entity.actions"
import ContentLoader from "react-content-loader"
import Pagination from "../../components/Pagination"
import Button from "../../components/Button"
import Input from "../../components/Input"
import Modal from "../../components/Modal"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"
import { clearEntity, setIsLoading } from "../../store/entity/entity.reducer"
import {
  prepareBeforeUpdate,
  resolve,
  updateNestedProperty,
} from "../../helpers"
import cloneDeep from "lodash/cloneDeep"
import StatusesChart from "../../components/StatusesChart"
import Criteria from "../../components/StudySections/Criteria"
import Details from "../../components/StudySections/Details"
import SupplInfo from "../../components/StudySections/SupplInfo"
import { Switch } from "@mui/material"
import SitesWithUsers from "../../components/StudySections/SitesWithUsers"

const SingleStudyPage = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [edit, setEdit] = useState(false)
  const [study, setStudy] = useState(null)
  const [isStudyLoading, setIsStudyLoading] = useState(null)
  const [updatedStudy, setUpdatedStudy] = useState({})
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmArchiveModal, setConfirmArchiveModal] = useState(false)
  const [confirmUnarchiveModal, setConfirmUnarchiveModal] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [navigation, setNavigation] = useState({
    criteria: { uri: "criteria", active: true },
    details: { uri: "details", active: false },
    sites: { uri: "sites", active: false },
    intra: { uri: "intra", active: false },
    inter: { uri: "inter", active: false },
    supple: { uri: "supple-info", active: false },
  })
  const fields = [
    { title: "Study name", editable: true, key: "name" },
    { title: "Topic", editable: false, key: "topic.name" },
    { title: "Sponsor", editable: false, key: "sponsor.name" },
    { title: "Sub-Topic", editable: true, key: "sub_topic" },
  ]

  const updateStudy = () => {
    setUpdatedStudy(study)
  }

  const toggleEdit = () => {
    if (!edit) {
      updateStudy()
    }

    setEdit(!edit)
  }

  const save = async () => {
    const validated = prepareBeforeUpdate(updatedStudy)

    setIsUpdating(true)

    dispatch(setIsLoading(true))

    return dispatch(
      updateEntity({ endpoint: "studies/" + id, data: validated })
    )
      .unwrap()
      .then(r => {
        setIsUpdating(false)

        if (r !== undefined) {
          dispatch(loadEntity({ endpoint: "studies/" + id }))
          setEdit(false)

          return true
        }

        return false
      })
  }

  const deleteItem = () => {
    setConfirmDeleteModal(true)
  }

  const archiveItem = () => {
    setConfirmArchiveModal(true)
  }

  const unarchiveItem = () => {
    setConfirmUnarchiveModal(true)
  }

  const cancelEdit = () => {
    setEdit(!edit)
  }

  const changeField = (key, value) => {
    if (key.includes(".")) {
      let item = cloneDeep(updatedStudy)

      updateNestedProperty(item, key, value)

      setUpdatedStudy(item)
    } else {
      let item = { ...updatedStudy }

      item[key] = value

      setUpdatedStudy(item)
    }
  }

  const deleteAction = () => {
    dispatch(deleteEntity({ endpoint: `studies/${study.id}` }))
      .unwrap()
      .then(response => {
        if (response !== undefined) {
          setConfirmDeleteModal(false)

          setTimeout(() => {
            addAlertWithTimeout(
              "Study successfully deleted",
              <>
                <p>
                  <span className="font-bold">{study.name}</span> has been
                  deleted successfully.
                </p>
              </>,
              AlertType.Warning
            )
          }, 500)

          return navigate(`/studies`, { replace: true })
        }
      })
  }

  const archiveAction = () => {
    dispatch(archiveStudy({ id: study.id }))
      .unwrap()
      .then(response => {
        if (response !== undefined) {
          setConfirmArchiveModal(false)

          dispatch(loadEntity({ endpoint: "studies/" + id }))

          setTimeout(() => {
            addAlertWithTimeout(
              "Study successfully archived",
              <>
                <p>
                  <span className="font-bold">{study.name}</span> has been
                  archived.
                </p>
              </>,
              AlertType.Warning
            )
          }, 500)
        }
      })
  }

  const unarchiveAction = () => {
    dispatch(unarchiveStudy({ id: study.id }))
      .unwrap()
      .then(response => {
        if (response !== undefined) {
          setConfirmUnarchiveModal(false)

          dispatch(loadEntity({ endpoint: "studies/" + id }))

          setTimeout(() => {
            addAlertWithTimeout(
              "Study successfully unarchived",
              <>
                <p>
                  <span className="font-bold">{study.name}</span> has been
                  deleted unarchived.
                </p>
              </>,
              AlertType.Success
            )
          }, 500)
        }
      })
  }

  const setNav = route => {
    let nav = cloneDeep(navigation)

    nav = Object.keys(nav).reduce((acc, key) => {
      acc[key] = { ...nav[key], active: false }
      return acc
    }, {})

    nav[route]["active"] = true

    setNavigation(nav)
  }

  useEffect(() => {
    setIsStudyLoading(true)

    dispatch(loadEntity({ endpoint: "studies/" + id }))
      .unwrap()
      .then(r => {
        setIsStudyLoading(false)

        if (r?.data !== undefined) {
          setStudy(r.data)
        }
      })

    return () => dispatch(clearEntity())
  }, [dispatch, id])

  return (
    <>
      <Pagination
        data={
          study
            ? [
              { title: "Studies", link: "/studies" },
              {
                title: `${study?.sponsor?.name ?? "(Empty)"}`,
                link: `/sponsors/${study?.sponsor?.id ?? ""}`,
              },
              {
                title: `${study.name}`,
                link: `/studies/${study.id}`,
              },
            ]
            : null
        }
      />

      <div className="flex flex-col lg:flex-row max-w-[100%] xl:max-w-[85%]">
        <div className="flex flex-col md:flex-row w-full bg-light-blue rounded-md border-2 py-8 px-8 md:mr-5">
          <div className="flex flex-col sm:flex-row grow flex-wrap">
            {fields.map((field, index) => (
              <div className="w-[200px] mb-5" key={index}>
                <span className="text-sm text-light-gray">{field.title}</span>
                <br />
                {isStudyLoading && (
                  <ContentLoader
                    speed={2}
                    width="100%"
                    height={24}
                    backgroundColor="#c2c2c2"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="2" rx="8" ry="8" width="80%" height="12" />
                  </ContentLoader>
                )}
                {!isStudyLoading &&
                  study &&
                  (edit && field.editable ? (
                    <div className="pr-5">
                      <Input
                        id={field.key}
                        value={updatedStudy[field.key] ?? ""}
                        onChange={event => {
                          changeField(field.key, event.target.value)
                        }}
                      />
                    </div>
                  ) : (
                    <span className="font-medium">
                      {resolve(study, field.key) ?? "(Empty)"}
                    </span>
                  ))}
              </div>
            ))}
            <div className="w-[200px] mb-5">
              <span className="text-sm text-light-gray">Editable</span>
              <div>
                {isStudyLoading ? (
                  <ContentLoader
                    speed={2}
                    width="100%"
                    height={24}
                    backgroundColor="#c2c2c2"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="2" rx="8" ry="8" width="80%" height="12" />
                  </ContentLoader>
                ) : edit ? (
                  <Switch
                    checked={updatedStudy["editable_value"]}
                    onChange={event => {
                      setUpdatedStudy(prev => {
                        let updatedData = cloneDeep(prev)

                        updatedData["editable_value"] =
                          !updatedData["editable_value"]

                        updatedData["editable"] = updatedData["editable_value"]

                        return updatedData
                      })
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                ) : study && study["editable_value"] ? (
                  "True"
                ) : (
                  "False"
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <Button
              onClick={edit ? save : toggleEdit}
              loading={isUpdating || isStudyLoading}
            >
              {isUpdating ? "..." : edit ? "Save" : "Edit"}
            </Button>
            <Button
              onClick={edit ? cancelEdit : deleteItem}
              className="text-red-500"
              loading={isUpdating || isStudyLoading}
            >
              {edit ? "Cancel" : "Delete"}
            </Button>
            <Button
              onClick={
                edit
                  ? () => {
                  }
                  : study && study.archived
                    ? unarchiveItem
                    : archiveItem
              }
              className="text-red-500"
              loading={isUpdating || isStudyLoading}
              disabled={edit}
            >
              {study && study.archived ? "Unarchive" : "Archive"}
            </Button>
          </div>
        </div>
        <div className="bg-light-blue rounded-md border-2 w-full lg:w-[400px] lg:h-auto mt-5 lg:mt-0 flex">
          <StatusesChart statuses={study && study.statuses} />
        </div>
      </div>
      <div
        className="flex flex-col w-full bg-light-blue rounded-md border-2 mt-5 py-8 px-8 max-w-[100%] xl:max-w-[85%]">
        <div className="flex flex-row w-full bg-ultralight-gray rounded-md border-2 p-4 justify-around">
          <div
            className={`cursor-pointer flex flex-row items-center ${
              navigation.criteria.active && "text-dark-blue font-medium"
            }`}
            onClick={() => setNav("criteria")}
          >
            {navigation.criteria.active && (
              <svg
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 7L11 3.5L12.4 2.1L14.5 4.2L18.7 0L20.1 1.4L14.5 7ZM9 3H0V5H9V3ZM19 9.4L17.6 8L15 10.6L12.4 8L11 9.4L13.6 12L11 14.6L12.4 16L15 13.4L17.6 16L19 14.6L16.4 12L19 9.4ZM9 11H0V13H9V11Z"
                  fill="#255888"
                />
              </svg>
            )}
            <span className="ml-1.5">Incl/Excl</span>
          </div>
          <div
            className={`cursor-pointer flex flex-row items-center ${
              navigation.details.active && "text-dark-blue font-medium"
            }`}
            onClick={() => setNav("details")}
          >
            {navigation.details.active && (
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM4 6H14V4H16V18H2V4H4V6ZM9 16V14H14V16H9ZM9 10V8H14V10H9ZM5 11V8H4V7H6V11H5ZM6.25 13C6.66 13 7 13.34 7 13.75C7 13.95 6.92 14.14 6.79 14.27L5.12 16H7V17H4V16.08L6 14H4V13H6.25Z"
                  fill="#255888"
                />
              </svg>
            )}
            <span className="ml-1.5">Study details</span>
          </div>
          <div
            className={`cursor-pointer flex flex-row items-center ${
              navigation.sites.active && "text-dark-blue font-medium"
            }`}
            onClick={() => setNav("sites")}
          >
            {navigation.sites.active && (
              <svg
                width="24"
                height="19"
                viewBox="0 0 24 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0V2.26L12 3.59V2H22V17H17V19H24V0H10ZM7.5 3L0 8V19H15V8L7.5 3ZM14 4V4.93L15.61 6H16V4H14ZM18 4V6H20V4H18ZM7.5 5.5L13 9V17H10V11H5V17H2V9L7.5 5.5ZM18 8V10H20V8H18ZM18 12V14H20V12H18Z"
                  fill="#255888"
                />
              </svg>
            )}
            <span className="ml-1.5">Sites</span>
          </div>
          <div
            className={`cursor-pointer flex flex-row items-center ${
              navigation.inter.active && "text-dark-blue font-medium"
            }`}
            onClick={() => setNav("inter")}
          >
            {navigation.inter.active && (
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 12H11V14H15V16L18 13L15 10V12ZM8 0C5.8 0 4 1.8 4 4C4 6.2 5.8 8 8 8C10.2 8 12 6.2 12 4C12 1.8 10.2 0 8 0ZM8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 9C5.3 9 0 10.3 0 13V16H9.5C9.2 15.4 9.1 14.8 9 14.1H1.9V13C1.9 12.4 5 10.9 8 10.9C8.5 10.9 9 11 9.5 11C9.8 10.4 10.1 9.8 10.6 9.3C9.6 9.1 8.7 9 8 9Z"
                  fill="#255888"
                />
              </svg>
            )}
            <span className="ml-1.5">Inter-site</span>
          </div>
          <div
            className={`cursor-pointer flex flex-row items-center ${
              navigation.supple.active && "text-dark-blue font-medium"
            }`}
            onClick={() => setNav("supple")}
          >
            {navigation.supple.active && (
              <svg
                width="24"
                height="19"
                viewBox="0 0 24 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 10.6751C18.8618 10.6751 19.7782 10.762 20.7273 10.9357V12.5647C20.0509 12.3909 19.1345 12.3041 18 12.3041C15.9273 12.3041 14.3018 12.6624 13.0909 13.3792V11.5439C14.3673 10.9683 16.0036 10.6751 18 10.6751ZM13.0909 8.64434C14.4982 8.06878 16.1345 7.78643 18 7.78643C18.8618 7.78643 19.7782 7.86244 20.7273 8.0362V9.66516C20.0509 9.4914 19.1345 9.40452 18 9.40452C15.9273 9.40452 14.3018 9.77376 13.0909 10.4796M18 6.51584C15.9273 6.51584 14.3018 6.86335 13.0909 7.60181V5.79909C14.4327 5.19095 16.0691 4.88688 18 4.88688C18.8618 4.88688 19.7782 4.97375 20.7273 5.13665V6.81991C19.92 6.61357 18.9927 6.51584 18 6.51584ZM21.8182 15.2036V2.71493C20.6836 2.35656 19.4073 2.17195 18 2.17195C15.7636 2.17195 13.7673 2.71493 12 3.8009V16.2896C13.7673 15.2036 15.7636 14.6606 18 14.6606C19.2982 14.6606 20.5745 14.8344 21.8182 15.2036ZM18 0C20.5636 0 22.5709 0.542986 24 1.62896V17.4407C24 17.571 23.9455 17.7014 23.8255 17.8208C23.7055 17.9186 23.5745 18.0054 23.4545 18.0054C23.3345 18.0054 23.2473 17.9837 23.1818 17.9511C21.7855 17.2018 20.0509 16.8326 18 16.8326C15.7636 16.8326 13.7673 17.3756 12 18.4615C10.5382 17.3756 8.54182 16.8326 6 16.8326C4.18909 16.8326 2.45455 17.2235 0.818182 17.9946C0.785455 18.0054 0.741818 18.0054 0.687273 18.0271C0.643636 18.038 0.6 18.0489 0.545455 18.0489C0.425455 18.0489 0.294545 18.0054 0.174545 17.9186C0.0545454 17.7991 0 17.6796 0 17.5385V1.62896C1.46182 0.542986 3.46909 0 6 0C8.54182 0 10.5382 0.542986 12 1.62896C13.4618 0.542986 15.4582 0 18 0Z"
                  fill="#446B95"
                />
              </svg>
            )}
            <span className="ml-1.5">Supple info</span>
          </div>
        </div>

        <div className="mt-5">
          {navigation.criteria.active && (
            <Criteria
              isLoading={isStudyLoading}
              data={study && study.criteria}
              study={updatedStudy}
              setStudy={setUpdatedStudy}
              updateStudy={updateStudy}
              save={save}
            />
          )}
          {navigation.details.active && (
            <Details
              isLoading={isStudyLoading}
              data={study ?? null}
              study={updatedStudy}
              setStudy={setUpdatedStudy}
              updateStudy={updateStudy}
              save={save}
            />
          )}
          {navigation.sites.active && <SitesWithUsers studyId={id} />}
          {navigation.supple.active && (
            <SupplInfo
              studyId={id}
              data={study}
              study={updatedStudy}
              setStudy={setUpdatedStudy}
            />
          )}
        </div>
      </div>

      <Modal
        title="Delete Study"
        size="md"
        isShown={confirmDeleteModal}
        setIsShown={setConfirmDeleteModal}
        onSubmit={deleteAction}
        submitText="Yes"
      >
        <p>
          Are you sure you want to delete{" "}
          <span className="font-bold">{study && `${study.name}`}</span>
        </p>
      </Modal>

      <Modal
        title="Archive Study"
        size="md"
        isShown={confirmArchiveModal}
        setIsShown={setConfirmArchiveModal}
        onSubmit={archiveAction}
        submitText="Yes"
      >
        <p>
          Are you sure you want to archive{" "}
          <span className="font-bold">{study && `${study.name}`}</span>
        </p>
      </Modal>

      <Modal
        title="Unarchive Study"
        size="md"
        isShown={confirmUnarchiveModal}
        setIsShown={setConfirmUnarchiveModal}
        onSubmit={unarchiveAction}
        submitText="Yes"
      >
        <p>
          Are you sure you want to unarchive{" "}
          <span className="font-bold">{study && `${study.name}`}</span>
        </p>
      </Modal>
    </>
  )
}

export default SingleStudyPage
