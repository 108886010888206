import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { loadEntities } from "../../store/entities/entities.actions"
import { selectEntitiesData } from "../../store/entities/entities.selector"
import { DOTS, usePagination } from "../../hooks/usePagination"
import { setCurrentPage } from "../../store/entities/entities.reducer"
import RowsLoader from "./RowsLoader"
import debounce from "lodash.debounce"
import EntityIcon from "../EntityIcon"
import { resolve } from "../../helpers"
import Checkbox from "@mui/material/Checkbox"

const DataTable = forwardRef(
  (
    {
      rows,
      endpoint,
      setAddNewModal,
      searchByText = "",
      newItemRoute = null,
      selectableOnly = false,
      selectedItems = [],
      disabledItems = []
    },
    ref
  ) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [search, setSearch] = useState("")
    const [selected, setSelected] = useState(selectedItems)
    const { data, currentPage, totalPages, perPage, totalItems } =
      useSelector(selectEntitiesData)
    const paginationRange = usePagination({
      totalPages,
      currentPage,
    })
    let [searchParams, setSearchParams] = useSearchParams()
    let queryParams = useMemo(
      function () {
        return {
          page: searchParams.get("page"),
        }
      },
      [searchParams]
    )
    const label = { inputProps: { "aria-label": "Select user" } }

    const prepareLink = (link, param) => {
      return link.replace("{param}", param)
    }

    const setQueryParam = useCallback(
      (name, value) => {
        const newQueryParameters = new URLSearchParams()

        newQueryParameters.set(name, value)
        setSearchParams(newQueryParameters)
      },
      [setSearchParams]
    )

    const setPage = useCallback(
      pageNumber => {
        queryParams.page = pageNumber
        let queryStr = new URLSearchParams({
          page: queryParams.page,
          search: search,
        }).toString()

        setQueryParam("page", pageNumber)

        dispatch(setCurrentPage(pageNumber))
        dispatch(loadEntities({ endpoint: endpoint + "?" + queryStr }))
      },
      [dispatch, endpoint, queryParams, setQueryParam, search]
    )

    const debounceFn = useMemo(
      () =>
        debounce(inputValue => {
          queryParams.page = 1
          setQueryParam("page", 1)
          dispatch(setCurrentPage(1))

          let queryStr = new URLSearchParams({
            page: queryParams.page,
            search: inputValue,
          }).toString()

          dispatch(loadEntities({ endpoint: endpoint + "?" + queryStr }))
        }, 500),
      [dispatch, endpoint, queryParams, setQueryParam]
    )

    const onChangeSearch = event => {
      setSearch(event.target.value)
      debounceFn(event.target.value)
    }

    const handleSelectItem = (checked, id) => {
      if (checked) {
        setSelected(prev => [...prev, id]);
      } else {
        const index = selected.indexOf(id);

        if (index !== -1) {
          setSelected(prev => {
            const updatedArray = [...prev];
            updatedArray.splice(index, 1);
            return updatedArray;
          });
        }
      }
    }

    const getSelectedIds = () => {
      return selected
    }

    useImperativeHandle(ref, () => ({
      getSelectedIds,
    }))

    useEffect(() => {
      setPage(queryParams.page ?? 1)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div className="border-2 rounded-md bg-light-blue px-3">
        <div className="flex justify-between">
          <div className="mr-2 my-3 w-full">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                id="search"
                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-100 transition duration-200 focus:bg-white outline-0"
                placeholder={searchByText}
                autoComplete="off"
                value={search}
                onChange={onChangeSearch}
              />
            </div>
          </div>
          <button
            onClick={
              newItemRoute
                ? () => navigate(newItemRoute, { replace: true })
                : () => setAddNewModal(true)
            }
            className="block text-center py-2 px-4 my-3 w-32 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-100 transition hover:bg-white focus:bg-white"
          >
            Add new
          </button>
        </div>
        <div className="overflow-hidden overflow-x-auto visible-horizontal-scrollbar mb-2.5 min-h-[450px]">
          <table className="w-full border-spacing-y-1.5 border-separate border-t border-gray-300">
            <thead>
              <tr>
                {selectableOnly && <td></td>}
                {rows.map((row, index) => (
                  <td
                    key={index}
                    className={`px-3 py-2.5 text-sm text-light-gray ${
                      row?.center ? "text-center" : null
                    }`}
                  >
                    {row.title}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {data ? (
                data.length > 0 ? (
                  data.map(el => {
                    return (
                      <tr key={el.id} className="bg-white">
                        {selectableOnly && (
                          <td>
                            <Checkbox
                              {...label}
                              onClick={event =>
                                handleSelectItem(event.target.checked, el.id)
                              }
                              checked={selected.includes(el.id)}
                              disabled={disabledItems.includes(el.id)}
                            />
                          </td>
                        )}
                        {rows.map(row => {
                          return (
                            <td
                              key={el.id + "_" + row.id}
                              className={`first:rounded-l-md last:rounded-r-md px-3 ${
                                row?.center ? "text-center" : ""
                              } ${row?.type === "image" ? "" : "p-7"}`}
                            >
                              {(() => {
                                switch (row.type) {
                                  case "text":
                                    return resolve(el, row.id)
                                  case "status":
                                    return (
                                      <label
                                        className={`bg-light-blue text-xs py-2.5 px-5 rounded-full whitespace-nowrap text-gray-500 ${
                                          row?.center ? "text-center" : null
                                        }\`}`}
                                      >
                                        {resolve(el, row.id)}
                                      </label>
                                    )
                                  case "action":
                                    return (
                                      <Link
                                        to={prepareLink(row.link, el.id)}
                                        className="text-link-color text-sm transition hover:opacity-70"
                                      >
                                        {row.text}
                                      </Link>
                                    )
                                  case "image":
                                    return (
                                      <EntityIcon
                                        size="md"
                                        src={resolve(el, row.id)}
                                        className="mx-auto"
                                      />
                                    )
                                  default:
                                    return ""
                                }
                              })()}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })
                ) : (
                  <tr className="bg-white">
                    <td
                      colSpan={rows.length}
                      className="rounded-md py-7 px-3 text-light-gray text-center"
                    >
                      <span>Nothing not found</span>
                    </td>
                  </tr>
                )
              ) : (
                <RowsLoader columnsCount={rows.length} rowsCount={perPage} />
              )}
            </tbody>
          </table>
        </div>
        <div>
          <p className="text-xs text-light-gray pb-2">
            Total records: {totalItems}
          </p>
        </div>
        <div>
          <ul className="flex justify-center mb-2.5">
            {paginationRange &&
              paginationRange.map((pageNumber, i) => {
                if (pageNumber === DOTS) {
                  return (
                    <li key={i} className="mx-1">
                      &#8230;
                    </li>
                  )
                }

                return (
                  <li
                    className={`mx-1 font-light cursor-pointer ${
                      pageNumber !== currentPage
                        ? "underline underline-offset-4"
                        : ""
                    }`}
                    key={i}
                    onClick={() => {
                      setPage(pageNumber)
                    }}
                  >
                    {pageNumber}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    )
  }
)

export default DataTable
