import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import React from "react"
import { AnimatePresence } from "framer-motion"
import OnlyGuestRoute from "./OnlyGuestRoute"
import EmptyLayout from "../layouts/EmptyLayout"
import LoginPage from "../pages/auth/LoginPage"
import CheckLoginPage from "../pages/auth/CheckLoginPage"
import ProtectedRoute from "./ProtectedRoute"
import AppLayout from "../layouts/AppLayout"
import DashboardPage from "../pages/DashboardPage"
import Error from "../pages/errors/Error"
import Error404 from "../pages/errors/Error404"
import SitesPage from "../pages/app/SitesPage"
import SponsorsPage from "../pages/app/SponsorsPage"
import UsersPage from "../pages/app/UsersPage"
import StudiesPage from "../pages/app/StudiesPage"
import SingleSitePage from "../pages/app/SingleSitePage"
import SingleSponsorPage from "../pages/app/SingleSponsorPage"
import SingleStudyPage from "../pages/app/SingleStudyPage"
import CreateStudyPage from "../pages/app/CreateStudyPage"
import SingleUserPage from "../pages/app/SingleUserPage"

const AnimatedRoutes = () => {
  const location = useLocation()

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route element={<OnlyGuestRoute />}>
          <Route element={<EmptyLayout />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/check/:id" element={<CheckLoginPage />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route element={<AppLayout />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/sites" element={<SitesPage />} />
            <Route path="/sites/:id" element={<SingleSitePage />} />
            <Route path="/sponsors" element={<SponsorsPage />} />
            <Route path="/sponsors/:id" element={<SingleSponsorPage />} />
            <Route path="/studies" element={<StudiesPage />} />
            <Route path="/studies/create" element={<CreateStudyPage />} />
            <Route path="/studies/:id" element={<SingleStudyPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/users/:id" element={<SingleUserPage />} />
          </Route>
        </Route>

        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes
