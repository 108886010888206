import { api } from "../../api"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const loadEntity = createAsyncThunk("entity/load", async params => {
  return await api().loadEntity(params.endpoint)
})

export const createEntity = createAsyncThunk("entity/create", async params => {
  return await api().createEntity(params.endpoint, params.data)
})

export const updateEntity = createAsyncThunk("entity/update", async params => {
  return await api().updateEntity(params.endpoint, params.data)
})

export const deleteEntity = createAsyncThunk("entity/delete", async params => {
  return await api().deleteEntity(params.endpoint)
})

export const archiveStudy = createAsyncThunk(
  "study/archive",
  async ({ id }) => {
    return await api().archiveStudy(id)
  }
)

export const unarchiveStudy = createAsyncThunk(
  "study/unarchive",
  async ({ id }) => {
    return await api().unarchiveStudy(id)
  }
)

export const loadClinicalTrialsStudy = createAsyncThunk(
  "study/clinicalTrials",
  async ({ nct }) => {
    return await api().loadClinicalTrialsStudy(nct)
  }
)

export const loadStudyTopicInfo = createAsyncThunk(
  "study/topicInfo",
  async ({ id }) => {
    return await api().loadStudyTopicInfo(id)
  }
)

export const updateStudyTopicInfo = createAsyncThunk(
  "study/topicInfo",
  async ({ id, value }) => {
    return await api().updateStudyTopicInfo(id, value)
  }
)

export const loadStudyCalculations = createAsyncThunk(
  "study/calculations",
  async ({ id }) => {
    return await api().loadStudyCalculations(id)
  }
)

export const updateCalculations = createAsyncThunk(
  "study/calculations",
  async ({ id, data }) => {
    return await api().updateStudyCalculations(id, data)
  }
)

export const loadStudyProcedures = createAsyncThunk(
  "study/procedures",
  async ({ id }) => {
    return await api().loadStudyProcedures(id)
  }
)

export const updateProcedures = createAsyncThunk(
  "study/procedures",
  async ({ id, data }) => {
    return await api().updateStudyProcedures(id, data)
  }
)

export const loadStudyEvaluations = createAsyncThunk(
  "study/evaluations",
  async ({ id }) => {
    return await api().loadStudyEvaluations(id)
  }
)

export const updateEvaluations = createAsyncThunk(
  "study/evaluations",
  async ({ id, data }) => {
    return await api().updateStudyEvaluations(id, data)
  }
)

export const loadStudyVisitSchedule = createAsyncThunk(
  "study/visitSchedule",
  async ({ id }) => {
    return await api().loadStudyVisitSchedule(id)
  }
)

export const updateStudyVisitSchedule = createAsyncThunk(
  "study/visitSchedule",
  async ({ id, data }) => {
    return await api().updateStudyVisitSchedule(id, data)
  }
)

export const loadStudyInstructionalVideo = createAsyncThunk(
  "study/instructionalVideo",
  async ({ id }) => {
    return await api().loadInstructionalVideo(id)
  }
)

export const updateStudyInstructionalVideo = createAsyncThunk(
  "study/instructionalVideo",
  async ({ id, data }) => {
    return await api().updateInstructionalVideo(id, data)
  }
)

export const clearStudyInstructionalVideo = createAsyncThunk(
  "study/instructionalVideo",
  async ({ id }) => {
    return await api().clearInstructionalVideo(id)
  }
)

export const loadStudySynopsis = createAsyncThunk(
  "study/synopsis",
  async ({ id }) => {
    return await api().loadStudySynopsis(id)
  }
)

export const updateStudySynopsis = createAsyncThunk(
  "study/synopsis",
  async ({ id, data }) => {
    return await api().updateStudySynopsis(id, data)
  }
)

export const clearStudySynopsis = createAsyncThunk(
  "study/synopsis",
  async ({ id }) => {
    return await api().clearStudySynopsis(id)
  }
)

export const loadStudyProtocol = createAsyncThunk(
  "study/protocol",
  async ({ id }) => {
    return await api().loadStudyProtocol(id)
  }
)

export const updateStudyProtocol = createAsyncThunk(
  "study/protocol",
  async ({ id, data }) => {
    return await api().updateStudyProtocol(id, data)
  }
)

export const clearStudyProtocol = createAsyncThunk(
  "study/protocol",
  async ({ id }) => {
    return await api().clearStudyProtocol(id)
  }
)

export const loadStudyConsentForm = createAsyncThunk(
  "study/consentForm",
  async ({ id }) => {
    return await api().loadStudyConsentForm(id)
  }
)

export const updateStudyConsentForm = createAsyncThunk(
  "study/consentForm",
  async ({ id, data }) => {
    return await api().updateStudyConsentForm(id, data)
  }
)

export const clearStudyConsentForm = createAsyncThunk(
  "study/consentForm",
  async ({ id }) => {
    return await api().clearStudyConsentForm(id)
  }
)
