import React, { useCallback, useEffect, useRef, useState } from "react"
import UsersDataTable from "../DataTables/UsersDataTable"
import EntityIcon from "../EntityIcon"
import RoleSelect from "../Selects/RoleSelect"
import { Autocomplete, TextField } from "@mui/material"
import { getFullSiteName } from "../../helpers"
import { cloneDeep, forEach, toInteger } from "lodash"
import Button from "@mui/material/Button"
import Modal from "../Modal"
import {
  loadEntities,
  loadEntitiesByIds,
} from "../../store/entities/entities.actions"
import { useDispatch } from "react-redux"
import { updateEntity } from "../../store/entity/entity.actions"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"

const InviteModal = ({ isShown, setIsShown, sites, onInvite, studyId }) => {
  const [isUsersLoading, setIsUsersLoading] = useState(false)
  const [isInviteLoading, setIsInviteLoading] = useState(false)
  const [inviteStep, setInviteStep] = useState(1)
  const [invitedUsers, setInvitedUsers] = useState({})
  const [invitedWithRoles, setInvitedWithRoles] = useState([])
  const [isSubmitActive, setIsSubmitActive] = useState(true)
  const [allSites, setAllSites] = useState(true)

  const usersDataTableRef = useRef(null)
  const dispatch = useDispatch()

  const handleNextAddUsers = () => {
    let invited = {}
    let assignedUsers = sites
      .map(item => item.users.map(user => user.id))
      .flat()

    forEach(usersDataTableRef.current.getSelectedIds(), id => {
      if (!assignedUsers.includes(id)) {
        invited[id] = {
          role_id: 2,
          site_id: null,
        }
      }
    })

    loadUsersById(Object.keys(invited))

    setInviteStep(2)
    setInvitedWithRoles(invited)
  }

  const loadUsersById = ids => {
    setIsUsersLoading(true)

    dispatch(
      loadEntitiesByIds({
        endpoint: "users/get-by-ids",
        data: ids,
      })
    )
      .unwrap()
      .then(result => {
        setIsUsersLoading(false)
        setInvitedUsers(result?.data ?? [])
      })
  }

  const handleCloseAction = () => {
    setInviteStep(1)
    setIsShown(false)
  }

  const handleInviteUsers = () => {
    setIsInviteLoading(true)

    dispatch(
      updateEntity({
        endpoint: `studies/${studyId}/invite`,
        data: { invited_users: invitedWithRoles },
      })
    )
      .unwrap()
      .then(result => {
        if (result) {
          addAlertWithTimeout(
            "Success",
            <p>Users has been invited successfully.</p>,
            AlertType.Success
          )

          onInvite()
          handleCloseAction()
        }

        setIsInviteLoading(false)
      })
      .catch(() => setIsInviteLoading(false))
  }

  const handleChangeRole = (userId, value) => {
    setInvitedWithRoles(prev => {
      let updated = cloneDeep(prev)

      updated[userId]["role_id"] = value

      return updated
    })
  }

  const handleChangeSite = (userId, value) => {
    setInvitedWithRoles(prev => {
      let updated = cloneDeep(prev)

      updated[userId]["site_id"] = value

      return updated
    })
  }

  const handleRemoveUser = userId => {
    setInvitedWithRoles(prev => {
      let updated = cloneDeep(prev)

      delete updated[userId]

      return updated
    })
  }

  const loadAllSites = useCallback(() => {
    dispatch(
      loadEntities({
        endpoint: `sites/`,
      })
    )
      .unwrap()
      .then(result => {
        setAllSites(result?.data ?? [])
      })
  }, [dispatch])

  useEffect(() => {
    if (inviteStep === 1) {
      setIsSubmitActive(true)
    } else if (
      inviteStep === 2 &&
      (Object.values(invitedWithRoles).some(item => item.site_id === null) ||
        Object.keys(invitedWithRoles).length < 1)
    ) {
      setIsSubmitActive(false)
    } else {
      setIsSubmitActive(true)
    }
  }, [inviteStep, invitedWithRoles])

  useEffect(() => {
    loadAllSites()
  }, [loadAllSites])

  return (
    <Modal
      title="Add users to the study"
      size="xxl"
      isShown={isShown}
      setIsShown={setIsShown}
      onSubmit={inviteStep === 1 ? handleNextAddUsers : handleInviteUsers}
      submitText={inviteStep === 1 ? "Next" : "Invite"}
      secondaryText={inviteStep === 1 ? "Cancel" : "Back"}
      secondaryAction={inviteStep === 1 ? null : () => setInviteStep(1)}
      repeatTopButtons={true}
      submitActionActive={isSubmitActive}
      submitActionLoading={isInviteLoading}
      onClose={handleCloseAction}
    >
      {inviteStep === 1 && (
        <UsersDataTable
          ref={usersDataTableRef}
          selectableOnly={true}
          selectedItems={sites
            .map(item => item.users.map(user => user.id))
            .flat()
            .map(Number)}
          disabledItems={sites
            .map(item => item.users.map(user => user.id))
            .flat()
            .map(Number)}
        />
      )}
      {inviteStep === 2 && (
        <>
          <div className="flex flex-col">
            <table className="w-full border-spacing-y-1.5 border-separate border-gray-300">
              <thead>
                <tr className="[&>td]:px-3 [&>td]:py-2.5 [&>td]:text-sm [&>td]:text-light-gray ">
                  <td>Image</td>
                  <td>Data</td>
                  <td>Role</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {isUsersLoading ? (
                  <tr className="bg-white">
                    <td
                      className="px-3 py-7 rounded-md text-center"
                      colSpan="4"
                    >
                      Loading...
                    </td>
                  </tr>
                ) : Object.keys(invitedWithRoles).length > 0 ? (
                  Object.keys(invitedWithRoles).map(userId => {
                    const user = invitedUsers.find(
                      obj => obj.id === toInteger(userId)
                    )

                    return (
                      <tr key={user.id} className="bg-white">
                        <td className="rounded-l-md pl-5 py-7 w-5">
                          <EntityIcon
                            size="md"
                            src={user.image_path}
                            className="mr-5"
                          />
                        </td>
                        <td className="px-3 py-7 lg:w-[350px]">
                          <p>{user.full_name}</p>
                          <p className="mt-2">{user.email}</p>
                        </td>
                        <td className="py-7">
                          <div className="flex flex-col">
                            <RoleSelect
                              id={`user-role-${user.id}`}
                              value={
                                invitedWithRoles[user.id]["role_id"] ?? null
                              }
                              onChange={event =>
                                handleChangeRole(user.id, event.target.value)
                              }
                            />

                            <Autocomplete
                              disablePortal
                              options={allSites}
                              getOptionLabel={site => getFullSiteName(site)}
                              onChange={(event, value) => {
                                handleChangeSite(user.id, value?.id ?? null)
                              }}
                              value={
                                allSites.find(
                                  site =>
                                    site.id ===
                                    invitedWithRoles[user.id]["site_id"]
                                ) ?? null
                              }
                              className="w-full form-control font-light text-gray-700 bg-white rounded focus:text-gray-700 mt-2"
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Enter or select site name"
                                />
                              )}
                            />
                          </div>
                        </td>
                        <td className="rounded-r-md px-5 py-7 w-[50px]">
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleRemoveUser(user.id)}
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center py-5">
                      <span className="text-light-gray">Empty</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </Modal>
  )
}

export default InviteModal
