import React from "react"

import { BrowserRouter } from "react-router-dom"
import AnimatedRoutes from "./AnimatedRoutes"

const Routes = () => (
  <BrowserRouter>
    <AnimatedRoutes />
  </BrowserRouter>
)

export default Routes
