import React from "react"

const Button = ({ children, onClick, className, loading = false, disabled = false }) => (
  <button
    disabled={loading || disabled}
    className={[
      "bg-white px-10 py-2.5 border rounded-lg mb-2 transition text-center",
      (loading || disabled) ? "opacity-30" : "hover:bg-gray-100",
      className,
    ].join(" ")}
    onClick={onClick}
  >
    {children}
  </button>
)

export default Button
