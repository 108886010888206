import React, { useState } from "react"
import logo from "../assets/images/logo.webp"
import burger from "../assets/images/burger.svg"
import { NavLink } from "react-router-dom"

const Sidebar = () => {
  const [isVisible, setIsVisible] = useState(false)
  const toggleSidebar = () => {
    setIsVisible(!isVisible)
  }

  return (
    <>
      <button className="md:hidden ml-3 mt-2" onClick={toggleSidebar}>
        <img src={burger} alt="" />
      </button>
      <div
        className={`
          ${isVisible ? "z-20" : "translate-x-[-192px] md:translate-x-0"}
          fixed top-0 h-screen bg-light-blue w-[192px] transition duration-500
        `}
      >
        <nav className="flex flex-col text-center">
          <NavLink to="/">
            <img className="w-16 mx-auto my-7" src={logo} alt="" />
          </NavLink>
          {[
            ["Dashboard", "/dashboard"],
            ["Sites", "/sites"],
            ["Sponsors", "/sponsors"],
            ["Users", "/users"],
            ["Studies", "/studies"],
          ].map(([title, url]) => (
            <NavLink
              className={({ isActive }) =>
                [
                  "px-14 py-3 hover:text-blue",
                  isActive ? "text-blue font-medium" : null,
                ]
                  .filter(Boolean)
                  .join(" ")
              }
              to={url}
              key={url}
            >
              {title}
            </NavLink>
          ))}
        </nav>
      </div>
      <div
        className={`
          ${isVisible ? null : "hidden"}
          md:hidden navbar-backdrop fixed inset-0 bg-gray-800 opacity-75 z-10
        `}
        onClick={toggleSidebar}
      ></div>
    </>
  )
}

export default Sidebar
