import React, { forwardRef, useImperativeHandle, useRef, useState } from "react"
import DataTable from "./DataTable"
import Modal from "../Modal"
import Input from "../Input"
import UploadAndCropImage from "../UploadAndCropImage"
import { useDispatch } from "react-redux"
import cloneDeep from "lodash/cloneDeep"
import { updateNestedProperty } from "../../helpers"
import { createEntity, updateEntity } from "../../store/entity/entity.actions"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"
import { loadEntities } from "../../store/entities/entities.actions"

const initialFormState = {
  email: "",
  firstname: "",
  lastname: "",
  phone: {
    country_code: "",
    phone: "",
  },
}

const UsersDataTable = forwardRef(
  ({ selectableOnly = false, selectedItems = [], disabledItems = [] }, ref) => {
    const endpoint = "users/search"
    const [addModal, setAddModal] = useState(false)
    const [uploadModal, setUploadModal] = useState(false)
    const [image, setImage] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [form, setForm] = useState(initialFormState)
    const [createdId, setCreatedId] = useState(null)
    const dispatch = useDispatch()
    const [step, setStep] = useState(1)
    const [isCreating, setIsCreating] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const uploadRef = useRef(null)

    let rows = [
      {
        id: "image_path",
        title: "Logo",
        type: "image",
        center: true,
      },
      {
        id: "full_name",
        title: "Name",
        type: "text",
      },
      {
        id: "email",
        title: "Email",
        type: "text",
      },
      {
        id: "action",
        title: "",
        type: "action",
        link: "/users/{param}",
        text: "View user",
        center: true,
      },
    ]

    const innerRef = useRef(null)

    useImperativeHandle(ref, () => ({
      getSelectedIds() {
        return innerRef.current.getSelectedIds()
      },
    }))

    const onInputChange = (key, value) => {
      let newForm = cloneDeep(form)

      if (key.includes(".")) {
        updateNestedProperty(newForm, key, value)
      } else {
        newForm[key] = value
      }

      setForm(newForm)
    }

    const onCreateSubmit = () => {
      setIsCreating(true)
      dispatch(createEntity({ endpoint: "users/", data: form }))
        .unwrap()
        .then(response => {
          if (response !== undefined) {
            setForm(initialFormState)

            setAddModal(false)

            if (response?.data.id) {
              addAlertWithTimeout(
                "Success",
                <>
                  <p>User has been created successfully.</p>
                  <p className="font-bold mt-2">Click here to open</p>
                </>,
                AlertType.Success,
                `users/${response.data.id}`
              )

              dispatch(loadEntities({ endpoint: "users/search" }))

              setCreatedId(response.data.id)
              setUploadModal(true)
              setStep(2)
            }
          }

          setIsCreating(false)
        })
    }

    const onCropSubmit = () => {
      uploadRef.current.cropImageAction()

      setStep(4)
    }

    const onUploadSubmit = () => {
      setIsUploading(true)

      dispatch(
        updateEntity({
          endpoint: "users/update-profile-image/" + createdId,
          data: {
            image: croppedImage,
          },
        })
      )
        .unwrap()
        .then(response => {
          setIsUploading(false)

          if (response !== undefined) {
            addAlertWithTimeout(
              "Success",
              <>
                <p>User's profile image has been uploaded successfully.</p>
              </>,
              AlertType.Success
            )

            dispatch(loadEntities({ endpoint: "users/search" }))

            closeUploadModal()
          }
        })
    }

    const backAction = () => {
      setImage(null)
      setStep(2)
    }

    const closeUploadModal = () => {
      setUploadModal(false)
      setCreatedId(null)
      setImage(null)
      setCroppedImage(null)
      setStep(1)
    }

    return (
      <>
        <DataTable
          ref={innerRef}
          rows={rows}
          endpoint={endpoint}
          selectableOnly={selectableOnly}
          selectedItems={selectedItems}
          setAddNewModal={setAddModal}
          disabledItems={disabledItems}
          searchByText="Search by name or email"
        />

        <Modal
          title="Add new User"
          size="xl"
          isShown={addModal}
          setIsShown={setAddModal}
          onSubmit={onCreateSubmit}
          onClose={() => setForm(initialFormState)}
          submitActionLoading={isCreating}
        >
          <div className="mb-3">
            First name:
            <Input
              id="firstname"
              onChange={e => onInputChange("firstname", e.target.value)}
              value={form.firstname}
              placeholder="First name"
            />
          </div>
          <div className="mb-3">
            Last name:
            <Input
              id="lastname"
              onChange={e => onInputChange("lastname", e.target.value)}
              value={form.lastname}
              placeholder="Last name"
            />
          </div>
          <div className="mb-3">
            Email:
            <Input
              id="email"
              onChange={e => onInputChange("email", e.target.value)}
              value={form.email}
              placeholder="Email"
            />
          </div>
          <div className="mb-3">
            Phone number:
            <div className="flex">
              <Input
                id="country_code"
                className="!w-20 text-center mr-2"
                onChange={e =>
                  onInputChange("phone.country_code", e.target.value)
                }
                value={form.phone.country_code}
                placeholder="+1"
              />
              <Input
                id="phone"
                onChange={e => onInputChange("phone.phone", e.target.value)}
                value={form.phone.phone}
                placeholder="(999) 999 9999"
              />
            </div>
          </div>
        </Modal>
        <Modal
          title="Upload image"
          size="lg"
          isShown={uploadModal}
          setIsShown={setUploadModal}
          onSubmit={step === 3 ? onCropSubmit : onUploadSubmit}
          submitActionActive={
            (step === 3 && image) ||
            (step === 4 && croppedImage && !isUploading)
          }
          submitActionLoading={isUploading}
          onClose={() => setCreatedId(null)}
          leftButtonText="Skip"
          leftButtonAction={closeUploadModal}
          secondaryText="Back"
          secondaryAction={backAction}
          secondaryActionActive={image}
        >
          <UploadAndCropImage
            ref={uploadRef}
            image={image}
            setImage={setImage}
            croppedImage={croppedImage}
            setCroppedImage={setCroppedImage}
            initialStep={2}
            currentStep={step}
            setStep={setStep}
            handlerCancel={backAction}
            type="jpeg"
            cropShape="round"
          />
        </Modal>
      </>
    )
  }
)

export default UsersDataTable
