import React, { useState } from "react"
import downArrow from "../assets/images/down.svg"
import { useDispatch, useSelector } from "react-redux"
import { selectAuthData } from "../store/auth/auth.selector"
import { NavLink } from "react-router-dom"
import useClickOutside from "../hooks/useClickOutside"
import { logout } from "../store/auth/auth.actions"
import EntityIcon from "./EntityIcon"

const UserPopup = () => {
  const dispatch = useDispatch()
  const linkStyle =
    "bg-white hover:bg-light-blue py-2 px-4 text-left w-40 first:pt-3 last:pb-3"
  const [visible, setVisible] = useState(false)

  const { ref } = useClickOutside(() => setVisible(false))

  const { user } = useSelector(selectAuthData)

  const toggle = () => {
    setVisible(!visible)
  }

  return (
    <div className="fixed top-3.5 right-3.5 flex flex-col z-10" ref={ref}>
      <div className="flex justify-end">
        <button className="flex items-center" onClick={toggle}>
          <img
            src={downArrow}
            className={`${visible ? "rotate-180 " : ""}w-5 mx-2.5 transition`}
            alt={user?.full_name}
          />
          {user && <EntityIcon src={user?.image_path} alt={user?.full_name} />}
        </button>
      </div>
      <div
        className={`
          ${visible ? "fadeIn" : "fadeOut"}
          relative top-0 z-20 transition duration-500 flex flex-col shadow-md 
          border shadow-gray-400 rounded-3xl mt-3 overflow-hidden transition
        `}
      >
        {[
          ["Admins", "/admins"],
          ["Profile", "/profile"],
        ].map(([title, url]) => (
          <NavLink
            className={({ isActive }) =>
              `${isActive ? "bg-light-blue" : null} ${linkStyle}`
            }
            to={url}
            key={url}
          >
            {title}
          </NavLink>
        ))}
        <button
          onClick={() => {
            dispatch(logout())
          }}
          className={linkStyle}
        >
          Log Out
        </button>
      </div>
    </div>
  )
}

export default UserPopup
