import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  alerts: [],
}

export const alertsReducer = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    appendAlert(state, action) {
      state.alerts = [action.payload, ...state.alerts]
    },
    removeAlert(state, action) {
      state.alerts = state.alerts.filter(({ id }) => action.payload !== id)
    },
  },
})

export const { appendAlert, removeAlert } = alertsReducer.actions
export default alertsReducer.reducer
