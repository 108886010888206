import React, { useCallback, useEffect, useState } from "react"
import ContentLoader from "react-content-loader"
import Input from "../../Input"
import {
  clearStudyProtocol,
  loadStudyProtocol,
  updateStudyProtocol,
} from "../../../store/entity/entity.actions"
import { useDispatch } from "react-redux"
import Button from "../../Button"
import Modal from "../../Modal"
import Dropzone from "../../DropZone"
import { bytesToMB } from "../../../helpers"
import { addAlertWithTimeout, AlertType } from "../../../utils/alertUtils"
const StudyProtocol = ({ studyId }) => {
  const dispatch = useDispatch()
  const [editProtocol, setEditProtocol] = useState(false)
  const [protocol, setProtocol] = useState(null)
  const [newProtocolPath, setNewProtocolPath] = useState("")
  const [protocolLoading, setProtocolLoading] = useState(false)
  const [selectProtocolUrl, setSelectProtocolUrl] = useState(false)
  const [protocolSaving, setProtocolSaving] = useState(false)
  const [protocolFileModal, setProtocolFileModal] = useState(false)
  const [protocolFile, setProtocolFile] = useState(null)
  const [protocolEdited, setProtocolEdited] = useState(false)
  const [confirmClearProtocolModal, setConfirmClearProtocolModal] =
    useState(false)

  const loadProtocol = useCallback(() => {
    setProtocolLoading(true)

    dispatch(loadStudyProtocol({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          if (!r.data?.is_file) {
            setNewProtocolPath(r.data?.path ?? "")
          }
          setProtocol(r.data)
        }

        setProtocolLoading(false)
      })
  }, [dispatch, studyId])

  const saveProtocol = async () => {
    let formData = new FormData()

    setProtocolSaving(true)

    if (protocolFile) {
      formData.append("file", protocolFile)
      formData.append("is_file", "true")
    } else {
      formData.append("path", newProtocolPath)
      formData.append("is_file", "false")
    }

    dispatch(updateStudyProtocol({ id: studyId, data: formData }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          setProtocol(r.data)

          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Study Protocol has been updated successfully.</p>
            </>,
            AlertType.Success
          )
        }

        setProtocolSaving(false)
        setEditProtocol(false)
      })
  }

  const clearProtocol = () => {
    setProtocolSaving(true)

    dispatch(clearStudyProtocol({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Study Protocol has been removed successfully.</p>
            </>,
            AlertType.Warning
          )

          setProtocol(null)
          setConfirmClearProtocolModal(false)
        }

        setProtocolSaving(false)
        setEditProtocol(false)
      })
  }

  useEffect(() => {
    loadProtocol()
  }, [loadProtocol])

  return (
    <>
      <div className="bg-white rounded-lg px-5 border mt-2.5 p-5">
        <div className="flex flex-row justify-between items-center">
          <h4 className="font-bold text-xl">Study Protocol</h4>
          <div>
            {editProtocol && (
              <Button
                className={`px-8 py-1.5 mb-0 mr-2 text-green-700 ${
                  !protocolEdited || protocolSaving ? "opacity-40" : ""
                }`}
                onClick={saveProtocol}
                disabled={!protocolEdited || protocolSaving}
              >
                {protocolSaving ? "Saving..." : "Save"}
              </Button>
            )}
            <Button
              className="px-3 py-1.5 mb-0"
              onClick={() => {
                setSelectProtocolUrl(false)
                setProtocolFile(null)
                setEditProtocol(prev => !prev)
              }}
            >
              {editProtocol ? (
                "Cancel"
              ) : (
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87M0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z"
                    fill="black"
                  />
                </svg>
              )}
            </Button>
          </div>
        </div>
        <hr />
        <div className="mt-5 py-3 px-5 rounded-lg border flex flex-row items-center my-2">
          <span className="w-1/2 inline-block font-medium">Study protocol</span>
          <div className="w-1/2">
            {protocolLoading ? (
              <ContentLoader
                speed={2}
                width="100%"
                height={24}
                backgroundColor="#c2c2c2"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
              </ContentLoader>
            ) : editProtocol ? (
              selectProtocolUrl ? (
                <div className="flex items-center">
                  <Input
                    value={newProtocolPath}
                    onChange={e => {
                      setNewProtocolPath(e.target.value)
                      setProtocolEdited(true)
                    }}
                    placeholder="Enter URL..."
                  />
                  <button
                    className="pl-2.5 hover:opacity-70 transition h-full text-red-500"
                    onClick={() => {
                      setProtocolEdited(false)
                      setSelectProtocolUrl(false)
                      setNewProtocolPath("")
                    }}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="w-fit mx-auto border border-dashed rounded-xl flex flex-row text-dark-gray font-light">
                  {protocolFile ? (
                    <>
                      <button
                        className="pl-4 pr-2.5 py-4 hover:opacity-70 transition h-full flex flex-row justify-around"
                        onClick={() => setProtocolFileModal(true)}
                      >
                        <span className="max-w-[200px] truncate mr-1.5">
                          {protocolFile.name}
                        </span>
                        <span className="font-medium">
                          {bytesToMB(protocolFile.size)} MB
                        </span>
                      </button>
                      <button
                        className="pr-4 pl-2.5 py-4 hover:opacity-70 transition h-full text-red-500"
                        onClick={() => {
                          setProtocolFile(null)
                          setProtocolEdited(false)
                        }}
                      >
                        Clear
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="pl-4 pr-2.5 py-4 hover:opacity-70 transition h-full"
                        onClick={() => setProtocolFileModal(true)}
                      >
                        {protocol && protocol.is_file
                          ? "Upload new file"
                          : "Select file"}
                      </button>
                      <button
                        className="px-2.5 py-4 hover:opacity-70 transition h-full"
                        onClick={() => setSelectProtocolUrl(true)}
                      >
                        {protocol && !protocol.is_file
                          ? "Edit URL"
                          : "Select URL"}
                      </button>
                      {protocol && (
                        <button
                          className="pr-4 pl-2.5 py-4 hover:opacity-70 transition h-full text-red-500"
                          onClick={() => setConfirmClearProtocolModal(true)}
                        >
                          Clear all
                        </button>
                      )}
                    </>
                  )}
                </div>
              )
            ) : protocol ? (
              protocol.is_file ? (
                <a
                  href={protocol.link}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full block border border-dashed border-light-gray text-light-gray rounded-lg font-light px-3 truncate hover:opacity-80 transition"
                >
                  {protocol.path}
                </a>
              ) : (
                <a
                  href={protocol.link}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full block text-blue hover:opacity-70 transition inline-block truncate"
                >
                  {protocol.path}
                </a>
              )
            ) : (
              <div className="text-light-gray text-center">
                No file uploaded
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        title="Upload file"
        size="lg"
        isShown={protocolFileModal}
        setIsShown={setProtocolFileModal}
        submitActionActive={protocolFile}
        noButtons={true}
      >
        <Dropzone
          setFile={setProtocolFile}
          onDropAction={() => {
            setProtocolFileModal(false)
            setProtocolEdited(true)
          }}
        />
      </Modal>

      <Modal
        title="Clear Protocol"
        size="md"
        isShown={confirmClearProtocolModal}
        setIsShown={setConfirmClearProtocolModal}
        onSubmit={clearProtocol}
        submitText="Yes"
      >
        <p>Are you sure you want to clear Study Protocol?</p>
      </Modal>
    </>
  )
}

export default StudyProtocol
