import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Pagination from "../../components/Pagination"
import { setIsLoading } from "../../store/entity/entity.reducer"
import {
  isEmpty,
  prepareBeforeUpdate,
  updateNestedProperty,
} from "../../helpers"
import cloneDeep from "lodash/cloneDeep"

import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Button from "@mui/material/Button"
import { remove } from "lodash"
import { Autocomplete, TextField, useMediaQuery } from "@mui/material"
import Input from "../../components/Input"
import {
  createEntity,
  loadClinicalTrialsStudy,
} from "../../store/entity/entity.actions"
import { selectEntityData } from "../../store/entity/entity.selector"
import { loadEntities } from "../../store/entities/entities.actions"
import CriteriaList from "../../components/StudySections/CriteriaList"
import Details from "../../components/StudySections/Details"
import { useNavigate } from "react-router-dom"
import { clearEntities } from "../../store/entities/entities.reducer"
import InviteUsers from "../../components/StudySections/InviteUsers"

const CreateStudyPage = () => {
  const steps = [
    { label: "NCT number", optional: true, loadFromNCT: false },
    { label: "General information", optional: false, loadFromNCT: true },
    { label: "Incl/Excl criteria", optional: false, loadFromNCT: true },
    { label: "Study details", optional: true, loadFromNCT: false },
    { label: "Invite users", optional: true, loadFromNCT: false },
    { label: "Suppl info", optional: true, loadFromNCT: false },
  ]

  const [activeStep, setActiveStep] = useState(0)
  const [lastStep, setLastStep] = useState(0)
  const [skipped, setSkipped] = useState([])
  const [nctNumber, setNctNumber] = useState("")
  const [topics, setTopics] = useState([])
  const [sponsors, setSponsors] = useState([])
  const [rolesErrorModal, setRolesErrorModal] = useState(false)
  const [sitesErrorModal, setSitesErrorModal] = useState(false)

  const [study, setStudy] = useState({
    criteria: {
      inclusion: [{ show: true, value: "" }],
      exclusion: [{ show: true, value: "" }],
    },
  })

  const { isLoading, data } = useSelector(selectEntityData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 600px)")

  const save = () => {
    const validated = prepareBeforeUpdate(study)

    dispatch(setIsLoading(true))

    dispatch(createEntity({ endpoint: "studies", data: validated }))
      .unwrap()
      .then(r => {
        if (r !== undefined && r?.data?.id) {
          return navigate("/studies/" + r.data.id, { replace: true })
        }
      })
  }

  const changeField = (key, value) => {
    if (key.includes(".")) {
      let item = cloneDeep(study)

      updateNestedProperty(item, key, value)

      setStudy(item)
    } else {
      let item = { ...study }

      item[key] = value

      setStudy(item)
    }
  }

  const getClinicalTrialsStudyData = async () => {
    return await dispatch(loadClinicalTrialsStudy({ nct: nctNumber }))
      .unwrap()
      .then(result => {
        return result?.data
      })
  }

  const isStepOptional = index => {
    return steps[index]["optional"]
  }

  const isStepSkipped = step => {
    return skipped.includes(step)
  }

  const isNextActive = () => {
    switch (activeStep) {
      case 0:
        return !isEmpty(nctNumber)
      case 1:
        return !isEmpty(study.name) && !isEmpty(study.sponsor_id)
      default:
        return true
    }
  }

  const handleNext = async () => {
    let allowed = false

    switch (activeStep) {
      case 0:
        await getClinicalTrialsStudyData().then(r => {
          if (r) {
            allowed = true
          }
        })
        break
      case 4:
        // const roles = Object.values(study["invited_users"]).map(
        //   item => item.role_id
        // )
        const sites = Object.values(study["invited_users"]).map(
          item => item.site_id
        )

        //TODO: change it per site

        // if (!roles.includes(1) || !roles.includes(3)) {
        //   setRolesErrorModal(true)
        // } else

        if (sites.some(value => value === null)) {
          setSitesErrorModal(true)
        } else {
          allowed = true
        }
        break
      default:
        allowed = true
        break
    }

    if (allowed && isNextActive()) {
      let newSkipped = skipped

      if (isStepSkipped(activeStep)) {
        remove(newSkipped, item => item === activeStep)
      }

      const nextStep = activeStep + 1

      setActiveStep(nextStep)
      setSkipped(newSkipped)

      if (nextStep > lastStep) {
        setLastStep(nextStep)
      }
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.")
    }

    const nextStep = activeStep + 1

    setActiveStep(nextStep)

    if (nextStep > lastStep) {
      setLastStep(nextStep)
    }

    setSkipped(prevSkipped => {
      const newSkipped = prevSkipped

      if (!newSkipped.includes(activeStep)) {
        newSkipped.push(activeStep)
      }

      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
    setLastStep(0)
  }

  const transformCriteriaFromClinicalTrials = criteria => {
    return {
      inclusion: criteria?.inclusion.map(item => ({ show: true, value: item })),
      exclusion: criteria?.exclusion.map(item => ({ show: true, value: item })),
    }
  }

  const setClinicalTrialsStudyData = () => {
    switch (activeStep) {
      case 1:
        setStudy(study => ({
          ...study,
          name: data.name ?? study.name,
          topic_name: data.topic ?? study.topic_name,
        }))
        break
      case 2:
        if (data.criteria) {
          setStudy(study => ({
            ...study,
            criteria: transformCriteriaFromClinicalTrials(data.criteria),
          }))
        }
        break
      default:
        break
    }
  }

  const changeCriteria = (criteria, key, value, type) => {
    const updatedCriteria = [...criteria[type]]
    const updatedStudy = cloneDeep(study)

    updatedCriteria[key] = { ...updatedCriteria[key], value }

    updatedStudy["criteria"][type] = updatedCriteria

    setStudy(updatedStudy)
  }

  const removeCriteria = (index, type) => {
    let updatedStudy = cloneDeep(study)

    updatedStudy.criteria[type].splice(index, 1)

    setStudy(updatedStudy)
  }

  const handleDropCriteria = (droppedItem, list) => {
    if (!droppedItem.destination) {
      return
    }
    let updatedStudy = cloneDeep(study)
    let updatedList = cloneDeep(study["criteria"][list])

    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)

    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)

    updatedStudy["criteria"][list] = updatedList

    setStudy(updatedStudy)
  }

  useEffect(() => {
    dispatch(loadEntities({ endpoint: "topics" }))
      .unwrap()
      .then(result => {
        setTopics(result?.data ?? [])
      })
    dispatch(loadEntities({ endpoint: "sponsors" }))
      .unwrap()
      .then(result => {
        setSponsors(result?.data ?? [])
      })

    return () => dispatch(clearEntities())
  }, [dispatch])

  useEffect(() => {
    if (
      !isEmpty(
        study?.criteria?.inclusion[study.criteria.inclusion.length - 1].value
      )
    ) {
      let updatedStudy = cloneDeep(study)

      updatedStudy.criteria.inclusion.push({ show: true, value: "" })

      setStudy(updatedStudy)
    }

    if (
      !isEmpty(
        study?.criteria?.exclusion[study.criteria.exclusion.length - 1].value
      )
    ) {
      let updatedStudy = cloneDeep(study)

      updatedStudy.criteria.exclusion.push({ show: true, value: "" })

      setStudy(updatedStudy)
    }
  }, [setStudy, study])

  useEffect(() => {
    changeField("clinical_trials_id", nctNumber)
  }, [nctNumber]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Pagination
        data={[
          { title: "Studies", link: "/studies" },
          {
            title: `Create Study`,
            link: `/studies/create`,
          },
        ]}
      />

      <div className="flex flex-col w-full bg-light-blue rounded-md border-2 mt-5 py-8 px-4 sm:px-8 max-w-[100%] xl:max-w-[85%]">
        <Stepper
          activeStep={lastStep}
          alternativeLabel={!isMobile}
          orientation={isMobile ? "vertical" : "horizontal"}
        >
          {steps.map((step, index) => {
            const stepProps = {}
            const labelProps = {}
            if (isStepOptional(index)) {
              labelProps.optional = (
                <span className="text-gray-500 text-xs">Optional</span>
              )
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step
                active={lastStep === index}
                key={index}
                {...stepProps}
                onClick={() => {
                  if (index <= lastStep) {
                    setActiveStep(index)
                  }
                }}
                className={`${
                  index <= lastStep
                    ? "cursor-pointer hover:opacity-80 transition "
                    : ""
                }`}
              >
                <StepLabel {...labelProps}>
                  <span
                    className={`${activeStep === index ? "font-bold" : ""}`}
                  >
                    {step.label}
                  </span>
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <p>completed</p>
            <div className="flex flex-row pt-2">
              <div className="flex-auto" />
              <Button onClick={handleReset}>Reset</Button>
            </div>
          </>
        ) : (
          <>
            <div className="mt-10 mb-5">
              {nctNumber &&
                activeStep !== 0 &&
                steps[activeStep]["loadFromNCT"] && (
                  <Button
                    className="!mb-5"
                    onClick={setClinicalTrialsStudyData}
                  >
                    Load from clinical trials
                  </Button>
                )}

              {activeStep === 0 && (
                <div className="flex flex-col mb-4">
                  <span className="text-sm text-light-gray">NCT number</span>
                  <Input
                    placeholder="Enter NCT number"
                    className="max-w-md w-full h-14 px-3 font-normal"
                    onChange={event => setNctNumber(event.target.value)}
                    value={nctNumber}
                  />
                </div>
              )}
              {activeStep === 1 && (
                <>
                  <div className="flex flex-col mb-4">
                    <span className="text-sm text-light-gray">Sponsor</span>
                    <Autocomplete
                      disablePortal
                      options={sponsors}
                      getOptionLabel={sponsor => sponsor.name}
                      onChange={(event, sponsor) => {
                        changeField("sponsor_id", sponsor.id)
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      className="w-full form-control font-light text-gray-700 bg-white rounded focus:text-gray-700"
                      renderInput={params => (
                        <TextField {...params} placeholder="Select sponsor" />
                      )}
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <span className="text-sm text-light-gray">Topic</span>
                    <Autocomplete
                      disablePortal
                      freeSolo
                      options={topics}
                      getOptionLabel={topic => topic.name}
                      className="w-full form-control font-light text-gray-700 bg-white rounded focus:text-gray-700"
                      inputValue={study.topic_name ?? ""}
                      onInputChange={(event, value) => {
                        changeField("topic_name", value)
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Enter or select topic"
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <span className="text-sm text-light-gray">Study name</span>
                    <Input
                      placeholder="Enter study name"
                      className="w-full h-14 px-3 font-normal"
                      value={study.name ?? ""}
                      onChange={event =>
                        changeField("name", event.target.value)
                      }
                    />
                  </div>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <h4 className="font-bold">Inclusion criteria</h4>
                  <CriteriaList
                    data={null}
                    study={study}
                    edit={true}
                    isLoading={isLoading}
                    handleDropCriteria={handleDropCriteria}
                    changeCriteria={changeCriteria}
                    removeCriteria={removeCriteria}
                    type="inclusion"
                  />

                  <h4 className="font-bold mt-5">Exclusion criteria</h4>
                  <CriteriaList
                    data={null}
                    study={study}
                    edit={true}
                    isLoading={isLoading}
                    handleDropCriteria={handleDropCriteria}
                    changeCriteria={changeCriteria}
                    removeCriteria={removeCriteria}
                    type="exclusion"
                  />
                </>
              )}
              {activeStep === 3 && (
                <>
                  <Details
                    isLoading={isLoading}
                    data={null}
                    study={study}
                    setStudy={setStudy}
                    updateStudy={() => {}}
                    save={() => {}}
                    editingOnly={true}
                  />
                </>
              )}
              {activeStep === 4 && (
                <InviteUsers
                  study={study}
                  setStudy={setStudy}
                  rolesErrorModal={rolesErrorModal}
                  setRolesErrorModal={setRolesErrorModal}
                  sitesErrorModal={sitesErrorModal}
                  setSitesErrorModal={setSitesErrorModal}
                />
              )}
              {activeStep === 5 && <h4>In development...</h4>}
            </div>
            <div className="flex flex-row">
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <div className="flex-auto" />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}

              <Button
                onClick={activeStep === steps.length - 1 ? save : handleNext}
                disabled={!isNextActive()}
              >
                {activeStep === steps.length - 1 ? "Save" : "Next"}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default CreateStudyPage
