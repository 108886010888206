import { api } from "../../api"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const createLoginRequest = createAsyncThunk(
  "auth/create-request",
  async params => {
    const response = await api().loginRequest(params.email)

    return response.data.id
  }
)

export const checkLoginRequest = createAsyncThunk(
  "auth/check-request",
  async params => {
    return await api().checkLoginRequest(params.id)
  }
)

export const authMe = createAsyncThunk("auth/me", async () => {
  const response = await api().me()

  return response.data
})

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  await api().logout()

  return ""
})
