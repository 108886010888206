import Button from "../Button"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  loadStudyCalculations,
  loadStudyEvaluations,
  loadStudyProcedures,
  loadStudyTopicInfo,
  loadStudyVisitSchedule,
  updateCalculations,
  updateEvaluations,
  updateProcedures,
  updateStudyTopicInfo,
  updateStudyVisitSchedule,
} from "../../store/entity/entity.actions"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { ContentState, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"
import Input from "../Input"
import { cloneDeep } from "lodash"
import { isEmpty } from "../../helpers"
import ContentLoader from "react-content-loader"
import SiteSelect from "../Selects/SiteSelect"
import { loadEntities } from "../../store/entities/entities.actions"
import StudySynopsis from "./SupplInfoItems/StudySynopsis"
import StudyProtocol from "./SupplInfoItems/StudyProtocol"
import ConsentForm from "./SupplInfoItems/ConsentForm"
import InstructionalVideo from "./SupplInfoItems/InstructionalVideo"

const SupplInfo = ({ studyId }) => {
  const [topicInfo, setTopicInfo] = useState("")
  const [editTopic, setEditTopic] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [isHidden, setIsHidden] = useState(true)
  const [topicInfoLoading, setTopicInfoLoading] = useState(false)
  const [topicInfoSaving, setTopicInfoSaving] = useState(false)

  const [calculations, setCalculations] = useState([])
  const [editCalculations, setEditCalculations] = useState(false)
  const [newCalculations, setNewCalculations] = useState([])
  const [calculationsLoading, setCalculationsLoading] = useState(false)
  const [calculationsSaving, setCalculationsSaving] = useState(false)

  const [procedures, setProcedures] = useState([])
  const [editProcedures, setEditProcedures] = useState(false)
  const [newProcedures, setNewProcedures] = useState([])
  const [proceduresLoading, setProceduresLoading] = useState(false)
  const [proceduresSaving, setProceduresSaving] = useState(false)

  const [evaluations, setEvaluations] = useState([])
  const [editEvaluations, setEditEvaluations] = useState(false)
  const [newEvaluations, setNewEvaluations] = useState([])
  const [evaluationsLoading, setEvaluationsLoading] = useState(false)
  const [evaluationsSaving, setEvaluationsSaving] = useState(false)

  const [visitSchedule, setVisitSchedule] = useState([])
  const [editVisitSchedule, setEditVisitSchedule] = useState(false)
  const [newVisitSchedule, setNewVisitSchedule] = useState([])
  const [visitScheduleLoading, setVisitScheduleLoading] = useState(false)
  const [visitScheduleSaving, setVisitScheduleSaving] = useState(false)

  const [sites, setSites] = useState([])
  const dispatch = useDispatch()

  const loadTopicInfo = useCallback(() => {
    setTopicInfoLoading(true)

    dispatch(loadStudyTopicInfo({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          setTopicInfo('<p>' + r.data + '</p>')

          const contentBlock = htmlToDraft('<p>' + r.data + '</p>')

          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            )

            const newEditorState = EditorState.createWithContent(contentState)

            setEditorState(newEditorState)
          }
        }

        setTopicInfoLoading(false)
      })
  }, [dispatch, studyId])

  const saveTopicInfo = () => {
    const contentState = editorState.getCurrentContent()
    const html = draftToHtml(convertToRaw(contentState))

    setTopicInfoSaving(true)
    setTopicInfo(html)

    dispatch(
      updateStudyTopicInfo({
        id: studyId,
        value: html,
      })
    )
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Topic Info has been updated successfully.</p>
            </>,
            AlertType.Success
          )

          loadTopicInfo()
          setEditTopic(false)
        }

        setTopicInfoSaving(false)
      })
  }

  const loadSites = useCallback(() => {
    dispatch(loadEntities({ endpoint: "sites" }))
      .unwrap()
      .then(r => {
        setSites(r?.data ?? [])
      })
  }, [dispatch])

  const loadCalculations = useCallback(() => {
    setCalculationsLoading(true)

    dispatch(loadStudyCalculations({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          setCalculations(r.data)
        }

        setCalculationsLoading(false)
      })
  }, [dispatch, studyId])

  const loadProcedures = useCallback(() => {
    setProceduresLoading(true)

    dispatch(loadStudyProcedures({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          setProcedures(r.data)
        }

        setProceduresLoading(false)
      })
  }, [dispatch, studyId])

  const loadEvaluations = useCallback(() => {
    setEvaluationsLoading(true)

    dispatch(loadStudyEvaluations({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          setEvaluations(r.data)
        }

        setEvaluationsLoading(false)
      })
  }, [dispatch, studyId])

  const loadVisitSchedule = useCallback(() => {
    setVisitScheduleLoading(true)

    dispatch(loadStudyVisitSchedule({ id: studyId }))
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          setVisitSchedule(r.data)
        }

        setVisitScheduleLoading(false)
      })
  }, [dispatch, studyId])

  const handleEditCalculations = () => {
    if (!editCalculations) {
      setNewCalculations(calculations.map(({ id, ...rest }) => rest))
    }

    setEditCalculations(prev => !prev)
  }

  const handleEditProcedures = () => {
    if (!editProcedures) {
      setNewProcedures(procedures.map(({ id, ...rest }) => rest))
    }

    setEditProcedures(prev => !prev)
  }

  const handleEditEvaluations = () => {
    if (!editEvaluations) {
      setNewEvaluations(evaluations.map(({ id, ...rest }) => rest))
    }

    setEditEvaluations(prev => !prev)
  }

  const handleEditVisitSchedule = () => {
    if (!editVisitSchedule) {
      setNewVisitSchedule(visitSchedule.map(({ id, ...rest }) => rest))
    }

    setEditVisitSchedule(prev => !prev)
  }

  const onCalculationChange = (value, field, index) => {
    let updatedData = cloneDeep(newCalculations)

    updatedData[index][field] = value

    setNewCalculations(updatedData)
  }

  const onProcedureChange = (value, field, index) => {
    let updatedData = cloneDeep(newProcedures)

    updatedData[index][field] = value

    setNewProcedures(updatedData)
  }

  const onEvaluationChange = (value, field, index) => {
    let updatedData = cloneDeep(newEvaluations)

    updatedData[index][field] = value

    setNewEvaluations(updatedData)
  }

  const onVisitScheduleChange = (value, field, index) => {
    let updatedData = cloneDeep(newVisitSchedule)

    updatedData[index][field] = value

    setNewVisitSchedule(updatedData)
  }

  const saveCalculations = () => {
    let updatedData = cloneDeep(newCalculations)

    setCalculationsSaving(true)

    updatedData = updatedData.filter(
      item => !isEmpty(item.name) || !isEmpty(item.path)
    )

    dispatch(
      updateCalculations({
        id: studyId,
        data: updatedData,
      })
    )
      .unwrap()
      .then(r => {
        if (r.data !== undefined) {
          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Calculations have been updated successfully.</p>
            </>,
            AlertType.Success
          )

          loadCalculations()
          setEditCalculations(false)
        }

        setCalculationsSaving(false)
      })
      .catch(() => {
        setCalculationsSaving(false)
      })
  }

  const saveProcedures = () => {
    let updatedData = cloneDeep(newProcedures)

    setProceduresSaving(true)

    updatedData = updatedData.filter(
      item => !isEmpty(item.name) || !isEmpty(item.path)
    )

    updatedData = updatedData.map(site => ({
      ...site,
      site_id: site.site_id === -1 ? null : site.site_id,
    }))

    dispatch(
      updateProcedures({
        id: studyId,
        data: updatedData,
      })
    )
      .unwrap()
      .then(r => {
        if (r.data !== undefined) {
          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Procedures have been updated successfully.</p>
            </>,
            AlertType.Success
          )

          loadProcedures()
          setEditProcedures(false)
        }

        setProceduresSaving(false)
      })
      .catch(() => {
        setProceduresSaving(false)
      })
  }

  const saveEvaluations = () => {
    let updatedData = cloneDeep(newEvaluations)

    setEvaluationsSaving(true)

    updatedData = updatedData.filter(
      item => !isEmpty(item.name) || !isEmpty(item.path)
    )

    dispatch(
      updateEvaluations({
        id: studyId,
        data: updatedData,
      })
    )
      .unwrap()
      .then(r => {
        if (r.data !== undefined) {
          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Evaluations have been updated successfully.</p>
            </>,
            AlertType.Success
          )

          loadEvaluations()
          setEditEvaluations(false)
        }

        setEvaluationsSaving(false)
      })
      .catch(() => {
        setEvaluationsSaving(false)
      })
  }

  const saveVisitSchedule = () => {
    let updatedData = cloneDeep(newVisitSchedule)

    setVisitScheduleSaving(true)

    updatedData = updatedData.filter(
      item => !isEmpty(item.name) || !isEmpty(item.path)
    )

    dispatch(
      updateStudyVisitSchedule({
        id: studyId,
        data: updatedData,
      })
    )
      .unwrap()
      .then(r => {
        if (r.data !== undefined) {
          addAlertWithTimeout(
            "Study successfully updated",
            <>
              <p>Visit Schedule has been updated successfully.</p>
            </>,
            AlertType.Success
          )

          loadVisitSchedule()
          setEditVisitSchedule(false)
        }

        setVisitScheduleSaving(false)
      })
      .catch(() => {
        setVisitScheduleSaving(false)
      })
  }

  const handleRemoveCalculation = index => {
    let updatedData = cloneDeep(newCalculations)

    updatedData.splice(index, 1)

    setNewCalculations(updatedData)
  }

  const handleRemoveProcedure = index => {
    let updatedData = cloneDeep(newProcedures)

    updatedData.splice(index, 1)

    setNewProcedures(updatedData)
  }

  const handleRemoveEvaluation = index => {
    let updatedData = cloneDeep(newEvaluations)

    updatedData.splice(index, 1)

    setNewEvaluations(updatedData)
  }

  const handleRemoveVisitSchedule = index => {
    let updatedData = cloneDeep(newVisitSchedule)

    updatedData.splice(index, 1)

    setNewVisitSchedule(updatedData)
  }

  useEffect(() => {
    if (editCalculations) {
      if (
        !isEmpty(newCalculations[newCalculations.length - 1]?.name) ||
        !isEmpty(newCalculations[newCalculations.length - 1]?.path) ||
        newCalculations.length === 0
      ) {
        let updatedData = cloneDeep(newCalculations)

        updatedData.push({ name: "", path: "" })

        setNewCalculations(updatedData)
      }
    }

    if (editProcedures) {
      if (
        !isEmpty(newProcedures[newProcedures.length - 1]?.name) ||
        !isEmpty(newProcedures[newProcedures.length - 1]?.path) ||
        newProcedures.length === 0
      ) {
        let updatedData = cloneDeep(newProcedures)

        updatedData.push({ name: "", path: "", site_id: null })

        setNewProcedures(updatedData)
      }
    }

    if (editEvaluations) {
      if (
        !isEmpty(newEvaluations[newEvaluations.length - 1]?.name) ||
        !isEmpty(newEvaluations[newEvaluations.length - 1]?.path) ||
        newEvaluations.length === 0
      ) {
        let updatedData = cloneDeep(newEvaluations)

        updatedData.push({ name: "", path: "" })

        setNewEvaluations(updatedData)
      }
    }

    if (editVisitSchedule) {
      if (
        !isEmpty(newVisitSchedule[newVisitSchedule.length - 1]?.name) ||
        !isEmpty(newVisitSchedule[newVisitSchedule.length - 1]?.path) ||
        newVisitSchedule.length === 0
      ) {
        let updatedData = cloneDeep(newVisitSchedule)

        updatedData.push({ name: "", path: "" })

        setNewVisitSchedule(updatedData)
      }
    }
  }, [
    editCalculations,
    newCalculations,
    editProcedures,
    newProcedures,
    editEvaluations,
    newEvaluations,
    editVisitSchedule,
    newVisitSchedule,
  ])

  useEffect(() => {
    loadSites()
    loadTopicInfo()
    loadCalculations()
    loadProcedures()
    loadEvaluations()
    loadVisitSchedule()
  }, [
    loadCalculations,
    loadEvaluations,
    loadProcedures,
    loadSites,
    loadTopicInfo,
    loadVisitSchedule,
  ])

  return (
    <div>
      <div className="bg-white rounded-lg px-5 border mt-2.5 p-5">
        <div className="flex flex-row justify-between items-center">
          <h4 className="font-bold text-xl">Topic Info</h4>
          <div>
            {editTopic && (
              <Button
                className={`px-8 py-1.5 mb-0 mr-2 text-green-700 ${
                  topicInfoSaving ? "opacity-80" : ""
                }`}
                onClick={saveTopicInfo}
                disabled={topicInfoSaving}
              >
                {topicInfoSaving ? "Saving..." : "Save"}
              </Button>
            )}
            <Button
              className="px-3 py-1.5 mb-0"
              onClick={() => {
                setEditTopic(prev => !prev)
              }}
            >
              {editTopic ? (
                "Cancel"
              ) : (
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87M0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z"
                    fill="black"
                  />
                </svg>
              )}
            </Button>
          </div>
        </div>
        <hr />
        <div className="mt-5">
          {topicInfoLoading ? (
            <>
              <ContentLoader
                speed={2}
                width="100%"
                height={28}
                backgroundColor="#c2c2c2"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="2" rx="8" ry="8" width="80%" height="12" />
              </ContentLoader>
              <ContentLoader
                speed={2}
                width="100%"
                height={28}
                backgroundColor="#c2c2c2"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="2" rx="8" ry="8" width="80%" height="12" />
              </ContentLoader>
              <ContentLoader
                speed={2}
                width="100%"
                height={28}
                backgroundColor="#c2c2c2"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="2" rx="8" ry="8" width="80%" height="12" />
              </ContentLoader>
            </>
          ) : editTopic ? (
            <div className="suppl-html">
              <Editor
                editorState={editorState}
                editorClassName="editor"
                onEditorStateChange={editorState => {
                  setEditorState(editorState)
                }}
                handlePastedText={() => false}
              />
            </div>
          ) : (
            <>
              <div
                className={`suppl-html mb-3 ${
                  isHidden
                    ? "h-40 overflow-hidden shadow-[inset_0_-10px_7px_-10px_rgba(0,0,0,0.6)]"
                    : ""
                }`}
                dangerouslySetInnerHTML={{ __html: topicInfo }}
              ></div>
              <div className="text-center">
                <span
                  onClick={() => setIsHidden(!isHidden)}
                  className="cursor-pointer text-blue text-center"
                >
                  {isHidden ? "Show more" : "Hide"}
                </span>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg px-5 border mt-2.5 p-5">
        <div className="flex flex-row justify-between items-center">
          <h4 className="font-bold text-xl">Calculations</h4>
          <div>
            {editCalculations && (
              <Button
                className={`px-8 py-1.5 mb-0 mr-2 text-green-700 ${
                  calculationsSaving ? "opacity-80" : ""
                }`}
                onClick={saveCalculations}
                disabled={calculationsSaving}
              >
                {calculationsSaving ? "Saving..." : "Save"}
              </Button>
            )}
            <Button
              className="px-3 py-1.5 mb-0"
              onClick={handleEditCalculations}
            >
              {editCalculations ? (
                "Cancel"
              ) : (
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87M0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z"
                    fill="black"
                  />
                </svg>
              )}
            </Button>
          </div>
        </div>
        <hr />
        <div className="mt-5">
          {calculationsLoading ? (
            [...Array(4)].map((el, index) => (
              <div
                key={index}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={24}
                  backgroundColor="#c2c2c2"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
                </ContentLoader>
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={24}
                  backgroundColor="#c2c2c2"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
                </ContentLoader>
              </div>
            ))
          ) : editCalculations ? (
            newCalculations.map((calculation, index) => (
              <div
                key={index}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <Input
                  id={`calculations.${index}.name`}
                  value={calculation.name}
                  onChange={e =>
                    onCalculationChange(e.target.value, "name", index)
                  }
                  className="mr-2"
                  placeholder="Name"
                />
                <Input
                  id={`calculations.${index}.path`}
                  value={calculation.link}
                  onChange={e =>
                    onCalculationChange(e.target.value, "path", index)
                  }
                  placeholder="https://..."
                />
                {newCalculations.length !== index + 1 ? (
                  <span
                    className="cursor-pointer hover:underline text-red-500 ml-3"
                    onClick={() => handleRemoveCalculation(index)}
                  >
                    Remove
                  </span>
                ) : (
                  <div className="w-40"></div>
                )}
              </div>
            ))
          ) : calculations && calculations.length > 0 ? (
            calculations.map(calculation => (
              <div
                key={calculation.id}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <span className="w-1/2 inline-block">{calculation.name}</span>
                <a
                  href={calculation.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue hover:opacity-70 transition w-1/2 inline-block truncate"
                >
                  {calculation.link}
                </a>
              </div>
            ))
          ) : (
            <div className="py-3 px-5 rounded-lg border my-2 text-center">
              <span className="text-light-gray text-center">
                No calculations yet
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg px-5 border mt-2.5 p-5">
        <div className="flex flex-row justify-between items-center">
          <h4 className="font-bold text-xl">Procedures</h4>
          <div>
            {editProcedures && (
              <Button
                className={`px-8 py-1.5 mb-0 mr-2 text-green-700 ${
                  proceduresSaving ? "opacity-80" : ""
                }`}
                onClick={saveProcedures}
                disabled={proceduresSaving}
              >
                {proceduresSaving ? "Saving..." : "Save"}
              </Button>
            )}
            <Button className="px-3 py-1.5 mb-0" onClick={handleEditProcedures}>
              {editProcedures ? (
                "Cancel"
              ) : (
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87M0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z"
                    fill="black"
                  />
                </svg>
              )}
            </Button>
          </div>
        </div>
        <hr />
        <div className="mt-5">
          {proceduresLoading ? (
            [...Array(4)].map((el, index) => (
              <div
                key={index}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={24}
                  backgroundColor="#c2c2c2"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
                </ContentLoader>
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={24}
                  backgroundColor="#c2c2c2"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
                </ContentLoader>
              </div>
            ))
          ) : editProcedures ? (
            newProcedures.map((procedure, index) => (
              <div
                key={index}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <div className="flex-grow">
                  <div className="flex flex-row mb-3">
                    <Input
                      id={`calculations.${index}.name`}
                      value={procedure.name}
                      onChange={e =>
                        onProcedureChange(e.target.value, "name", index)
                      }
                      className="mr-2"
                      placeholder="Name"
                    />
                    <Input
                      id={`procedures.${index}.path`}
                      value={procedure.link}
                      onChange={e =>
                        onProcedureChange(e.target.value, "path", index)
                      }
                      placeholder="https://..."
                    />
                  </div>
                  <SiteSelect
                    id={`site-${index}`}
                    value={procedure?.site_id ?? -1}
                    onChange={e => {
                      onProcedureChange(e.target.value, "site_id", index)
                    }}
                    sites={sites}
                    defaultLabel="All sites"
                    defaultValue={-1}
                  />
                </div>

                {newProcedures.length !== index + 1 ? (
                  <span
                    className="cursor-pointer hover:underline text-red-500 ml-3 w-16"
                    onClick={() => handleRemoveProcedure(index)}
                  >
                    Remove
                  </span>
                ) : (
                  <div className="w-16 ml-3"></div>
                )}
              </div>
            ))
          ) : procedures && procedures.length > 0 ? (
            procedures.map(procedure => (
              <div
                key={procedure.id}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <span className="w-1/2 inline-block">{procedure.name}</span>
                <a
                  href={procedure.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue hover:opacity-70 transition w-1/2 inline-block truncate"
                >
                  {procedure.link}
                </a>
              </div>
            ))
          ) : (
            <div className="py-3 px-5 rounded-lg border my-2 text-center">
              <span className="text-light-gray text-center">
                No procedures yet
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg px-5 border mt-2.5 p-5">
        <div className="flex flex-row justify-between items-center">
          <h4 className="font-bold text-xl">Evaluations</h4>
          <div>
            {editEvaluations && (
              <Button
                className={`px-8 py-1.5 mb-0 mr-2 text-green-700 ${
                  evaluationsSaving ? "opacity-80" : ""
                }`}
                onClick={saveEvaluations}
                disabled={evaluationsSaving}
              >
                {evaluationsSaving ? "Saving..." : "Save"}
              </Button>
            )}
            <Button
              className="px-3 py-1.5 mb-0"
              onClick={handleEditEvaluations}
            >
              {editEvaluations ? (
                "Cancel"
              ) : (
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87M0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z"
                    fill="black"
                  />
                </svg>
              )}
            </Button>
          </div>
        </div>
        <hr />
        <div className="mt-5">
          {evaluationsLoading ? (
            [...Array(4)].map((el, index) => (
              <div
                key={index}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={24}
                  backgroundColor="#c2c2c2"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
                </ContentLoader>
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={24}
                  backgroundColor="#c2c2c2"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
                </ContentLoader>
              </div>
            ))
          ) : editEvaluations ? (
            newEvaluations.map((evaluation, index) => (
              <div
                key={index}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <div className="flex flex-row flex-grow">
                  <Input
                    id={`evaluations.${index}.name`}
                    value={evaluation.name}
                    onChange={e =>
                      onEvaluationChange(e.target.value, "name", index)
                    }
                    className="mr-2 !w-1/2"
                    placeholder="Name"
                  />
                  {evaluation.is_file ? (
                    <button className="border border-dashed border-light-gray text-light-gray w-1/2 rounded-lg font-light px-3 truncate hover:opacity-80 transition">
                      {evaluation.path}
                    </button>
                  ) : (
                    <Input
                      id={`evaluations.${index}.path`}
                      value={evaluation.path}
                      onChange={e =>
                        onEvaluationChange(e.target.value, "path", index)
                      }
                      placeholder="https://..."
                      className="!w-1/2"
                    />
                  )}
                </div>
                {newEvaluations.length !== index + 1 ? (
                  <span
                    className="cursor-pointer hover:underline text-red-500 ml-3"
                    onClick={() => handleRemoveEvaluation(index)}
                  >
                    Remove
                  </span>
                ) : (
                  <div className="w-16"></div>
                )}
              </div>
            ))
          ) : evaluations && evaluations.length > 0 ? (
            evaluations.map(evaluation => (
              <div
                key={evaluation.id}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <span className="w-1/2 inline-block">{evaluation.name}</span>
                <a
                  href={evaluation.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue hover:opacity-70 transition w-1/2 inline-block truncate"
                >
                  {evaluation.path}
                </a>
              </div>
            ))
          ) : (
            <div className="py-3 px-5 rounded-lg border my-2 text-center">
              <span className="text-light-gray text-center">
                No evaluations yet
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg px-5 border mt-2.5 p-5">
        <div className="flex flex-row justify-between items-center">
          <h4 className="font-bold text-xl">Visit Schedule</h4>
          <div>
            {editVisitSchedule && (
              <Button
                className={`px-8 py-1.5 mb-0 mr-2 text-green-700 ${
                  visitScheduleSaving ? "opacity-80" : ""
                }`}
                onClick={saveVisitSchedule}
                disabled={visitScheduleSaving}
              >
                {visitScheduleSaving ? "Saving..." : "Save"}
              </Button>
            )}
            <Button
              className="px-3 py-1.5 mb-0"
              onClick={handleEditVisitSchedule}
            >
              {editVisitSchedule ? (
                "Cancel"
              ) : (
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87M0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z"
                    fill="black"
                  />
                </svg>
              )}
            </Button>
          </div>
        </div>
        <hr />
        <div className="mt-5">
          {visitScheduleLoading ? (
            [...Array(4)].map((el, index) => (
              <div
                key={index}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={24}
                  backgroundColor="#c2c2c2"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
                </ContentLoader>
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={24}
                  backgroundColor="#c2c2c2"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="2" rx="8" ry="8" width="90%" height="12" />
                </ContentLoader>
              </div>
            ))
          ) : editVisitSchedule ? (
            newVisitSchedule.map((visitScheduleItem, index) => (
              <div
                key={index}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <div className="flex flex-row flex-grow">
                  <Input
                    id={`visit_schedule.${index}.name`}
                    value={visitScheduleItem.name}
                    onChange={e =>
                      onVisitScheduleChange(e.target.value, "name", index)
                    }
                    className="mr-2 !w-1/2"
                    placeholder="Name"
                  />
                  {visitScheduleItem.is_file ? (
                    <button className="border border-dashed border-light-gray text-light-gray w-1/2 rounded-lg font-light px-3 truncate hover:opacity-80 transition">
                      {visitScheduleItem.path}
                    </button>
                  ) : (
                    <Input
                      id={`visit_schedule.${index}.path`}
                      value={visitScheduleItem.path}
                      onChange={e =>
                        onVisitScheduleChange(e.target.value, "path", index)
                      }
                      placeholder="https://..."
                      className="!w-1/2"
                    />
                  )}
                </div>
                {newVisitSchedule.length !== index + 1 ? (
                  <span
                    className="cursor-pointer hover:underline text-red-500 ml-3"
                    onClick={() => handleRemoveVisitSchedule(index)}
                  >
                    Remove
                  </span>
                ) : (
                  <div className="w-16"></div>
                )}
              </div>
            ))
          ) : visitSchedule && visitSchedule.length > 0 ? (
            visitSchedule.map(visitScheduleItem => (
              <div
                key={visitScheduleItem.id}
                className="py-3 px-5 rounded-lg border flex flex-row items-center my-2"
              >
                <span className="w-1/2 inline-block">
                  {visitScheduleItem.name}
                </span>
                <a
                  href={visitScheduleItem.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue hover:opacity-70 transition w-1/2 inline-block truncate"
                >
                  {visitScheduleItem.path}
                </a>
              </div>
            ))
          ) : (
            <div className="py-3 px-5 rounded-lg border my-2 text-center">
              <span className="text-light-gray text-center">
                No visit schedule yet
              </span>
            </div>
          )}
        </div>
      </div>

      <InstructionalVideo studyId={studyId} />
      <StudySynopsis studyId={studyId} />
      <StudyProtocol studyId={studyId} />
      <ConsentForm studyId={studyId} />
    </div>
  )
}

export default SupplInfo
