import React, { useRef, useState } from "react"
import DataTable from "./DataTable"
import Modal from "../Modal"
import Input from "../Input"
import UploadAndCropImage from "../UploadAndCropImage"
import { useDispatch } from "react-redux"
import cloneDeep from "lodash/cloneDeep"
import { updateNestedProperty } from "../../helpers"
import { createEntity, updateEntity } from "../../store/entity/entity.actions"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"
import { loadEntities } from "../../store/entities/entities.actions"

const initialFormState = {
  sponsors_name: "",
  email: "",
  firstname: "",
  lastname: "",
  phone: {
    country_code: "",
    phone: "",
  },
}

const SponsorsDataTable = () => {
  const endpoint = "sponsors/search"
  const [addModal, setAddModal] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)
  const [image, setImage] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [form, setForm] = useState(initialFormState)
  const [createdId, setCreatedId] = useState(null)
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const [isUploading, setIsUploading] = useState(false)
  const uploadRef = useRef(null)
  let rows = [
    {
      id: "image_path",
      title: "Logo",
      type: "image",
      center: true,
    },
    {
      id: "name",
      title: "Name",
      type: "text",
      center: true,
    },
    {
      id: "email",
      title: "Email",
      type: "text",
      center: true,
    },
    {
      id: "studies_count",
      title: "N. Studies",
      type: "text",
      center: true,
    },
    {
      id: "action",
      title: "",
      type: "action",
      link: "/sponsors/{param}",
      text: "View sponsor",
      center: true,
    },
  ]

  const onInputChange = (key, value) => {
    let newForm = cloneDeep(form)

    if (key.includes(".")) {
      updateNestedProperty(newForm, key, value)
    } else {
      newForm[key] = value
    }

    setForm(newForm)
  }

  const onCreateSubmit = () => {
    dispatch(createEntity({ endpoint: "sponsors/", data: form }))
      .unwrap()
      .then(response => {
        if (response !== undefined) {
          setForm(initialFormState)

          setAddModal(false)

          if (response?.data.id) {
            addAlertWithTimeout(
              "Success",
              <>
                <p>Sponsor has been created successfully.</p>
                <p className="font-bold mt-2">Click here to open</p>
              </>,
              AlertType.Success,
              `sponsors/${response.data.id}`
            )

            dispatch(loadEntities({ endpoint: "sponsors/search" }))

            setCreatedId(response.data.id)
            setUploadModal(true)
            setStep(2)
          }
        }
      })
  }

  const onCropSubmit = () => {
    uploadRef.current.cropImageAction()

    setStep(4)
  }

  const onUploadSubmit = () => {
    setIsUploading(true)

    dispatch(
      updateEntity({
        endpoint: "users/update-profile-image/" + createdId,
        data: {
          image: croppedImage,
        },
      })
    )
      .unwrap()
      .then(response => {
        setIsUploading(false)

        if (response !== undefined) {
          addAlertWithTimeout(
            "Success",
            <>
              <p>Sponsor's profile image has been uploaded successfully.</p>
            </>,
            AlertType.Success
          )

          dispatch(loadEntities({ endpoint: "sponsors/search" }))

          closeUploadModal()
        }
      })
  }

  const backAction = () => {
    setImage(null)
    setStep(2)
  }

  const closeUploadModal = () => {
    setUploadModal(false)
    setCreatedId(null)
    setImage(null)
    setCroppedImage(null)
    setStep(1)
  }

  return (
    <>
      <DataTable
        rows={rows}
        endpoint={endpoint}
        setAddNewModal={setAddModal}
        searchByText="Search by email, sponsor's name, or contact person's first and last name"
      />

      <Modal
        title="Add new Sponsor"
        size="xl"
        isShown={addModal}
        setIsShown={setAddModal}
        onSubmit={onCreateSubmit}
        onClose={() => setForm(initialFormState)}
      >
        <div className="mb-3">
          Sponsor's name:
          <Input
            id="sponsors_name"
            onChange={e => onInputChange("sponsors_name", e.target.value)}
            value={form.sponsors_name}
            placeholder="Sponsor's name"
          />
        </div>
        <div className="mb-3">
          Email:
          <Input
            id="email"
            onChange={e => onInputChange("email", e.target.value)}
            value={form.email}
            placeholder="Email"
          />
        </div>
        <div className="mb-3">
          Phone number:
          <div className="flex">
            <Input
              id="country_code"
              className="!w-20 text-center mr-2"
              onChange={e =>
                onInputChange("phone.country_code", e.target.value)
              }
              value={form.phone.country_code}
              placeholder="+1"
            />
            <Input
              id="phone"
              onChange={e => onInputChange("phone.phone", e.target.value)}
              value={form.phone.phone}
              placeholder="(999) 999 9999"
            />
          </div>
        </div>
        <div className="mb-3">
          Contact's first name:
          <Input
            id="firstname"
            onChange={e => onInputChange("firstname", e.target.value)}
            value={form.firstname}
            placeholder="Contact's first name"
          />
        </div>
        <div className="mb-3">
          Contact's last name:
          <Input
            id="lastname"
            onChange={e => onInputChange("lastname", e.target.value)}
            value={form.lastname}
            placeholder="Contact's last name"
          />
        </div>
      </Modal>
      <Modal
        title="Upload image"
        size="lg"
        isShown={uploadModal}
        setIsShown={setUploadModal}
        onSubmit={step === 3 ? onCropSubmit : onUploadSubmit}
        submitActionActive={
          (step === 3 && image) || (step === 4 && croppedImage && !isUploading)
        }
        submitActionLoading={isUploading}
        onClose={() => setCreatedId(null)}
        leftButtonText="Skip"
        leftButtonAction={closeUploadModal}
        secondaryText="Back"
        secondaryAction={backAction}
        secondaryActionActive={image}
      >
        <UploadAndCropImage
          ref={uploadRef}
          image={image}
          setImage={setImage}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          initialStep={2}
          currentStep={step}
          setStep={setStep}
          handlerCancel={backAction}
          type="jpeg"
          cropShape="round"
        />
      </Modal>
    </>
  )
}

export default SponsorsDataTable
