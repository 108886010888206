import { createSlice } from "@reduxjs/toolkit"
import { loadEntities } from "./entities.actions"

const initialState = {
  data: null,
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  perPage: 1,
  isLoading: false,
  isError: false,
}

export const entitiesReducer = createSlice({
  name: "entities",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      let page = parseInt(action.payload)

      state.currentPage = isNaN(page) ? 0 : page
    },
    clearEntities(state, action) {
      state.data = null
    },
  },
  extraReducers: builder => {
    builder.addCase(loadEntities.pending, (state, action) => {
      state.data = null
      state.isLoading = true
      state.isError = false
      state.totalItems = "..."
    })
    builder.addCase(loadEntities.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.totalPages = action.payload.meta?.last_page
      state.totalItems = action.payload.meta?.total
      state.perPage = action.payload.meta?.per_page
      state.isLoading = false
      state.isError = false
    })
    builder.addCase(loadEntities.rejected, (state, action) => {
      state.data = null
      state.currentPage = 1
      state.totalPages = 1
      state.totalItems = 0
      state.isLoading = false
      state.isError = true
    })
  },
})

export const { setCurrentPage, clearEntities } = entitiesReducer.actions
export default entitiesReducer.reducer
