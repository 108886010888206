import React from "react"
import ContentLoader from "react-content-loader"

const RowsLoader = ({ columnsCount, rowsCount }) => {
  let rows = []

  for (let r = 0; r < rowsCount; r++) {
    let columns = []

    for (let c = 0; c < columnsCount; c++) {
      columns.push(
        <td
          key={c}
          className="first:rounded-l-md last:rounded-r-md py-7 px-3 text-light-gray text-center"
        >
          <ContentLoader
            speed={2}
            width="100%"
            height={24}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="2" rx="8" ry="8" width="80%" height="16" />
          </ContentLoader>
        </td>
      )
    }

    rows.push(
      <tr key={r} className="bg-white">
        {columns}
      </tr>
    )
  }

  return rows
}

export default RowsLoader
