import { Outlet, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectAuthData } from "../store/auth/auth.selector"
import { useEffect, useState } from "react"

const ProtectedRoute = () => {
  const { user } = useSelector(selectAuthData)
  const [isReady, setIsReady] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      return navigate("/login")
    }

    setIsReady(true)
  }, [navigate, user])

  return isReady ? <Outlet /> : <></>
}

export default ProtectedRoute
