import React from "react"
import SitesDataTable from "../../components/DataTables/SitesDataTable"
import Pagination from "../../components/Pagination"

const SitesPage = () => (
  <>
    <Pagination data={[{ title: "Sites", link: "./sites" }]} />
    <SitesDataTable />
  </>
)

export default SitesPage
