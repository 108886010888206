import { createSlice } from "@reduxjs/toolkit"
import {
  authMe,
  checkLoginRequest,
  createLoginRequest,
  logout,
} from "./auth.actions"
import { ACCESS_TOKEN } from "../../constants"

const initialState = {
  email: "",
  loginRequestId: null,
  loginRequestStatus: null,
  accessToken: localStorage.getItem(ACCESS_TOKEN),
  user: null,
  userLoadingStatus: null,
}

export const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearLoginRequestId(state, action) {
      state.loginRequestId = null
    },
    setEmail(state, action) {
      state.email = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(createLoginRequest.pending, (state, action) => {
      state.loginRequestId = null
      state.loginRequestStatus = "pending"
    })
    builder.addCase(createLoginRequest.fulfilled, (state, action) => {
      state.loginRequestId = action.payload
      state.loginRequestStatus = "success"
    })
    builder.addCase(createLoginRequest.rejected, (state, action) => {
      state.loginRequestId = null
      state.loginRequestStatus = "error"
    })

    builder.addCase(checkLoginRequest.fulfilled, (state, action) => {
      if (action.payload.token) {
        state.loginRequestId = null
        state.email = ""
        state.accessToken = action.payload.token
        state.user = action.payload.user
        state.checkLoginRequestStatus = "success"

        localStorage.setItem(ACCESS_TOKEN, action.payload.token)
      }
    })

    builder.addCase(authMe.pending, (state, action) => {
      state.user = null
      state.userLoadingStatus = "pending"
    })
    builder.addCase(authMe.fulfilled, (state, action) => {
      state.user = action.payload
      state.userLoadingStatus = "success"
    })
    builder.addCase(authMe.rejected, (state, action) => {
      state.user = null
      state.userLoadingStatus = "error"
    })

    builder.addCase(logout.fulfilled, (state, action) => {
      state.user = null
      state.accessToken = null

      localStorage.removeItem(ACCESS_TOKEN)
    })
  },
})

export const { clearLoginRequestId, setEmail } = authReducer.actions
export default authReducer.reducer
