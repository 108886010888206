import Loader from "./components/Loader"
import Routes from "./routes"
import React from "react"
import useLoadedUser from "./hooks/useLoadedUser"

const App = () => {
  let isLoadedUser = useLoadedUser()

  return isLoadedUser ? <Routes /> : <Loader />
}

export default App
