import React from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { getFullSiteName } from "../../helpers"

const SiteSelect = ({
  id,
  sites,
  value,
  onChange,
  defaultValue = null,
  defaultLabel = "Select...",
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={id}>Select site</InputLabel>
      <Select
        label="Select site"
        id={id}
        value={value}
        onChange={onChange}
        className="min-w-[200px]"
      >
        {defaultValue && (
          <MenuItem value={defaultValue} key={defaultValue}>
            {defaultLabel}
          </MenuItem>
        )}
        {sites.map(site => (
          <MenuItem value={site.id} key={site.id}>
            {getFullSiteName(site)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SiteSelect
