import React from "react"

const Input = props => (
  <input
    {...props}
    className={[
      "form-control font-light px-2 py-1 w-full text-gray-700 bg-white border border-gray-300 rounded focus:text-gray-700",
      props.className,
    ].join(" ")}
  />
)

export default Input
