import { configureStore } from "@reduxjs/toolkit"
import authSlice from "./auth/auth.reducer"
import entitiesSlice from "./entities/entities.reducer"
import entitySlice from "./entity/entity.reducer"
import alertsSlice from "./alerts/entity.reducer"
import statsSlice from "./stats/stats.reducer"

export const store = configureStore({
  reducer: {
    auth: authSlice,
    entities: entitiesSlice,
    entity: entitySlice,
    alerts: alertsSlice,
    stats: statsSlice,
  },
})
