import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"

const Dropzone = ({ setFile, onDropAction = () => {} }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      let file = acceptedFiles[0]

      setFile(file)

      onDropAction()
    },
    [onDropAction, setFile]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop })

  return (
    <div className="flex items-center flex-col flex-1">
      <div
        {...getRootProps()}
        className={`flex flex-col items-center justify-center p-10 border-2 border-dashed rounded w-full h-48 bg-[#fafafa] cursor-pointer text-center ${
          isDragAccept && "bg-[#7467EF]"
        } ${isDragReject && "bg-[#ff1744]"} ${
          isDragActive && "bg-[#2196f3]"
        } text-[#bdbdbd]`}
      >
        <CloudUploadIcon style={{ fontSize: 80 }} />
        <input {...getInputProps()} multiple={false} />
        {isDragActive ? (
          <p>Drop the file here ...</p>
        ) : (
          <p>
            Drag 'n' drop the file here, or <u>click</u> to select the image
          </p>
        )}
      </div>
    </div>
  )
}

export default Dropzone
