import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteEntity,
  loadEntity,
  updateEntity,
} from "../../store/entity/entity.actions"
import { selectEntityData } from "../../store/entity/entity.selector"
import ContentLoader from "react-content-loader"
import Pagination from "../../components/Pagination"
import Button from "../../components/Button"
import Input from "../../components/Input"
import Modal from "../../components/Modal"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"
import { clearEntity } from "../../store/entity/entity.reducer"

const SingleSitePage = props => {
  const { id } = useParams()
  const { isLoading, data } = useSelector(selectEntityData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [edit, setEdit] = useState(false)
  const [site, setSite] = useState({})
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const fields = [
    { title: "Center", editable: true, key: "center" },
    { title: "Department", editable: true, key: "department" },
    { title: "Country", editable: true, key: "country" },
  ]

  const toggleEdit = () => {
    if (!edit) {
      setSite(data)
    }

    setEdit(!edit)
  }

  const save = () => {
    setIsUpdating(true)

    dispatch(updateEntity({ endpoint: "sites/" + id, data: site }))
      .unwrap()
      .then(r => {
        setIsUpdating(false)

        if (r !== undefined) {
          dispatch(loadEntity({ endpoint: "sites/" + id }))
          setEdit(false)
        }
      })
  }

  const deleteItem = () => {
    setConfirmDeleteModal(true)
  }

  const cancelEdit = () => {
    setEdit(!edit)
  }

  const changeField = (key, value) => {
    let item = { ...site }

    item[key] = value

    setSite(item)
  }

  const deleteAction = () => {
    dispatch(deleteEntity({ endpoint: `sites/${data.id}` }))
      .unwrap()
      .then(response => {
        if (response !== undefined) {
          setConfirmDeleteModal(false)

          setTimeout(() => {
            addAlertWithTimeout(
              "Site successfully deleted",
              <>
                <p>
                  <span className="font-bold">
                    {data.center} {data.department}
                  </span>{" "}
                  has been deleted successfully.
                </p>
              </>,
              AlertType.Warning
            )
          }, 500)

          return navigate(`/sites`, { replace: true })
        }
      })
  }

  useEffect(() => {
    dispatch(loadEntity({ endpoint: "sites/" + id }))

    return () => dispatch(clearEntity())
  }, [dispatch, id])

  return (
    <>
      <Pagination
        data={
          data
            ? [
                { title: "Sites", link: "/sites" },
                {
                  title: `${data.center} ${data.department}`,
                  link: `/sites/${data.id}`,
                },
              ]
            : null
        }
      />

      <div className="flex flex-col md:flex-row w-full bg-light-blue rounded-md border-2 py-8 px-8 lg:max-w-[85%]">
        <div className="flex flex-col sm:flex-row grow flex-wrap">
          {fields.map((field, index) => (
            <div className="w-[200px] mb-5" key={index}>
              <span className="text-sm text-light-gray">{field.title}</span>
              <br />
              {isLoading && (
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={24}
                  backgroundColor="#c2c2c2"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="2" rx="8" ry="8" width="80%" height="12" />
                </ContentLoader>
              )}
              {data &&
                (edit ? (
                  <div className="pr-5">
                    <Input
                      id={field.key}
                      value={site[field.key]}
                      onChange={event => {
                        changeField(field.key, event.target.value)
                      }}
                    />
                  </div>
                ) : (
                  <span className="font-medium">{data[field.key]}</span>
                ))}
            </div>
          ))}
        </div>
        <div className="flex flex-col">
          <Button
            onClick={edit ? save : toggleEdit}
            loading={isUpdating || isLoading}
          >
            {isUpdating ? "..." : (edit ? "Save" : "Edit")}
          </Button>
          <Button
            onClick={edit ? cancelEdit : deleteItem}
            className="text-red-500"
            loading={isUpdating || isLoading}
          >
            {edit ? "Cancel" : "Delete"}
          </Button>
        </div>
      </div>

      <Modal
        title="Delete Site"
        size="md"
        isShown={confirmDeleteModal}
        setIsShown={setConfirmDeleteModal}
        onSubmit={deleteAction}
        submitText="Yes"
      >
        <p>
          Are you sure you want to delete{" "}
          <span className="font-bold">
            {data && `${data.center} ${data.department}`}
          </span>
        </p>
      </Modal>
    </>
  )
}

export default SingleSitePage
