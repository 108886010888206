import React from "react"
import DataTable from "./DataTable"

const StudiesDataTable = props => {
  const endpoint = "studies/search"
  const newItemRoute = "/studies/create"

  let rows = [
    {
      id: "sponsor_image",
      title: "Logo",
      type: "image",
      center: true,
    },
    {
      id: "name",
      title: "Name",
      type: "text",
    },
    {
      id: "status.label",
      title: "Status",
      type: "status",
      center: true,
    },
    {
      id: "action",
      title: "",
      type: "action",
      link: "/studies/{param}",
      text: "View study",
      center: true,
    },
  ]

  return (
    <DataTable rows={rows} endpoint={endpoint} newItemRoute={newItemRoute} searchByText="Search by study name" />
  )
}

export default StudiesDataTable
