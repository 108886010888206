import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { DOTS, usePagination } from "../../hooks/usePagination"
import debounce from "lodash.debounce"
import { loadEntities } from "../../store/entities/entities.actions"
import { useDispatch, useSelector } from "react-redux"
import { selectEntitiesData } from "../../store/entities/entities.selector"
import { setCurrentPage } from "../../store/entities/entities.reducer"
import Accordion from "../Accordion"
import RowsLoader from "./RowsLoader"
import Folder from "@mui/icons-material/FolderOpen"
import { getFullSiteName } from "../../helpers"
import Modal from "../Modal"

import { styled } from "@mui/material/styles"
import Popper from "@mui/material/Popper"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete"
import InputBase from "@mui/material/InputBase"
import Box from "@mui/material/Box"
import { cloneDeep, forEach } from "lodash"
import { deleteEntity, updateEntity } from "../../store/entity/entity.actions"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"
import SiteSelect from "../Selects/SiteSelect"
import RoleSelect from "../Selects/RoleSelect"
import { Roles } from "../../constants"

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === "light" ? " #eaecef" : "#30363d"
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}))

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
  borderRadius: 6,
  width: 500,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}))

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  borderBottom: `1px solid ${
    theme.palette.mode === "light" ? "#eaecef" : "#30363d"
  }`,
  "& input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid ${
      theme.palette.mode === "light" ? "#eaecef" : "#30363d"
    }`,
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === "light"
          ? "rgba(3, 102, 214, 0.3)"
          : "rgb(12, 45, 107)"
      }`,
      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}))

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}

const UsersStudiesDataTable = ({ userId }) => {
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState(0)
  const [inviteModal, setInviteModal] = useState(false)
  const [editShareModal, setEditShareModal] = useState(false)
  const [editedShareId, setEditedShareId] = useState(null)
  const [editedShareRole, setEditedShareRole] = useState(2)
  const [editedShareSite, setEditedShareSite] = useState("")
  const [confirmDeleteShare, setConfirmDeleteShare] = useState(false)
  const [sites, setSites] = useState([])
  const [usersStudies, setUsersStudies] = useState([])
  const [allInvitableStudies, setAllInvitableStudies] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isInvitableLoading, setIsInvitableLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [inviteData, setInviteData] = useState({})
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [value, setValue] = React.useState([])
  const [pendingValue, setPendingValue] = React.useState([])
  const [active, setActive] = useState(null)
  const openStudiesSelect = Boolean(anchorEl)
  const studySelectId = openStudiesSelect ? "studies-label" : undefined
  const { currentPage, totalPages, perPage, totalItems } =
    useSelector(selectEntitiesData)
  const dispatch = useDispatch()
  let [searchParams, setSearchParams] = useSearchParams()
  const paginationRange = usePagination({
    totalPages,
    currentPage,
  })

  let queryParams = useMemo(
    function () {
      return {
        page: searchParams.get("page"),
      }
    },
    [searchParams]
  )

  const loadUsersStudies = useCallback(
    (pageNumber = 1, search = "") => {
      setIsLoading(true)

      queryParams.page = pageNumber

      let queryStr = new URLSearchParams({
        page: queryParams.page,
        search: search,
        archived: status,
      }).toString()

      dispatch(
        loadEntities({ endpoint: `users/${userId}/studies/search?${queryStr}` })
      )
        .unwrap()
        .then(r => {
          if (r !== undefined) {
            setUsersStudies(r.data)
          }

          setIsLoading(false)
        })
    },
    [dispatch, queryParams, status, userId]
  )

  const debounceFn = useMemo(
    () =>
      debounce(inputValue => {
        loadUsersStudies(1, inputValue)
      }, 500),
    [loadUsersStudies]
  )

  const onChangeSearch = event => {
    setSearch(event.target.value)
    debounceFn(event.target.value)
  }

  const setQueryParam = useCallback(
    (name, value) => {
      const newQueryParameters = new URLSearchParams()

      newQueryParameters.set(name, value)
      setSearchParams(newQueryParameters)
    },
    [setSearchParams]
  )

  const setPage = useCallback(
    pageNumber => {
      setQueryParam("page", pageNumber)

      dispatch(setCurrentPage(pageNumber))

      loadUsersStudies(pageNumber)
    },
    [setQueryParam, dispatch, loadUsersStudies]
  )

  const handleOpenAnchor = event => {
    setPendingValue(value)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseStudiesSelect = () => {
    setValue(pendingValue)
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const handleToggle = index => {
    if (active === index) {
      setActive(null)
    } else {
      setActive(index)
    }
  }

  const handleOpenEditShareModal = (shareId, roleId, siteId) => {
    setEditShareModal(true)
    setEditedShareId(shareId)
    setEditedShareRole(roleId)
    setEditedShareSite(siteId)
  }

  const handleCloseEditShareModal = () => {
    setEditShareModal(false)
    setEditedShareRole(2)
    setEditedShareSite("")
  }

  const handleUpdateShare = () => {
    dispatch(
      updateEntity({
        endpoint: `shares/${editedShareId}`,
        data: {
          role_id: editedShareRole,
          site_id: editedShareSite,
        },
      })
    )
      .unwrap()
      .then(r => {
        handleCloseEditShareModal()

        if (r.message) {
          addAlertWithTimeout(
            "Share Updated",
            <>
              <p>{r.message}</p>
            </>,
            AlertType.Success
          )
        }

        loadUsersStudies()
      })
  }

  const handleDeleteShare = () => {
    dispatch(deleteEntity({ endpoint: `shares/${editedShareId}` }))
      .unwrap()
      .then(r => {
        handleCloseEditShareModal()

        if (r.message) {
          addAlertWithTimeout(
            "Share Deleted",
            <>
              <p>{r.message}</p>
            </>,
            AlertType.Warning
          )
        }

        loadUsersStudies()
      })
  }

  const handleOpenInviteModal = () => {
    setIsInvitableLoading(true)

    dispatch(loadEntities({ endpoint: `users/${userId}/studies-for-invite` }))
      .unwrap()
      .then(r => {
        setAllInvitableStudies(r?.data ?? [])
        setIsInvitableLoading(false)
      })

    setInviteModal(true)
  }

  const handleCloseInviteModal = () => {
    setValue([])
    setPendingValue([])
    setAllInvitableStudies([])

    setInviteModal(false)
  }

  const save = () => {
    setIsSaving(true)

    dispatch(
      updateEntity({
        endpoint: `users/${userId}/invite`,
        data: { invite_to: inviteData },
      })
    )
      .unwrap()
      .then(r => {
        if (r !== undefined) {
          setIsSaving(false)

          handleCloseInviteModal()

          loadUsersStudies()

          addAlertWithTimeout(
            "Invite success",
            <>
              <p>User has been invited successfully.</p>
            </>,
            AlertType.Success
          )
        }
      })
  }

  useEffect(() => {
    setPage(1)
  }, [status]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (usersStudies?.length > 0) {
      setActive(usersStudies[0].id)
    }
  }, [usersStudies])

  useEffect(() => {
    setPage(queryParams.page ?? 1)

    dispatch(loadEntities({ endpoint: "sites" }))
      .unwrap()
      .then(r => {
        setSites(r?.data ?? [])
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="border-2 rounded-md bg-light-blue px-3 lg:max-w-[85%] mb-5">
        <div className="flex justify-between">
          <div className="mr-2 my-3 w-full">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                id="search"
                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-100 transition duration-200 focus:bg-white outline-0"
                placeholder="Search by Topic or Study name"
                autoComplete="off"
                value={search}
                onChange={onChangeSearch}
              />
            </div>
          </div>
          <button
            onClick={() => handleOpenInviteModal(true)}
            className="block text-center py-2 px-4 my-3 w-40 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-100 transition hover:bg-white focus:bg-white"
          >
            Invite to Studies
          </button>
        </div>
        <div>
          <span
            className={`ml-1 mr-3 cursor-pointer underline-offset-4 decoration-1 ${
              status === 0 ? "underline" : "opacity-50"
            }`}
            onClick={() => setStatus(0)}
          >
            Active
          </span>
          <span
            className={`cursor-pointer underline-offset-4 decoration-1 ${
              status === 1 ? "underline" : "opacity-50"
            }`}
            onClick={() => setStatus(1)}
          >
            Archived
          </span>
        </div>
        <div className="overflow-hidden overflow-x-auto visible-horizontal-scrollbar mb-2.5 min-h-[250px]">
          {usersStudies && !isLoading ? (
            usersStudies.length > 0 ? (
              usersStudies.map(topic => (
                <Accordion
                  key={topic.id}
                  active={active}
                  title={topic.name}
                  handleToggle={handleToggle}
                  id={topic.id}
                  icon={
                    <Folder
                      className="mt-0.5 mr-5"
                      style={{ color: "#446B95", fontSize: 24 }}
                    />
                  }
                >
                  <table className="mt-2 text-center w-full border-t border-spacing-y-1.5 border-separate">
                    <thead>
                      <tr className="[&>td]:text-light-gray [&>td]:font-light">
                        <td className="py-5 pl-6 text-left">Name</td>
                        <td className="py-5">Site</td>
                        <td className="py-5">Role</td>
                        <td className="py-5">Status</td>
                        <td className="py-5"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {topic.studies &&
                        topic.studies.map(study => (
                          <tr
                            key={study.id}
                            className="[&>td]:border-y [&>td]:px-5 [&>td]:py-7"
                          >
                            <td className="rounded-l-lg border-l text-left">
                              <Link
                                to={`/studies/${study.id}`}
                                className="text-link-color transition hover:opacity-70"
                              >
                                {study.name}
                              </Link>
                            </td>
                            <td>{getFullSiteName(study.site)}</td>
                            <td>{Roles[study.role_id]}</td>
                            <td>
                              {study.status_id === 5 ? "Archived" : "Active"}
                            </td>
                            <td className="rounded-r-lg border-r">
                              <span
                                className="text-link-color text-sm transition hover:opacity-70 cursor-pointer"
                                onClick={() =>
                                  handleOpenEditShareModal(
                                    study.share_id,
                                    study.role_id,
                                    study.site.id
                                  )
                                }
                              >
                                Edit share
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Accordion>
              ))
            ) : (
              <div className="w-full rounded-md py-7 px-3 text-light-gray text-center">
                <span>Nothing not found</span>
              </div>
            )
          ) : (
            <table className="mt-2 text-center w-full border-t border-spacing-y-1.5 border-separate">
              <tbody>
                <RowsLoader columnsCount={4} rowsCount={perPage} />
              </tbody>
            </table>
          )}
        </div>
        <div>
          <p className="text-xs text-light-gray pb-2">
            Total records: {totalItems}
          </p>
        </div>
        <div>
          <ul className="flex justify-center mb-2.5">
            {paginationRange &&
              paginationRange.map((pageNumber, i) => {
                if (pageNumber === DOTS) {
                  return (
                    <li key={i} className="mx-1">
                      &#8230;
                    </li>
                  )
                }

                return (
                  <li
                    className={`mx-1 font-light cursor-pointer ${
                      pageNumber !== currentPage
                        ? "underline underline-offset-4"
                        : null
                    }`}
                    key={i}
                    onClick={() => {
                      setPage(pageNumber)
                    }}
                  >
                    {pageNumber}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>

      <Modal
        title="Edit share"
        size="lg"
        isShown={editShareModal}
        setIsShown={setEditShareModal}
        onSubmit={() => {
          handleUpdateShare()
          handleCloseEditShareModal()
        }}
        onClose={handleCloseEditShareModal}
        submitActionActive={true}
        submitActionLoading={false}
        leftButtonText="Delete share"
        leftButtonClassNames="text-red-500"
        leftButtonAction={() => setConfirmDeleteShare(true)}
      >
        <div className="flex flex-col">
          <div className="mb-4">
            <RoleSelect
              id="edit-share-role"
              value={editedShareRole}
              onChange={event => setEditedShareRole(event.target.value)}
            />
          </div>

          <div>
            <SiteSelect
              id="edit-share-site"
              sites={sites}
              value={editedShareSite}
              onChange={event => setEditedShareSite(event.target.value)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        title="Delete Share"
        size="md"
        isShown={confirmDeleteShare}
        setIsShown={setConfirmDeleteShare}
        onSubmit={() => {
          handleDeleteShare()
          setConfirmDeleteShare(false)
        }}
        submitText="Yes"
      >
        <p>Are you sure you want to remove this user from the study?</p>
      </Modal>

      <Modal
        title="Invite user to studies"
        size="full"
        isShown={inviteModal}
        setIsShown={setInviteModal}
        onClose={handleCloseInviteModal}
        onSubmit={save}
        submitActionLoading={isSaving}
      >
        <div>
          <button
            className="mb-5 px-5 py-2.5 bg-blue text-white font-medium rounded shadow-md hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition hover:opacity-80 active:bg-[#012b4b]"
            onClick={handleOpenAnchor}
          >
            <span>+ Click here to select studies</span>
          </button>
          <table className="w-full border-spacing-y-1.5 border-separate table-fixed">
            <thead></thead>
            <tbody>
              {value.length < 1 ? (
                <tr>
                  <td className="rounded-lg text-center px-3 py-5">
                    <span className="text-light-gray">
                      No studies selected yet.
                      <br /> Press Select studies button to add studies
                    </span>
                  </td>
                </tr>
              ) : (
                value.map(study => (
                  <tr
                    className="[&>td]:border-y [&>td]:px-5 [&>td]:py-3"
                    key={study.name}
                  >
                    <td className="rounded-l-lg border-l text-left">
                      {study.name}
                      <span className="font-bold">
                        {study.archived ? " (Archived)" : ""}
                      </span>
                    </td>
                    <td>
                      <SiteSelect
                        id={`site-${study.id}`}
                        value={inviteData[study.id]?.site_id ?? ""}
                        onChange={event => {
                          setInviteData(inviteData => {
                            let updatedData = cloneDeep(inviteData)

                            updatedData[study.id]["site_id"] =
                              event.target.value

                            return updatedData
                          })
                        }}
                        sites={sites}
                      />
                    </td>
                    <td className="rounded-r-lg border-r">
                      <RoleSelect
                        id={`role-${study.id}`}
                        value={inviteData[study.id]?.role_id ?? ""}
                        onChange={event => {
                          setInviteData(inviteData => {
                            let updatedData = cloneDeep(inviteData)

                            updatedData[study.id]["role_id"] =
                              event.target.value

                            return updatedData
                          })
                        }}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <StyledPopper
          id={studySelectId}
          open={openStudiesSelect}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={handleCloseStudiesSelect}>
            <div>
              <div className="border-b py-3 px-4 flex justify-between">
                <span className="font-bold text-[16px]">
                  Select or deselect the studies to which you want to add the
                  user
                </span>
                <button
                  className="px-5 py-2.5 bg-blue text-white font-medium rounded shadow-md hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition hover:opacity-80 active:bg-[#012b4b]"
                  onClick={handleCloseStudiesSelect}
                >
                  Apply
                </button>
              </div>
              <Autocomplete
                open
                multiple
                onClose={(event, reason) => {
                  if (reason === "escape") {
                    handleCloseStudiesSelect()
                  }
                }}
                value={pendingValue}
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    event.key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return
                  }
                  setPendingValue(newValue)

                  setInviteData(inviteData => {
                    let updatedData = {}

                    newValue.map(study => {
                      return (updatedData[study.id] = {
                        role_id: updatedData[study.id] ?? 2,
                        site_id: updatedData[study.id] ?? null,
                      })
                    })

                    forEach(newValue, study => {
                      if (inviteData[study.id]) {
                        updatedData[study.id] = { ...inviteData[study.id] }
                      } else {
                        updatedData[study.id] = {
                          role_id: 2,
                          site_id: null,
                        }
                      }
                    })

                    return updatedData
                  })
                }}
                disableCloseOnSelect
                PopperComponent={PopperComponent}
                renderTags={() => null}
                noOptionsText={
                  isInvitableLoading ? "Loading..." : "No studies available"
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Box
                      component={DoneIcon}
                      sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
                      style={{
                        visibility: selected ? "visible" : "hidden",
                      }}
                    />
                    <Box
                      component="span"
                      sx={{
                        width: 14,
                        height: 14,
                        flexShrink: 0,
                        borderRadius: "3px",
                        mr: 1,
                        mt: "2px",
                      }}
                      style={{ backgroundColor: option.color }}
                    />
                    <div>
                      {option.name}
                      <span className="font-bold">
                        {option.archived ? " (Archived)" : ""}
                      </span>
                    </div>
                    <Box
                      component={CloseIcon}
                      sx={{ opacity: 0.6, width: 18, height: 18 }}
                      style={{
                        visibility: selected ? "visible" : "hidden",
                      }}
                    />
                  </li>
                )}
                options={[...allInvitableStudies].sort((a, b) => {
                  let ai = value.indexOf(a)
                  let bi = value.indexOf(b)

                  ai =
                    ai === -1
                      ? value.length + allInvitableStudies.indexOf(a)
                      : ai
                  bi =
                    bi === -1
                      ? value.length + allInvitableStudies.indexOf(b)
                      : bi

                  return ai - bi
                })}
                getOptionLabel={option => option.name + "asd"}
                renderInput={params => (
                  <StyledInput
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                    placeholder="Search a study"
                  />
                )}
              />
            </div>
          </ClickAwayListener>
        </StyledPopper>
      </Modal>
    </>
  )
}

export default UsersStudiesDataTable
