import React from "react"

const Modal = ({
  children,
  isShown,
  setIsShown,
  size = "xl",
  title = "",
  onClose = () => {},
  submitText = "Save",
  onSubmit = () => {},
  submitActionActive = true,
  submitActionLoading = false,
  secondaryText = "Cancel",
  secondaryAction,
  secondaryActionActive = true,
  showSecondary = true,
  leftButtonText = "",
  leftButtonAction = () => {},
  leftButtonClassNames = "",
  fullHeight = false,
  noButtons = false,
  repeatTopButtons = false
}) => {
  const sizesVariants = {
    xs: "max-w-xs",
    sm: "max-w-sm",
    md: "max-w-md",
    lg: "max-w-xl",
    xl: "max-w-2xl",
    xxl: "max-w-6xl",
    full: "max-w-full",
  }

  const onCloseAction = () => {
    onClose()
    setIsShown(false)
  }

  const actionButtons = (isTop = false) => {
    return (
      <div className={`flex justify-between ${isTop ? "border-b p-3" : "border-t p-6"} border-solid border-slate-200 rounded-b`}>
        <div className="flex items-center justify-start">
          {leftButtonText && (
            <button
              className={`text-light-gray background-transparent font-medium px-1.5 py-2 hover:opacity-70 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${leftButtonClassNames}`}
              type="button"
              onClick={leftButtonAction}
            >
              {leftButtonText}
            </button>
          )}
        </div>
        <div className="flex items-center justify-end">
          {showSecondary && (
            <button
              className={`text-light-gray background-transparent font-medium px-6 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${
                secondaryActionActive
                  ? "hover:opacity-70"
                  : "opacity-40"
              }`}
              type="button"
              onClick={() =>
                secondaryAction
                  ? secondaryAction()
                  : onCloseAction()
              }
              disabled={!secondaryActionActive}
            >
              {secondaryText}
            </button>
          )}
          <button
            className={`inline - block px-5 py-2.5 bg-blue text-white font-medium rounded shadow-md hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition ${
              submitActionActive
                ? "hover:opacity-80  active:bg-[#012b4b]"
                : "opacity-50"
            }`}
            type="button"
            onClick={() => onSubmit()}
            disabled={!submitActionActive || submitActionLoading}
          >
            {submitActionLoading ? "Loading..." : submitText}
          </button>
        </div>
      </div>
    )
  }

  return (
    isShown && (
      <>
        {isShown ? (
          <>
            <div className="justify-center items-top flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
              <div
                className={`relative w-full my-6 mx-auto p-4 z-50 h-fit ${sizesVariants[size]}`}
              >
                <div
                  className={`${
                    fullHeight ? "h-full" : ""
                  } border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}
                >
                  <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-xl">{title}</h3>
                    <button
                      className="ml-auto bg-transparent border-0 text-black opacity-50 hover:opacity-70 transition float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => onCloseAction()}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block">
                        ×
                      </span>
                    </button>
                  </div>
                  {repeatTopButtons && actionButtons(true)}
                  {/*body*/}
                  <div className="p-4 h-full">{children}</div>
                  {/*footer*/}
                  {!noButtons ? actionButtons() : (
                    <div className="pb-8"></div>
                  )}
                </div>
              </div>
              <div
                className="opacity-25 fixed inset-0 z-40 bg-black"
                onClick={() => onCloseAction()}
              ></div>
            </div>
          </>
        ) : null}
      </>
    )
  )
}

export default Modal
