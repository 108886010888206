import { createSlice } from "@reduxjs/toolkit"
import { loadStats } from "./stats.actions"

const initialState = {
  stats: {
    uniqueLoginsToday: {
      count: 0,
      isLoading: false,
    },
    totalUsers: {
      count: 0,
      isLoading: false,
    },
  },
}

export const statsReducer = createSlice({
  name: "stats",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadStats.pending, (state, action) => {
      state.stats.uniqueLoginsToday.count = 0
      state.stats.uniqueLoginsToday.isLoading = true

      state.stats.totalUsers.count = 0
      state.stats.totalUsers.isLoading = true
    })
    builder.addCase(loadStats.fulfilled, (state, action) => {
      state.stats.uniqueLoginsToday.count =
        action.payload.countUniqueLoginsToday
      state.stats.uniqueLoginsToday.isLoading = false

      state.stats.totalUsers.count = action.payload.totalUsers
      state.stats.totalUsers.isLoading = false
    })
    builder.addCase(loadStats.rejected, (state, action) => {
      state.stats.uniqueLoginsToday.count = 0
      state.stats.uniqueLoginsToday.isLoading = false

      state.stats.totalUsers.count = 0
      state.stats.totalUsers.isLoading = false
    })
  },
})

export default statsReducer.reducer
