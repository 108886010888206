import React, { useEffect, useRef, useState } from "react"
import { removeAlert } from "../store/alerts/entity.reducer"
import { useDispatch } from "react-redux"
import { AlertType } from "../utils/alertUtils"
import { useNavigate } from "react-router-dom"
import { formatDisplayField } from "../helpers"

const Alert = ({ id, title, message, link = null, type = "info" }) => {
  const [progress, setProgress] = useState(0)
  const [out, setOut] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref = useRef()
  const time = 5
  const root = document.documentElement
  let cssVar = `--alert-margin-${id}`
  let colors, progressBarBg

  switch (type) {
    case AlertType.Info:
      colors = "text-blue-500 bg-blue-100"
      progressBarBg = "bg-blue-500"
      break
    case AlertType.Error:
      colors = "text-red-800 bg-red-100"
      progressBarBg = "bg-red-500"
      break
    case AlertType.Success:
      colors = "text-green-500 bg-green-100"
      progressBarBg = "bg-green-500"
      break
    case AlertType.Warning:
      colors = "text-yellow-500 bg-yellow-100"
      progressBarBg = "bg-yellow-500"
      break
    default:
      colors = "text-gray-500 bg-gray-100"
      progressBarBg = "bg-gray-500"
      break
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => prevProgress + 1)
    }, time * 10)

    if (progress >= 100) {
      clearInterval(interval)
      setTimeout(() => {
        setOut(true)
      }, time * 100)
    }

    return () => {
      clearInterval(interval)
    }
  }, [progress])

  useEffect(() => {
    root.style.setProperty(cssVar, `${ref.current.offsetHeight}px`)

    return () => {
      root.style.removeProperty(cssVar)
    }
  })

  return (
    <div
      className={`enter relative text-sm w-full cursor-pointer transition shadow-lg mb-3 ${colors} ${
        out && "opacity-animation-out opacity-0"
      }`}
      style={{ "--alert-margin": `var(${cssVar})` }}
      ref={ref}
      role="alert"
      onClick={() => {
        dispatch(removeAlert(id))

        if (link) {
          return navigate(link)
        }
      }}
    >
      <div
        className={`h-2 duration-[.5s] ease-linear ${progressBarBg}`}
        style={{ width: `${progress}%` }}
      ></div>
      <div className="p-3.5">
        {!link && (
          <div className="absolute top-2.5 right-2.5 m-1 z-10">
            <svg
              className={`fill-current h-5 w-5 ${colors}`}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </div>
        )}
        <p className="font-bold text-[16px] mb-2">
          {formatDisplayField(title)}
        </p>
        <div dangerouslySetInnerHTML={{ __html: message }}></div>
      </div>
    </div>
  )
}

export default Alert
