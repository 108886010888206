import React from "react"
import Alert from "./Alert"

const AlertsList = ({ alerts }) => {
  return (
    <div className="fixed top-0 left-1/2 -translate-x-1/2 z-50 pt-4 px-2 w-full max-w-[350px]">
      {alerts.map(alert => {
        return (
          <Alert
            key={alert.id}
            id={alert.id}
            title={alert.title}
            message={alert.message}
            type={alert.type}
            link={alert.link}
          />
        )
      })}
    </div>
  )
}

export default AlertsList
