import { api } from "../../api"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const loadEntities = createAsyncThunk("entities/load", async params => {
  return await api().loadEntities(params.endpoint)
})

export const loadEntitiesByIds = createAsyncThunk("entities/loadByIds", async params => {
  return await api().loadEntitiesByIds(params.endpoint, {
    ids: params.data
  })
})