import React from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { Roles } from "../../constants"

const RoleSelect = ({ id, value, onChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={id}>Select role</InputLabel>
      <Select label="Select role" id={id} value={value} onChange={onChange}>
        {Object.keys(Roles).map(id => (
          <MenuItem key={id} value={id}>
            {Roles[id]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RoleSelect
