import Button from "@mui/material/Button"
import EntityIcon from "../EntityIcon"
import { Autocomplete, TextField } from "@mui/material"
import cloneDeep from "lodash/cloneDeep"
import React, { useEffect, useRef, useState } from "react"
import Modal from "../Modal"
import UsersDataTable from "../DataTables/UsersDataTable"
import { forEach } from "lodash"
import {
  loadEntities,
  loadEntitiesByIds,
} from "../../store/entities/entities.actions"
import { clearEntities } from "../../store/entities/entities.reducer"
import { useDispatch } from "react-redux"
import { getFullSiteName } from "../../helpers"
import RoleSelect from "../Selects/RoleSelect"

const InviteUsers = ({
  study,
  setStudy,
  sitesErrorModal,
  setSitesErrorModal,
  rolesErrorModal,
  setRolesErrorModal,
  initialInvitedUsers = [],
  setInviteUsersChanged = null,
}) => {
  const [sites, setSites] = useState([])
  const [siteValues, setSiteValues] = useState({})
  const [invitedUsers, setInvitedUsers] = useState(initialInvitedUsers)
  const [addUsersModal, setAddUsersModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const usersDataTableRef = useRef(null)
  const dispatch = useDispatch()

  const loadUsers = ids => {
    setIsLoading(true)

    dispatch(
      loadEntitiesByIds({
        endpoint: "users/get-by-ids",
        data: ids,
      })
    )
      .unwrap()
      .then(result => {
        setIsLoading(false)
        setInvitedUsers(result?.data ?? [])
      })
  }

  const handleAddUsers = () => {
    setStudy(study => {
      let updatedStudy = cloneDeep(study)
      let invitedWithRoles = {}

      forEach(usersDataTableRef.current.getSelectedIds(), id => {
        if (
          updatedStudy["invited_users"] &&
          Object.keys(updatedStudy["invited_users"]).map(Number).includes(id)
        ) {
          invitedWithRoles[id] = { ...updatedStudy["invited_users"][id] }
        } else {
          invitedWithRoles[id] = {
            role_id: 2,
            site_id: null,
          }
        }
      })

      updatedStudy["invited_users"] = invitedWithRoles

      loadUsers(Object.keys(updatedStudy["invited_users"]))

      return updatedStudy
    })

    if (setInviteUsersChanged) {
      setInviteUsersChanged(true)
    }

    setAddUsersModal(false)
  }

  const handleRemoveUser = id => {
    setInvitedUsers(users => {
      let updatedUsers = cloneDeep(users)

      updatedUsers = updatedUsers.filter(obj => obj.id !== id)

      return updatedUsers
    })

    setStudy(study => {
      let updatedStudy = cloneDeep(study)

      delete updatedStudy["invited_users"][id]

      return updatedStudy
    })

    if (setInviteUsersChanged) {
      setInviteUsersChanged(true)
    }
  }

  const handleChangeRole = (id, role) => {
    setStudy(study => {
      let updatedStudy = cloneDeep(study)

      updatedStudy["invited_users"][id]["role_id"] = role

      return updatedStudy
    })

    if (setInviteUsersChanged) {
      setInviteUsersChanged(true)
    }
  }

  const handleChangeSite = (userId, siteId) => {
    setStudy(study => {
      let updatedStudy = cloneDeep(study)

      updatedStudy["invited_users"][userId]["site_id"] = siteId

      return updatedStudy
    })

    if (setInviteUsersChanged) {
      setInviteUsersChanged(true)
    }
  }

  useEffect(() => {
    dispatch(loadEntities({ endpoint: "sites" }))
      .unwrap()
      .then(result => {
        setSites(result?.data ?? [])
      })

    return () => dispatch(clearEntities())
  }, [dispatch])

  useEffect(() => {
    const ids = Object.keys(initialInvitedUsers)

    setStudy({ ...study, invited_users: initialInvitedUsers })

    if (ids.length > 0) {
      loadUsers(ids)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Button onClick={() => setAddUsersModal(true)}>
        + Add users to the study
      </Button>
      <h3 className="text-2xl mt-5 mb-2 font-medium">Invited users: </h3>
      <div className="flex flex-col">
        <table className="w-full border-spacing-y-1.5 border-separate border-t border-gray-300">
          <thead>
            <tr className="[&>td]:px-3 [&>td]:py-2.5 [&>td]:text-sm [&>td]:text-light-gray ">
              <td>Image</td>
              <td>Data</td>
              <td>Role</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="bg-white">
                <td className="px-3 py-7 rounded-md text-center" colSpan="4">
                  Loading...
                </td>
              </tr>
            ) : (
              invitedUsers.length > 0 &&
              invitedUsers.map(user => (
                <tr key={user.id} className="bg-white">
                  <td className="rounded-l-md pl-5 py-7 w-5">
                    <EntityIcon
                      size="md"
                      src={user.image_path}
                      className="mr-5"
                    />
                  </td>
                  <td className="px-3 py-7 lg:w-[350px]">
                    <p>{user.full_name}</p>
                    <p className="mt-2">{user.email}</p>
                  </td>
                  <td className="py-7">
                    <div className="flex flex-col">
                      <RoleSelect
                        id={`user-role-${user.id}`}
                        value={study["invited_users"][user.id]["role_id"]}
                        onChange={event =>
                          handleChangeRole(user.id, event.target.value)
                        }
                      />

                      <Autocomplete
                        disablePortal
                        options={sites}
                        getOptionLabel={site => getFullSiteName(site)}
                        onChange={(event, value) => {
                          handleChangeSite(user.id, value?.id ?? null)
                          setSiteValues(values => {
                            let updated = cloneDeep(values)

                            updated[user.id] = value

                            return updated
                          })
                        }}
                        onInputChange={(event, value) => {
                          setSiteValues(values => {
                            let updated = cloneDeep(values)

                            updated[user.id] = value

                            return updated
                          })
                        }}
                        inputValue={
                          typeof siteValues[user.id] === "object"
                            ? ""
                            : siteValues[user.id] ?? ""
                        }
                        value={
                          sites.find(
                            site =>
                              site.id ===
                              study["invited_users"][user.id]["site_id"]
                          ) ?? null
                        }
                        className="w-full form-control font-light text-gray-700 bg-white rounded focus:text-gray-700 mt-2"
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Enter or select site name"
                          />
                        )}
                      />
                    </div>
                  </td>
                  <td className="rounded-r-md px-5 py-7 w-[50px]">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleRemoveUser(user.id)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Modal
        title="Add users to the study"
        size="xxl"
        isShown={addUsersModal}
        setIsShown={setAddUsersModal}
        onSubmit={handleAddUsers}
        submitText="Add users"
      >
        <UsersDataTable
          ref={usersDataTableRef}
          selectableOnly={true}
          selectedItems={Object.keys(study["invited_users"] ?? {}).map(Number)}
        />
      </Modal>

      <Modal
        title="Roles error"
        size="lg"
        isShown={rolesErrorModal}
        setIsShown={setRolesErrorModal}
        submitText="Ok"
        showSecondary={false}
        onSubmit={() => setRolesErrorModal(false)}
      >
        <p>
          The study must contain at least one{" "}
          <span className="font-bold">Assigned Study Coordinator</span> and{" "}
          <span className="font-bold">Principal Investigator</span>
        </p>
      </Modal>

      <Modal
        title="Sites error"
        size="lg"
        isShown={sitesErrorModal}
        setIsShown={setSitesErrorModal}
        submitText="Ok"
        showSecondary={false}
        onSubmit={() => setSitesErrorModal(false)}
      >
        <p>One or more of the users do not have selected site </p>
      </Modal>
    </>
  )
}

export default InviteUsers
