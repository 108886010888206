import React from "react"
import UsersDataTable from "../../components/DataTables/UsersDataTable"
import Pagination from "../../components/Pagination"

const UsersPage = () => {
  return (
    <>
      <Pagination data={[{ title: "Users", link: "./users" }]} />
      <UsersDataTable />
    </>
  )
}

export default UsersPage
