import React, { useEffect } from "react"
import { checkLoginRequest } from "../../store/auth/auth.actions"
import { useDispatch, useSelector } from "react-redux"
import { selectAuthData } from "../../store/auth/auth.selector"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { motion } from "framer-motion"
import { clearLoginRequestId } from "../../store/auth/auth.reducer"

const CheckLoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { checkLoginRequestStatus, accessToken } = useSelector(selectAuthData)
  const { id } = useParams()

  useEffect(() => {
    dispatch(clearLoginRequestId())

    const interval = setInterval(function () {
      dispatch(checkLoginRequest({ id }))
    }, 5000)

    if (checkLoginRequestStatus === "success" && accessToken) {
      clearInterval(interval)
      return navigate(`/dashboard`, { replace: true })
    }

    if (checkLoginRequestStatus === "error") {
      clearInterval(interval)
      return navigate(`/error`, { replace: true })
    }

    return () => clearInterval(interval)
  }, [accessToken, dispatch, id, navigate, checkLoginRequestStatus])

  return (
    <motion.div
      className="flex justify-center items-center h-screen"
      initial={{ opacity: 0, marginTop: "-50px" }}
      animate={{ opacity: 1, marginTop: 0 }}
      exit={{ opacity: 0, marginTop: "-50px" }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-center max-w-[450px]">
        <h1 className="text-4xl font-semibold mb-5">
          Please check your email and confirm your login
        </h1>
        <p>
          Please check your email to confirm your login. <br /> You will be
          redirected automatically
        </p>
        <p className="mt-5">
          <NavLink
            to="/login"
            className="font-medium text-blue dark:text-blue hover:underline"
          >
            Change email
          </NavLink>
        </p>
      </div>
    </motion.div>
  )
}

export default CheckLoginPage
