import React from "react"
import Pagination from "../../components/Pagination"
import SponsorsDataTable from "../../components/DataTables/SponsorsDataTable"

const SponsorsPage = () => {
  return (
    <>
      <Pagination data={[{ title: "Sponsors", link: "./sponsors" }]} />
      <SponsorsDataTable />
    </>
  )
}

export default SponsorsPage
