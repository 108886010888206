import { createSlice } from "@reduxjs/toolkit"
import { loadClinicalTrialsStudy, loadEntity } from "./entity.actions";

const initialState = {
  data: null,
  isLoading: false,
  isError: false,
}

export const entityReducer = createSlice({
  name: "entity",
  initialState,
  reducers: {
    clearEntity(state, action) {
      state.data = null
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(loadEntity.pending, (state, action) => {
      state.data = null
      state.isLoading = true
      state.isError = false
    })
    builder.addCase(loadEntity.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
      state.isError = false
    })
    builder.addCase(loadEntity.rejected, (state, action) => {
      state.data = null
      state.isLoading = false
      state.isError = true
    })

    builder.addCase(loadClinicalTrialsStudy.pending, (state, action) => {
      state.data = null
      state.isLoading = true
      state.isError = false
    })
    builder.addCase(loadClinicalTrialsStudy.fulfilled, (state, action) => {
      state.data = action.payload?.data
      state.isLoading = false
      state.isError = false
    })
    builder.addCase(loadClinicalTrialsStudy.rejected, (state, action) => {
      state.data = null
      state.isLoading = false
      state.isError = true
    })
  },
})

export const { clearEntity, setIsLoading } = entityReducer.actions
export default entityReducer.reducer
