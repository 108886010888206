import React from "react"
import StudiesDataTable from "../../components/DataTables/StudiesDataTable";
import Pagination from "../../components/Pagination";

const StudiesPage = props => (
  <>
    <Pagination data={[{ title: "Sponsors", link: "./sponsors" }]} />
    <StudiesDataTable />
  </>
)

export default StudiesPage
