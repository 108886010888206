import { store } from "../store"
import { appendAlert, removeAlert } from "../store/alerts/entity.reducer"
import uuid from "react-native-uuid"
import { renderToString } from "react-dom/server"

export function addAlertWithTimeout(
  title,
  message,
  type,
  link = null,
  timeout = 7000
) {
  const alert = {
    id: uuid.v4(),
    title,
    message: renderToString(message),
    type,
    link,
  }

  store.dispatch(appendAlert(alert))

  setTimeout(() => {
    store.dispatch(removeAlert(alert.id))
  }, timeout)
}

export const AlertType = {
  Info: "info",
  Error: "error",
  Success: "success",
  Warning: "warning",
}
