import React, { forwardRef, useImperativeHandle, useRef } from "react"
import Dropzone from "./DropZone"
import CropImage from "./CropImage"
import EntityIcon from "./EntityIcon"

const UploadAndCropImage = forwardRef(
  (
    {
      image,
      setImage,
      croppedImage,
      setCroppedImage,
      initialStep = 1,
      currentStep,
      setStep,
      handlerCancel,
      type = "jpeg",
      cropShape = "rect",
    },
    ref
  ) => {
    const innerRef = useRef(null)

    useImperativeHandle(ref, () => ({
      cropImageAction() {
        innerRef.current.handleCropImage()
      },
    }))

    const onDropAction = () => {
      setStep(currentStep + 1)
    }

    return (
      <>
        {currentStep === initialStep && (
          <Dropzone
            setFile={setImage}
            onDropAction={onDropAction}
          />
        )}

        {currentStep === initialStep + 1 && image && (
          <CropImage
            ref={innerRef}
            image={image}
            handlerCancel={handlerCancel}
            setCroppedImage={setCroppedImage}
            type={type}
            cropShape={cropShape}
          />
        )}

        {currentStep === initialStep + 2 && croppedImage && (
          <EntityIcon
            src={croppedImage}
            size="xxl"
            className="mx-auto my-5"
            alt=""
          />
        )}
      </>
    )
  }
)

export default UploadAndCropImage
