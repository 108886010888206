import { ACCESS_TOKEN } from "../constants"
import { addAlertWithTimeout, AlertType } from "../utils/alertUtils"

// const BASE_URL = "http://api.localhost:8000/"
const BASE_URL = "https://api.pi-enroll.com/"

function request(uri = "", method = "GET", data = {}, headers = {}) {
  let accessToken = localStorage.getItem(ACCESS_TOKEN)
  let requestHeaders = {
    Accept: "application/json",
    "Client-key": "632e390aca566491a2016fb553d88e67",
    Authorization: accessToken ? "Bearer " + accessToken : null,
    ...headers,
  }

  if (!(data instanceof FormData)) {
    data = JSON.stringify(data)
    requestHeaders["Content-Type"] = "application/json"
    requestHeaders["Accept"] = "application/json"
  }

  if (method === "GET" || method === "HEAD") {
    data = null
  }

  return fetch(BASE_URL + uri, {
    method,
    body: data,
    headers: requestHeaders,
  })
    .then(async response => {
      if (!response.ok) {
        throw await response.json()
      } else if (response.status === 204) {
        return true
      }

      return response.json()
    })
    .catch(data => {
      if (data.errors) {
        for (const [key, messageArr] of Object.entries(data.errors)) {
          let el = document.getElementById(key)

          messageArr.map(message =>
            addAlertWithTimeout(key ?? "", message ?? "", AlertType.Error)
          )

          if (el) {
            el.classList.add(...["!border-red-400", "animation-shake"])

            setTimeout(() => {
              el.classList.remove(...["animation-shake"])
            }, 500)

            el.addEventListener("change", () => {
              el.classList.remove(...["!border-red-400"])
            })

            el.addEventListener("focus", () => {
              el.classList.remove(...["!border-red-400"])
            })
          }
        }
      } else if (data.message) {
        addAlertWithTimeout("Error", data.message, AlertType.Error)
      }
    })
}

export const api = () => ({
  loginRequest(email) {
    return request(`login`, "POST", {
      email: email,
    })
  },
  checkLoginRequest(id) {
    return request(`check-login-request/${id}`)
  },
  me() {
    return request(`users/me`)
  },
  logout() {
    return request(`logout`, "POST")
  },
  loadEntities(endpoint) {
    return request(endpoint)
  },
  loadEntitiesByIds(endpoint, data) {
    return request(endpoint, "POST", data)
  },
  loadEntity(endpoint) {
    return request(endpoint)
  },
  createEntity(endpoint, data) {
    return request(endpoint, "POST", data)
  },
  updateEntity(endpoint, data) {
    return request(endpoint, "PUT", data)
  },
  deleteEntity(endpoint) {
    return request(endpoint, "DELETE")
  },
  archiveStudy(id) {
    return request(`studies/${id}/archive`, "POST")
  },
  unarchiveStudy(id) {
    return request(`studies/${id}/unarchive`, "POST")
  },
  loadClinicalTrialsStudy(nct) {
    return request(`get-clinical-trials-study`, "POST", {
      clinical_trials_id: nct,
    })
  },
  loadStudyTopicInfo(id) {
    return request(`studies/${id}/topic-info`)
  },
  updateStudyTopicInfo(id, value) {
    return request(`studies/${id}/topic-info`, "POST", {
      topic_info: value,
    })
  },
  loadStudyCalculations(id) {
    return request(`studies/${id}/calculations`)
  },
  updateStudyCalculations(id, data) {
    return request(`studies/${id}/update-calculations`, "POST", {
      calculations: data,
    })
  },
  loadStudyProcedures(id) {
    return request(`studies/${id}/procedures`)
  },
  updateStudyProcedures(id, data) {
    return request(`studies/${id}/update-procedures`, "POST", {
      procedures: data,
    })
  },
  loadStudyEvaluations(id) {
    return request(`studies/${id}/evaluations`)
  },
  updateStudyEvaluations(id, data) {
    return request(`studies/${id}/update-evaluations`, "POST", {
      evaluations: data,
    })
  },
  loadStudyVisitSchedule(id) {
    return request(`studies/${id}/visit-schedule`)
  },
  updateStudyVisitSchedule(id, data) {
    return request(`studies/${id}/update-visit-schedule`, "POST", {
      visit_schedule: data,
    })
  },
  loadInstructionalVideo(id) {
    return request(`studies/${id}/instructional-video`)
  },
  updateInstructionalVideo(id, data) {
    return request(`studies/${id}/update-instructional-video`, "POST", data)
  },
  clearInstructionalVideo(id) {
    return request(`studies/${id}/instructional-video`, "DELETE")
  },
  loadStudySynopsis(id) {
    return request(`studies/${id}/synopsis`)
  },
  updateStudySynopsis(id, data) {
    return request(`studies/${id}/update-synopsis`, "POST", data)
  },
  clearStudySynopsis(id) {
    return request(`studies/${id}/synopsis`, "DELETE")
  },
  loadStudyProtocol(id) {
    return request(`studies/${id}/protocol`)
  },
  uploadProtocolAndExtractStudyInfo(data) {
    return request(`upload-and-extract-study-info`, "POST", data)
  },
  updateStudyProtocol(id, data) {
    return request(`studies/${id}/update-protocol`, "POST", data)
  },
  clearStudyProtocol(id) {
    return request(`studies/${id}/protocol`, "DELETE")
  },
  loadStudyConsentForm(id) {
    return request(`studies/${id}/consent-form`)
  },
  updateStudyConsentForm(id, data) {
    return request(`studies/${id}/update-consent-form`, "POST", data)
  },
  clearStudyConsentForm(id) {
    return request(`studies/${id}/consent-form`, "DELETE")
  },
  loadStats() {
    return request(`stats/all`)
  },
})
