import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react"
import Cropper from "react-easy-crop"

import { getCroppedImg } from "../utils/canvasUtils"
import { Slider } from "@mui/material";

const CropImage = forwardRef(
  (
    {
      image,
      handlerCancel,
      setCroppedImage,
      type = "jpeg",
      cropShape = "rect",
    },
    ref
  ) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [file, setFile] = useState("")
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const handleCropImage = useCallback(async () => {
      try {
        const croppedImage = await getCroppedImg(file, croppedAreaPixels, type)
        setCroppedImage(croppedImage)
      } catch (e) {
        console.error(e)
      }
    }, [file, croppedAreaPixels, type, setCroppedImage])

    useImperativeHandle(ref, () => ({
      handleCropImage() {
        handleCropImage().then()
      },
    }))

    useEffect(() => {
      const convertFileToBase64 = () => {
        const reader = new FileReader()
        reader.onabort = () => console.log("file reading was aborted")
        reader.onerror = () => console.log("file reading has failed")
        reader.readAsDataURL(image)
        reader.onload = () => setFile(reader.result)
      }
      convertFileToBase64()
    }, [image])

    return (
      <div>
        <div className="relative h-[300px] w-full bg-[#999]">
          <Cropper
            image={file}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape={cropShape}
          />
        </div>
        <div className="p-5 flex flex-1 flex-col items-center">
          <h3>Zoom</h3>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.01}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
      </div>
    )
  }
)

export default CropImage
