import { Outlet, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectAuthData } from "../store/auth/auth.selector"
import { useEffect } from "react"

const OnlyGuestRoute = () => {
  const { user } = useSelector(selectAuthData)
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      return navigate("/dashboard")
    }
  }, [navigate, user])

  return <Outlet />
}

export default OnlyGuestRoute
