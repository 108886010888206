const EntityIcon = ({ src, alt = "", className = "", size = "sm" }) => {
  let sizeClassName

  switch (size) {
    case "sm":
      sizeClassName = "w-10 h-10"
      break
    case "md":
      sizeClassName = "w-12 h-12"
      break
    case "xl":
      sizeClassName = "w-24 h-24"
      break
    case "xxl":
      sizeClassName = "w-48 h-48"
      break
    default:
      sizeClassName = "w-10 h-10"
      break
  }
  return (
    <div
      className={`border rounded-full bg-light-blue overflow-hidden ${sizeClassName} ${className}`}
    >
      {src && (
        <img
          className="object-fill object-center w-full h-full"
          src={src}
          alt={alt}
        />
      )}
    </div>
  )
}

export default EntityIcon
