import { useEffect, useRef } from "react";

const StatusesChart = ({ statuses }) => {
  const statusesRef = useRef(null);
  const committedRef = useRef(null);

  const calculatePercentage = (statuses, status) => {
    let total = Math.max(...(Object.values(statuses)))

    return Math.round((status / total) * 100);
  }

  const calculateCommitted = (statuses, status) => {
    let result = calculatePercentage(statuses, status)

    if (result < 10) {
      result += 9
    } else if(result > 90) {
      result -= 9
    }

    return result // Adjust high values
  }

  const calculateMetrics = (statuses, half = false) => {
    return Math.round(Math.max(...(Object.values(statuses))) / (half ? 2 : 1))
  }

  useEffect(() => {
    if (statuses && statusesRef && committedRef) {
      setTimeout(() => {
        statusesRef.current?.classList.add('loaded');
        committedRef.current?.classList.add('loaded');
      }, 500)
    }
  }, [statuses]);

  return (
    <div className="py-5 pl-7 pr-4 w-full">
      <div className="relative min-h-[250px]">
        <div className="absolute z-30 bg-white w-[10px] h-full rounded-t-lg rounded-bl-lg">
          <div className="flex flex-col justify-between items-center h-full relative -left-[18px] bottom-[24px]">
            <span className="text-xs mt-[28px]">{statuses && calculateMetrics(statuses)}</span>
            <span className="text-xs">{statuses && calculateMetrics(statuses, true)}</span>
            <span className="text-xs">0</span>
          </div>
        </div>
        <div className="absolute z-30 bottom-0 bg-white w-full h-[24px] rounded-bl-lg rounded-r-lg flex flex-row justify-between px-4 pt-1">
          <span className="text-xs text-center w-[33%]">Consented</span>
          <span className="text-xs text-center w-[33%]">Screened</span>
          <span className="text-xs text-center w-[33%]">Enrolled</span>
        </div>
        {statuses ? (
          <>
            <div
              className="animated-charts-right absolute z-10 h-[4px] bg-[#032C4A] flex justify-end rounded-r-md mt-1"
              ref={committedRef}
              style={{
                bottom: `calc(${calculateCommitted(
                  statuses,
                  statuses.committed
                )}% + 8px)`,
              }}
            >
              <div className="rounded-full bg-[#032C4A] w-6 h-6 flex justify-center items-center -mt-2.5">
                <span className="text-white text-sm">{statuses.committed}</span>
              </div>
            </div>

            <div
              className="animated-charts-up absolute z-20 bottom-[24px] w-full flex flex-row justify-between px-8 pt-6"
              ref={statusesRef}
            >
              <div
                className={`relative mt-auto bg-[#AECCE8] w-[24px] min-h-[24px] rounded-t-xl flex justify-center`}
                style={{
                  height: calculatePercentage(statuses, statuses.consented) + "%",
                }}
              >
              <span className="text-white text-sm mt-1">
                {statuses.consented}
              </span>
              </div>
              <div
                className={`relative mt-auto bg-[#80A6C9] w-[24px] min-h-[24px] rounded-t-xl flex justify-center`}
                style={{
                  height: calculatePercentage(statuses, statuses.screened) + "%",
                }}
              >
              <span className="text-white text-sm mt-1">
                {statuses.screened}
              </span>
              </div>
              <div
                className={`relative mt-auto bg-dark-blue w-[24px] min-h-[24px] rounded-t-xl flex justify-center`}
                style={{
                  height: calculatePercentage(statuses, statuses.enrolled) + "%",
                }}
              >
              <span className="text-white text-sm mt-1">
                {statuses.enrolled}
              </span>
              </div>
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-full">
            <span className="text-sm text-light-gray">Loading...</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default StatusesChart
