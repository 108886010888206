import { useDispatch, useSelector } from "react-redux"
import { selectAuthData } from "../store/auth/auth.selector"
import { useEffect, useState } from "react"
import { authMe } from "../store/auth/auth.actions"

const useLoadedUser = () => {
  const { userLoadingStatus, accessToken } = useSelector(selectAuthData)
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (!isReady && userLoadingStatus === null && accessToken !== null) {
      dispatch(authMe())
    }

    if (
      accessToken === null ||
      (userLoadingStatus !== "pending" && userLoadingStatus !== null)
    ) {
      setIsReady(true)
    }
  }, [accessToken, dispatch, isReady, userLoadingStatus])

  return isReady
}

export default useLoadedUser
