import React from "react"

const Spinner = ({ size = "xs", classNames = "" }) => {
  const sizesVariants = {
    xs: "w-4 h-4",
    sm: "w-6 h-6",
  }

  const sizesVariantsBorder = {
    xs: "border-2",
    sm: "border-[2px]",
  }

  return (
    <div className={`relative ${sizesVariants[size]} ${classNames}`}>
      <div
        className={`${sizesVariants[size]} ${sizesVariantsBorder[size]} rounded-full absolute border-solid border-gray-200`}
      ></div>
      <div
        className={`${sizesVariants[size]} ${sizesVariantsBorder[size]} rounded-full animate-spin absolute border-solid border-blue border-t-transparent`}
      ></div>
    </div>
  )
}

export default Spinner
