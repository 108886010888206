import React from "react"
import { Outlet } from "react-router-dom"
import { motion } from "framer-motion"
import Sidebar from "../components/Sidebar"
import UserPopup from "../components/UserPopup"
import AlertsList from "../components/AlertsList"
import { useSelector } from "react-redux"
import { selectAlertsData } from "../store/alerts/entity.selector"

const AppLayout = () => {
  const { alerts } = useSelector(selectAlertsData)

  return (
    <>
      <Sidebar />
      <UserPopup />
      <motion.div
        className="grow px-5 my-6 md:ml-[192px] pb-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Outlet />
      </motion.div>
      <AlertsList alerts={alerts} />
    </>
  )
}

export default AppLayout
