import React, { useEffect } from "react"
import PersonIcon from "@mui/icons-material/Person"
import SmartphoneIcon from "@mui/icons-material/Smartphone"
import CountUp from "react-countup"
import { loadStats } from "../store/stats/stats.actions"
import { useDispatch, useSelector } from "react-redux"
import { selectStatsData } from "../store/stats/stats.selector"
import Spinner from "../components/Spinner"
import { Link } from "react-router-dom"
import Pagination from "../components/Pagination"

const DashboardPage = () => {
  const dispatch = useDispatch()
  const { stats } = useSelector(selectStatsData)

  useEffect(() => {
    dispatch(loadStats())
  }, [dispatch])

  return (
    <>
      <Pagination data={[{ title: "Dashboard", link: "./dashboard" }]} />
      <div className="block max-w-[200px] p-8 bg-white border border-gray-200 rounded-xl shadow text-center float-left mr-8">
        <div className="flex flex-row items-center justify-center mb-2">
          <SmartphoneIcon style={{ fontSize: 30 }} />

          <h5 className="text-3xl font-bold tracking-tight text-gray-900 ml-2">
            {stats.uniqueLoginsToday.isLoading ? (
              <Spinner size="sm" />
            ) : (
              <CountUp
                start={0}
                end={stats.uniqueLoginsToday.count}
                duration={2}
              />
            )}
          </h5>
        </div>
        <p className="font-normal text-gray-700">users opened app today</p>
      </div>

      <Link
        to="/users"
        className="block max-w-[200px] p-8 bg-white border border-gray-200 rounded-xl shadow text-center float-left hover:opacity-75 transition"
      >
        <div className="flex flex-row items-center justify-center mb-2">
          <PersonIcon style={{ fontSize: 30 }} />

          <h5 className="text-3xl font-bold tracking-tight text-gray-900 ml-2">
            {stats.totalUsers.isLoading ? (
              <Spinner size="sm" />
            ) : (
              <CountUp start={0} end={stats.totalUsers.count} duration={2} />
            )}
          </h5>
        </div>
        <p className="font-normal text-gray-700">total registered users</p>
      </Link>
    </>
  )
}

export default DashboardPage
