import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { DOTS, usePagination } from "../../hooks/usePagination"
import debounce from "lodash.debounce"
import { loadEntities } from "../../store/entities/entities.actions"
import { useDispatch, useSelector } from "react-redux"
import { selectEntitiesData } from "../../store/entities/entities.selector"
import { setCurrentPage } from "../../store/entities/entities.reducer"
import Accordion from "../Accordion"
import RowsLoader from "./RowsLoader"
import Folder from "@mui/icons-material/FolderOpen"

const SponsorsStudiesDataTable = ({ sponsorId }) => {
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState(0)
  const { data, currentPage, totalPages, perPage, totalItems } =
    useSelector(selectEntitiesData)
  const dispatch = useDispatch()
  let [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const paginationRange = usePagination({
    totalPages,
    currentPage,
  })

  let queryParams = useMemo(
    function () {
      return {
        page: searchParams.get("page"),
      }
    },
    [searchParams]
  )

  const debounceFn = useMemo(
    () =>
      debounce(inputValue => {
        let queryStr = new URLSearchParams({
          search: inputValue,
          archived: status,
        }).toString()

        dispatch(
          loadEntities({
            endpoint: `sponsors/${sponsorId}/studies/search?${queryStr}`,
          })
        )
      }, 500),
    [dispatch, sponsorId, status]
  )

  const onChangeSearch = event => {
    setSearch(event.target.value)
    debounceFn(event.target.value)
  }

  const setQueryParam = useCallback(
    (name, value) => {
      const newQueryParameters = new URLSearchParams()

      newQueryParameters.set(name, value)
      setSearchParams(newQueryParameters)
    },
    [setSearchParams]
  )

  const setPage = useCallback(
    pageNumber => {
      queryParams.page = pageNumber
      let queryStr = new URLSearchParams({
        page: queryParams.page,
        search: search,
        archived: status,
      }).toString()

      setQueryParam("page", pageNumber)

      dispatch(setCurrentPage(pageNumber))
      dispatch(
        loadEntities({
          endpoint: `sponsors/${sponsorId}/studies/search?${queryStr}`,
        })
      )
    },
    [queryParams, search, status, setQueryParam, dispatch, sponsorId]
  )

  const [active, setActive] = useState(null)

  const handleToggle = index => {
    if (active === index) {
      setActive(null)
    } else {
      setActive(index)
    }
  }

  useEffect(() => {
    setPage(1)
  }, [status]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.length > 0) {
      setActive(data[0].id)
    }
  }, [data])

  useEffect(() => {
    setPage(queryParams.page ?? 1)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="border-2 rounded-md bg-light-blue px-3 lg:max-w-[85%] mb-5">
      <div className="flex justify-between">
        <div className="mr-2 my-3 w-full">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-100 transition duration-200 focus:bg-white outline-0"
              placeholder="Search by Topic or Study name"
              autoComplete="off"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
        </div>
        <button
          onClick={() => navigate("/studies/create")}
          className="block text-center py-2 px-4 my-3 w-40 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-100 transition hover:bg-white focus:bg-white"
        >
          Add new Study
        </button>
      </div>
      <div>
        <span
          className={`ml-1 mr-3 cursor-pointer underline-offset-4 decoration-1 ${
            status === 0 ? "underline" : "opacity-50"
          }`}
          onClick={() => setStatus(0)}
        >
          Active
        </span>
        <span
          className={`cursor-pointer underline-offset-4 decoration-1 ${
            status === 1 ? "underline" : "opacity-50"
          }`}
          onClick={() => setStatus(1)}
        >
          Archived
        </span>
      </div>
      <div className="overflow-hidden overflow-x-auto visible-horizontal-scrollbar mb-2.5 min-h-[250px]">
        {data ? (
          data.length > 0 ? (
            data.map(topic => (
              <Accordion
                key={topic.id}
                active={active}
                title={topic.name}
                handleToggle={handleToggle}
                id={topic.id}
                icon={
                  <Folder
                    className="mt-0.5 mr-5"
                    style={{ color: "#446B95", fontSize: 24 }}
                  />
                }
              >
                <table className="mt-2 text-center w-full border-t border-spacing-y-1.5 border-separate">
                  <thead>
                    <tr className="[&>td]:text-light-gray [&>td]:font-light">
                      <td className="py-5 pl-6 text-left">Name</td>
                      <td className="py-5">Sites</td>
                      <td className="py-5">Status</td>
                      <td className="py-5"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {topic.studies &&
                      topic.studies.map(study => (
                        <tr
                          key={study.id}
                          className="[&>td]:border-y [&>td]:px-5 [&>td]:py-7"
                        >
                          <td className="rounded-l-lg border-l text-left">
                            {study.name}
                          </td>
                          <td>{study.sites_count}</td>
                          <td>
                            {study.status_id === 5 ? "Archived" : "Active"}
                          </td>
                          <td className="rounded-r-lg border-r">
                            <Link
                              to={`/studies/${study.id}`}
                              className="text-link-color text-sm transition hover:opacity-70"
                            >
                              View study
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Accordion>
            ))
          ) : (
            <div className="w-full rounded-md py-7 px-3 text-light-gray text-center">
              <span>Nothing not found</span>
            </div>
          )
        ) : (
          <table className="mt-2 text-center w-full border-t border-spacing-y-1.5 border-separate">
            <tbody>
              <RowsLoader columnsCount={4} rowsCount={perPage} />
            </tbody>
          </table>
        )}
      </div>
      <div>
        <p className="text-xs text-light-gray pb-2">
          Total records: {totalItems}
        </p>
      </div>
      <div>
        <ul className="flex justify-center mb-2.5">
          {paginationRange &&
            paginationRange.map((pageNumber, i) => {
              if (pageNumber === DOTS) {
                return (
                  <li key={i} className="mx-1">
                    &#8230;
                  </li>
                )
              }

              return (
                <li
                  className={`mx-1 font-light cursor-pointer ${
                    pageNumber !== currentPage
                      ? "underline underline-offset-4"
                      : null
                  }`}
                  key={i}
                  onClick={() => {
                    setPage(pageNumber)
                  }}
                >
                  {pageNumber}
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default SponsorsStudiesDataTable
