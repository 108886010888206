import React, { useState } from "react"
import DataTable from "./DataTable"
import Input from "../Input"
import Modal from "../Modal"
import { useDispatch } from "react-redux"
import { createEntity } from "../../store/entity/entity.actions"
import { addAlertWithTimeout, AlertType } from "../../utils/alertUtils"
import { loadEntities } from "../../store/entities/entities.actions"

const initialFormState = {
  center: "",
  department: "",
  country: "",
}

const SitesDataTable = () => {
  const endpoint = "sites/search"

  let rows = [
    {
      id: "center",
      title: "Center",
      type: "text",
    },
    {
      id: "department",
      title: "Department",
      type: "text",
    },
    {
      id: "country",
      title: "Country",
      type: "text",
    },
    {
      id: "action",
      title: "",
      type: "action",
      link: "/sites/{param}",
      text: "View site",
      center: true,
    },
  ]

  const [isModalShown, setIsModalShown] = useState(false)
  const [form, setForm] = useState(initialFormState)
  const dispatch = useDispatch()

  const onInputChange = (key, value) => {
    let newForm = { ...form }

    newForm[key] = value

    setForm(newForm)
  }

  const onSubmit = () => {
    dispatch(createEntity({ endpoint: "sites/", data: form }))
      .unwrap()
      .then(response => {
        if (response !== undefined) {
          setForm(initialFormState)

          setIsModalShown(false)

          if (response?.data.id) {
            addAlertWithTimeout(
              "Success",
              <>
                <p>Site has been created successfully.</p>
                <p className="font-bold mt-2">Click here to open</p>
              </>,
              AlertType.Success,
              `sites/${response?.data.id}`
            )

            dispatch(loadEntities({ endpoint: "sites/search" }))
          }
        }
      })
  }

  return (
    <>
      <DataTable
        setAddNewModal={setIsModalShown}
        rows={rows}
        endpoint={endpoint}
        searchByText="Search by center, department and country"
      />
      <Modal
        title="Add new Site"
        size="xl"
        isShown={isModalShown}
        setIsShown={setIsModalShown}
        onSubmit={onSubmit}
        onClose={() => setForm(initialFormState)}
      >
        <div className="mb-3">
          Center:
          <Input
            id="center"
            onChange={e => onInputChange("center", e.target.value)}
            value={form.center}
            placeholder="Center"
          />
        </div>
        <div className="mb-3">
          Department:
          <Input
            id="department"
            onChange={e => onInputChange("department", e.target.value)}
            value={form.department}
            placeholder="Department"
          />
        </div>
        <div>
          Country:
          <Input
            id="country"
            onChange={e => onInputChange("country", e.target.value)}
            value={form.country}
            placeholder="Country"
          />
        </div>
      </Modal>
    </>
  )
}

export default SitesDataTable
