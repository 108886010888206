import React, { useEffect } from "react"
import { createLoginRequest } from "../../store/auth/auth.actions"
import { useDispatch, useSelector } from "react-redux"
import { selectAuthData } from "../../store/auth/auth.selector"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import { setEmail } from "../../store/auth/auth.reducer"

const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loginRequestStatus, loginRequestId, email } =
    useSelector(selectAuthData)

  const requestLoginHandler = async event => {
    event.preventDefault()

    dispatch(createLoginRequest({ email }))
  }

  useEffect(() => {
    if (loginRequestStatus === "success" && loginRequestId) {
      return navigate(`/check/${loginRequestId}`)
    }
  }, [loginRequestId, navigate, loginRequestStatus])

  return (
    <motion.div
      className="flex"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="justify-center items-center h-screen w-1/2 bg-light-blue hidden md:flex">
        <img
          src="https://i0.wp.com/refuahsolutions.com/wp-content/uploads/2022/08/PI-enroll-2.png?w=885&ssl=1"
          alt=""
        />
      </div>
      <div className="flex justify-center items-center h-screen w-full">
        <motion.form
          className="max-w-[340px] w-full p-3"
          initial={{ marginTop: "-50px", opacity: 0 }}
          animate={{ marginTop: 0, opacity: 1 }}
          exit={{ marginTop: "-50px", opacity: 0 }}
          transition={{ delay: 0.1 }}
        >
          <h1 className="text-4xl font-extralight text-center mb-7">
            Sign in/Log in
          </h1>

          <label
            htmlFor="email"
            className="font-extralight inline-block text-xl"
          >
            Enter your Email:
          </label>

          <input
            id="email"
            className="form-control font-light block my-2 px-2 py-1 w-full text-xl  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            type="email"
            placeholder="example@gmail.com"
            onChange={e => dispatch(setEmail(e.target.value))}
            value={email}
          />

          <div className="my-5">
            <input
              className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white focus:outline-none transition duration-200 mt-1 align-top float-left mr-2 cursor-pointer"
              type="checkbox"
              id="remember"
            />

            <label
              className="form-check-label inline-block text-gray-800 font-extralight"
              htmlFor="remember"
            >
              Remember me
            </label>
          </div>

          <button
            className="inline-block w-full px-2 py-3 bg-blue text-white font-light tracking-wider leading-tight uppercase rounded shadow-md hover:opacity-80 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#012b4b] active:shadow-lg transition duration-150 ease-in-out"
            onClick={requestLoginHandler}
            disabled={loginRequestStatus === "pending"}
            type="button"
          >
            {loginRequestStatus === "pending" ? "Loading..." : "Login"}
          </button>
        </motion.form>
      </div>
    </motion.div>
  )
}

export default LoginPage
