import { capitalize } from "lodash/string"
import cloneDeep from "lodash/cloneDeep"

export function resolve(obj, path) {
  path = path.split(".")

  let current = obj

  while (path.length) {
    if (typeof current !== "object") {
      return undefined
    }

    let key = path.shift()

    current = current ? current[key] : null
  }

  return current
}

export function updateNestedProperty(obj, path, value) {
  const keys = path.split(".")

  for (let i = 0; i < keys.length - 1; i++) {
    obj = obj[keys[i]]
    if (!obj) return
  }

  obj[keys[keys.length - 1]] = value
}

export function formatDisplayField(value) {
  if (value.includes(".")) {
    return "Error"
  }

  let values = value.split("_")

  values.map((item, index) => {
    if (index === 0) {
      values[index] = capitalize(item)
    }

    return values
  })

  return values.join(" ")
}

export function isEmpty(value) {
  return !value || value === "" || value.length < 1
}

export function prepareBeforeUpdate(study) {
  let updatedStudy = cloneDeep(study)

  if (study?.criteria) {
    updatedStudy.criteria.inclusion = updatedStudy.criteria.inclusion.filter(
      item => !isEmpty(item.value)
    )

    updatedStudy.criteria.exclusion = updatedStudy.criteria.exclusion.filter(
      item => !isEmpty(item.value)
    )
  }

  return updatedStudy
}

export function getFullSiteName(site) {
  return `${site.center}, ${site.department}, ${site.country}`
}

export function bytesToMB(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2)
}
